import {lazy, LazyExoticComponent} from "react";
import configureStore from "../store/configureStore";
import {RouteType} from "../@types/routes";
import Home from "../pages/Home";
import Monitor from "../pages/Monitor";
import HotMatches from "../pages/HotMatch";
import Group from "../pages/Group";
import Players from "../pages/Players";
import DroppingOddsClassic from "../pages/DroppingOddsClassic";
import DroppingOddsInstant from "../pages/DroppingOddsInstant";
import Extra from "../pages/Extra";

const store = configureStore();

export enum RoutesEnum {
    HOME,
    MONITOR,
    DROPPING_ODDS,
    CLASSIC_VIEW,
    INSTANT_VIEW,
    PLAYERS,
    HOT_MATCHES,
    EXTRA,
    GROUP,
    DEFAULT,
}

export const routesPaths = {
    [RoutesEnum.HOME]: "/",
    [RoutesEnum.MONITOR]: "/monitor",
    [RoutesEnum.CLASSIC_VIEW]: "/dropping-odds/classic-view",
    [RoutesEnum.INSTANT_VIEW]: "/dropping-odds/instant-view",
    [RoutesEnum.PLAYERS]: "/players",
    [RoutesEnum.HOT_MATCHES]: "/hot-matches",
    [RoutesEnum.EXTRA]: "/extra",
    [RoutesEnum.GROUP]: "/group",
    [RoutesEnum.DEFAULT]: "*",
};

export const HomeRoute: LazyExoticComponent<any> = lazy(() =>
    import(/* webpackChunkName: "home" */ "../modules/homeModule").then((module) => {
        store.injectReducer && store.injectReducer("home", module.reducer);
        return import(/* webpackChunkName: "home" */ "../pages/Home");
    })
);

export const routes: RouteType[] = [
    {
        path: routesPaths[RoutesEnum.HOME],
        component: Home,
    },
    {
        path: routesPaths[RoutesEnum.MONITOR],
        component: Monitor,
    },
    {
        path: routesPaths[RoutesEnum.CLASSIC_VIEW],
        component: DroppingOddsClassic,
        permission: 0,
    },
    {
        path: routesPaths[RoutesEnum.PLAYERS],
        component: Players,
        permission: 0,
    },
    {
        path: routesPaths[RoutesEnum.HOT_MATCHES],
        component: HotMatches,
        permission: 0,
    },
    {
        path: routesPaths[RoutesEnum.GROUP],
        component: Group,
        permission: 1,
    },
    {
        path: routesPaths[RoutesEnum.DEFAULT],
        component: HomeRoute,
    },
    {
        path: routesPaths[RoutesEnum.CLASSIC_VIEW],
        component: DroppingOddsClassic,
    },
    {
        path: routesPaths[RoutesEnum.INSTANT_VIEW],
        component: DroppingOddsInstant,
    },
    {
        path: routesPaths[RoutesEnum.EXTRA],
        component: Extra,
        permission: 0,
    },
];

export default function configureRoutes() {
    return store;
}
