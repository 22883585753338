import {MarginMethod} from "./CommonConstants";

const PREFIX = "DROPPING_ODDS";

enum OddsConstants {
    GET_CURRENT_ODDS_SUCCESS,
    GET_CURRENT_PINNACLE_ODDS,
    GET_CURRENT_BETFAIR_ODDS,
    SET_CLASSIC_SETTINGS,
    SET_CLASSIC_ORDER,
    SET_CLASSIC_BOOKMAKER,

    NEW_EVENT_CHANGE
}

export enum OrderConstants {
    NONE,
    DATE,
    MARGIN,
    FROM_AND_TO,
}

export const GET_CURRENT_PINNACLE_ODDS = PREFIX + "_" + OddsConstants.GET_CURRENT_PINNACLE_ODDS;
export const GET_CURRENT_BETFAIR_ODDS = PREFIX + "_" + OddsConstants.GET_CURRENT_BETFAIR_ODDS;
export const GET_CURRENT_ODDS_SUCCESS = PREFIX + "_" + OddsConstants.GET_CURRENT_ODDS_SUCCESS;

export const SET_CLASSIC_SETTINGS = PREFIX + '_' + OddsConstants.SET_CLASSIC_SETTINGS;

export const SET_CLASSIC_ORDER = PREFIX + "_" + OddsConstants.SET_CLASSIC_ORDER;
export const SET_CLASSIC_BOOKMAKER = PREFIX + "_" + OddsConstants.SET_CLASSIC_BOOKMAKER;


export const NEW_EVENT_CHANGE = PREFIX + "_" + OddsConstants.NEW_EVENT_CHANGE;


export const CLASSIC_DROPPING_REFERENCE_DEFAULT = 0
export const CLASSIC_DROPPING_MARGIN_DEFAULT = 0
export const CLASSIC_DROPPING_BOOKMAKERS_DEFAULT = {
    '2': {
        status: true,
        method: MarginMethod.ORIGINAL,
        amountMarket: 0,
        amountOutcome: 0
    },
    '3': {
        status: false,
        method: MarginMethod.ORIGINAL,
        amountMarket: 0,
        amountOutcome: 0
    }
}
export const CLASSIC_DROPPING_TIMEOUT_SEC = 30

export const INSTANT_DROPPING_LIST_SIZE = 100
export const INSTANT_DROPPING_SHOW_SIZE = 20