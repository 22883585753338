import {call, put} from 'redux-saga/effects';

interface ICall {
    function: any;
    payload?: any;
}

export interface ISkeletonSaga {
    tryApiCall: ICall;
    failedType: string;
    catchBlock?: any;
    extraErrorPayload?: any;
}

export function* skeletonSaga(sagaPayload: ISkeletonSaga) {
    try {
        yield call(sagaPayload.tryApiCall.function, sagaPayload.tryApiCall.payload || null);
    } catch (error: any) {
        if (error.type) {
            //common error catched
            yield put({type: error.type, error});
        }
        if (sagaPayload.catchBlock) {
            yield call(sagaPayload.catchBlock, error);
        }
        yield put({type: sagaPayload.failedType, error, ...(sagaPayload.extraErrorPayload || null)});
    }
}

export const apiServiceUrl: string = `${process.env.REACT_APP_API_ENDPOINT || 'http://localhost:8080'}/v1/`;
export const socketServiceUrl: string = process.env.REACT_APP_SOCKET_ENDPOINT || 'http://localhost:5050';
export const socketServicePath: string = process.env.REACT_APP_SOCKET_PATH || '/ws/socket.io';
