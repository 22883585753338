import {MapPlayersPayload, SearchEventPayload} from '../@types/actions';
import {skeletonGet} from './skeleton/skeletonGet';
import {skeletonPost} from './skeleton/skeletonPost';

const playerEndpoint = 'player'

export const GetPlayersCall = (): Promise<any> => {
    return skeletonGet('player', playerEndpoint);
};

export const GetEventPlayersCall = (eventId: number): Promise<any> => {
    return skeletonGet(`player/${eventId}/players`, playerEndpoint);
};

export const GetEventPlayerCall = (eventId: number, playerId: string): Promise<any> => {
    return skeletonGet(`player/${eventId}/players/${playerId}`, playerEndpoint);
};

export const BlacklistPlayerCall = (eventId: number, playerId: string, bookmakerId: number): Promise<any> => {
    return skeletonPost({bookmakerId}, `player/${eventId}/players/${playerId}/blacklist`, playerEndpoint);
};

export const MapPlayersCall = (eventId: number, payload: MapPlayersPayload[]): Promise<any> => {
    return skeletonPost(payload, `player/${eventId}/whitelist`, playerEndpoint);
};

export const GetSettingsCall = (template: string = 'default'): Promise<any> => {
    return skeletonGet(`player/template/${template}`, playerEndpoint);
};

export const SearchPlayerEventCall = (payload: SearchEventPayload): Promise<any> => {
    return skeletonGet(`player/search/${payload.event}`, playerEndpoint);
};