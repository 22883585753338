import {call, put} from 'redux-saga/effects';
import {ISkeletonSaga, skeletonSaga} from '../../apis/skeleton/skeletonSaga';
import {GetMarketCapCall} from "../../apis/events";
import {GENERIC_ERROR} from "../../constants/ErrorConstants";
import {GET_MARKET_CAP_SUCCESS} from "../../constants/CommonConstants";

function* tryFunction() {
    const response: Record<string, string> = yield call(GetMarketCapCall);
    yield put({
        type: GET_MARKET_CAP_SUCCESS,
        payload: response
    });
}

export function* getMarketCapSaga(): Generator {
    const sagaPayload: ISkeletonSaga = {
        tryApiCall: {function: tryFunction},
        failedType: GENERIC_ERROR
    };
    yield call(skeletonSaga, sagaPayload);
}
