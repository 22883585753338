import {AnyAction} from 'redux';
import {PlayerReducer as State} from '../../@types/reducers';
import {
    BOOK_EVENT_SUCCESS,
    DELETE_SETTINGS_SUCCESS,
    GET_EVENT_SUCCESS,
    GET_PLAYERS,
    GET_PLAYERS_SUCCESS,
    GET_SETTINGS_SUCCESS,
    HIDE_ALERT_SUCCESS,
    NEW_BOOKED_EVENT,
    NEW_HIDDEN_ALERT,
    OrderConstants,
    PLAYER_EVENT,
    PLAYER_INITIALIZE_SUCCESS,
    SEARCH_PLAYER_EVENT_SUCCESS,
    SET_ORDER,
    SET_SETTINGS,
    SET_SETTINGS_AVERAGE_BOOKMAKERS,
    STORE_PLAYER_INITIALIZE,
} from "../../constants/PlayerConstants";
import {AVERAGE, MarginMethod} from "../../constants/CommonConstants";
import {AlertPlayerObject, EventPlayerStoredObjects, EventsStoredObjects} from "../../@types/response";
import {eventToStore, processEvent, sortEvent} from "../utils/playerUtils";

const defaultSettings = {
    bookmakers: [],
    comparisons: {
        '0': {
            status: true,
            method: MarginMethod.NEW,
            threshold: -10,
            level0: 0,
            level1: -1
        },
        '-1': {
            status: false,
            method: MarginMethod.NEW,
            threshold: 5,
            level0: 0,
            level1: -1
        },
        '2': {
            status: true,
            method: MarginMethod.ORIGINAL,
            amountMarket: 0,
            amountOutcome: 0,
            threshold: 3,
            level0: 0,
            level1: -1
        }
    },
    date: 0,
    from: 1,
    to: 1,
    order: OrderConstants.COMPARISON,
    markets: [],
    sports: {1: true, 2: true, 5: true}
}

const initialState: State = {
    initializing: true,
    monitors: [],
    booked: [],
    events: {},
    eventPlayers: null,
    hides: {},
    template: null,
    templates: [],
    settings: defaultSettings,
    suggestions: []
};

const playerReducer = (state = initialState, action: AnyAction): State => {
    switch (action.type) {
        case SET_SETTINGS: {
            const settings = Object.assign({}, state.settings, action.payload)
            return {
                ...state,
                settings: settings,
            }
        }
        case SET_SETTINGS_AVERAGE_BOOKMAKERS: {
            let settings = {...state.settings}
            settings.comparisons[AVERAGE].bookmakers = action.payload
            return {
                ...state,
                settings: settings,
            }
        }
        case STORE_PLAYER_INITIALIZE: {
            if (state.templates.includes(action.payload.name))
                return state
            return {
                ...state,
                templates: [...state.templates, action.payload.name]
            }
        }
        case PLAYER_INITIALIZE_SUCCESS: {
            return {
                ...state,
                settings: defaultSettings,
                hides: action.payload.hides,
                booked: action.payload.booked,
                initializing: false
            };
        }
        case GET_SETTINGS_SUCCESS: {
            return {
                ...state,
                settings: action.payload.settings,
                template: action.payload.template
            };
        }
        case DELETE_SETTINGS_SUCCESS: {
            return {
                ...state,
                templates: state.templates.filter((item: string) => item !== action.payload.template)
            };
        }
        case GET_PLAYERS: {
            return {
                ...state,
                initializing: true
            }
        }
        case SEARCH_PLAYER_EVENT_SUCCESS:
            return {
                ...state,
                suggestions: action.payload
            };
        case GET_PLAYERS_SUCCESS: {
            const events: EventsStoredObjects = action.payload
                .reduce((obj: any, item: any) => Object.assign(obj, {[item.eventId]: eventToStore(item)}), {});
            const monitors: AlertPlayerObject[] = action.payload
                .map((e: EventPlayerStoredObjects) => processEvent(e, state.settings, state.hides, state.booked))
                .flat()
                .sort((a: AlertPlayerObject, b: AlertPlayerObject) => sortEvent(a, b, state.events, state.settings))
            return {
                ...state,
                events,
                monitors,
                initializing: false
            };
        }
        case PLAYER_EVENT: {
            const monitors = state.monitors
                .filter(m => m.eventId !== action.payload.eventId)
                .concat(processEvent(action.payload, state.settings, state.hides, state.booked))
            const events: EventsStoredObjects = !(action.payload.eventId in state.events) ?
                Object.assign({}, state.events, {[action.payload.eventId]: eventToStore(action.payload)})
                : state.events;
            return {
                ...state,
                events: events,
                monitors: monitors
                    .sort((a, b) => sortEvent(a, b, state.events, state.settings))
            };
        }
        case SET_ORDER: {
            const settings = Object.assign({}, state.settings, {order: action.payload})
            const monitors = [...state.monitors]
                .sort((a, b) => sortEvent(a, b, state.events, settings))
            return {
                ...state,
                monitors,
                settings
            };
        }
        case HIDE_ALERT_SUCCESS:
        case NEW_HIDDEN_ALERT: {
            const hides = Object.assign({}, state.hides, action.payload)
            return {
                ...state,
                hides: hides,
                monitors: state.monitors.filter(alert => !Object.keys(action.payload).includes(`${alert.eventId}-${alert.playerId}-${alert.marketId}-${alert.sbv}-${alert.signId}`))
            };
        }
        case BOOK_EVENT_SUCCESS:
        case NEW_BOOKED_EVENT: {
            const booked = [...state.booked, action.payload]
            return {
                ...state,
                booked: booked,
                monitors: state.monitors.filter(alert => !booked.includes(`${alert.eventId}-${alert.playerId}-${alert.marketId}-${alert.sbv}-${alert.signId}`))
            };
        }
        case GET_EVENT_SUCCESS:
            return {
                ...state,
                eventPlayers: action.payload
            };
        default:
            return state;
    }
};

export default playerReducer;
