import {fork} from 'redux-saga/effects';
import * as Watcher from './watcher';

export default function* rootSaga(): Generator {
    yield fork(Watcher.login);
    yield fork(Watcher.logout);
    yield fork(Watcher.getUserInfo);
    yield fork(Watcher.getGroupBooks);
    yield fork(Watcher.getSports)
    yield fork(Watcher.getMarketCap);
    yield fork(Watcher.getEventMoneyLine);
}
