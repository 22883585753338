import React, {useCallback, useContext, useEffect, useState} from 'react';
import {ModalsContext} from './ModalsContext';
import {MODALS_TYPES} from '../../../constants/ModalsConstants';
import {EventStoredObject, ExtraMarketObject} from '../../../@types/response';
import {Dropdown, DropDownMultipleItem} from "../common/DropDown";
import {EXTRA_MARKET_GROUPS, EXTRA_MARKETS} from "../../../constants/CommonConstants";
import {formatTimestamp} from "../../../store/utils/commonUtils";
import {GetExtraEventCall} from "../../../apis/extra";
import {ModalExtraTable} from "../extra/ModalExtraTable";

const ExtraDetailsModal: React.FC = () => {
    const {modalsData, openedModals, setOpenedModalsCallback} = useContext(ModalsContext);
    const opening = openedModals.indexOf(MODALS_TYPES.EXTRA_DETAILS_MODAL) !== -1
    const onCancelClickCb = useCallback(() => {
        setOpenedModalsCallback(MODALS_TYPES.EXTRA_DETAILS_MODAL, false);
        // dispatch(ClearEventExtrasAction())
    }, [setOpenedModalsCallback]);

    const event: EventStoredObject = (modalsData[MODALS_TYPES.EXTRA_DETAILS_MODAL] || {}).event;

    const [eventExtraDetail, setEventExtraDetail] = useState<any>(null);

    const [selectedMarketGroups, setSelectedMarketGroups] = useState<string[]>([])
    const [selectedMarkets, setSelectedMarkets] = useState<ExtraMarketObject[]>([])

    const escFunction = useCallback((e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            setOpenedModalsCallback(MODALS_TYPES.EXTRA_DETAILS_MODAL, false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', escFunction, false);
        return () => {
            document.removeEventListener('keydown', escFunction, false);
        };
    }, [escFunction]);

    useEffect(() => {
        if (!opening || !event._id) return;
        GetExtraEventCall({eventId: event._id}).then((result) => {
            setEventExtraDetail(result)
            setSelectedMarketGroups(['Corners'])
        })
    }, [opening])

    useEffect(() => {
        let newMarkets: ExtraMarketObject[] = []
        selectedMarketGroups.forEach(g => {
            EXTRA_MARKET_GROUPS[g].forEach(mId => {
                newMarkets.push(EXTRA_MARKETS[mId])
            })
        })
        setSelectedMarkets(newMarkets);
    }, [selectedMarketGroups]);

    return (
            (openedModals.indexOf(MODALS_TYPES.EXTRA_DETAILS_MODAL) > -1 && (
                    <div className='fixed z-10 inset-0 overflow-y-auto'>
                        <div className='flex items-center justify-center min-h-screen text-center relative'>
                            <div className='fixed inset-0 bg-modal-layer transition-opacity'/>
                            <div style={{width: '700px'}}
                                 className='bg-eventDetail-primary rounded-lg text-left shadow-xl transform transition-all border-solid border-eventDetail-secondary border-2'>
                                <button
                                        type='button'
                                        className='hover:bg-blue-600 hover:text-black hover:border-black absolute z-20 bg-eventDetail-primary -right-4 -top-4 text-eventDetail-event border border-eventDetail-event rounded-full h-8 w-8'
                                        onClick={onCancelClickCb}
                                >
                                    x
                                </button>
                                <div className='text-white px-4 py-2'>
                                    <div className='mt-2'>
                                        <div className='flex'>
                                            <div style={{
                                                background: `url('${process.env.PUBLIC_URL}/static/img/logo_icon.svg')`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center'
                                            }}
                                                 className='w-[60px] h-[60px] mb-2.5'></div>
                                            <div className='flex flex-col justify-end mb-2.5 ml-2.5'>
                                                <h2>{event.name.substring(0, 40)}<span
                                                        className='absolute right-4'>ID: {event._id}</span></h2>
                                                <h2 className='text-eventDetail-event font-medium'>
                                                    {eventExtraDetail ? (eventExtraDetail.category || '').substring(0, 15) + ' - ' +
                                                            eventExtraDetail.tournament + ' | '
                                                            + formatTimestamp(eventExtraDetail.date, true)
                                                            : ''}
                                                </h2>
                                            </div>
                                        </div>
                                        <div className='flex border-b'>
                                            <div className='flex flex-col justify-end mb-2.5 ml-2.5'>
                                                <Dropdown label='MARKETS' color='default'>
                                                    {eventExtraDetail && Object.keys(EXTRA_MARKET_GROUPS)
                                                            .map((marketGroup) => {
                                                                return (
                                                                        <DropDownMultipleItem
                                                                                key={marketGroup}
                                                                                label={marketGroup}
                                                                                selected={selectedMarketGroups.includes(marketGroup)}
                                                                                onClick={() => {
                                                                                    if (selectedMarketGroups.includes(marketGroup)) {
                                                                                        if (selectedMarketGroups.length > 1) {
                                                                                            setSelectedMarketGroups(selectedMarketGroups.filter(g => g !== marketGroup))
                                                                                        }
                                                                                    } else {
                                                                                        setSelectedMarketGroups([marketGroup])
                                                                                    }
                                                                                }}
                                                                                onButtonClick={() => {
                                                                                    if (selectedMarketGroups.includes(marketGroup)) {
                                                                                        if (selectedMarketGroups.length > 1) {
                                                                                            setSelectedMarketGroups(selectedMarketGroups.filter(g => g !== marketGroup))
                                                                                        }
                                                                                    } else {
                                                                                        setSelectedMarketGroups(selectedMarketGroups.concat([marketGroup]))
                                                                                    }
                                                                                }}
                                                                        />
                                                                );
                                                            })}
                                                </Dropdown>
                                            </div>
                                        </div>
                                        <div
                                                className='w-full overflow-x-scroll overflow-y-visible h-[520px] dropdown-hidden-scrollbar'
                                        >
                                            <ModalExtraTable
                                                    odds={eventExtraDetail?.bookmakers || {}}
                                                    markets={selectedMarkets}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            )) ||
            null
    )
};
export default ExtraDetailsModal;
