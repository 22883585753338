const PREFIX = 'LOGIN'

// NOTE const enum are not supported by Babel
enum LoginConstants {
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGOUT,
    LOGOUT_SUCCESS,
    LOGOUT_FAILED
}

export const LOGIN = PREFIX + '_' + LoginConstants.LOGIN;
export const LOGIN_SUCCESS = PREFIX + '_' + LoginConstants.LOGIN_SUCCESS;
export const LOGIN_FAILED = PREFIX + '_' + LoginConstants.LOGIN_FAILED;
export const LOGOUT = PREFIX + '_' + LoginConstants.LOGOUT;
export const LOGOUT_SUCCESS = PREFIX + '_' + LoginConstants.LOGOUT_SUCCESS;
export const LOGOUT_FAILED = PREFIX + '_' + LoginConstants.LOGOUT_FAILED;
