import React, {useEffect, useRef} from 'react'
import io from 'socket.io-client';
import {useDispatch, useSelector} from 'react-redux';
import {socketServicePath, socketServiceUrl} from "../../../../apis/skeleton/skeletonSaga";
import {AlivePayload, SetMarketCapPayload} from "../../../../@types/actions";
import {SetMarketCapAction} from "../../../../store/actions/monitor/SetMarketCapAction";
import {InstantDropObject, Props} from "../../../../@types/formContext";
import {
    EventPlayerStoredObjects,
    EventStoredObject,
    MarketCapObject,
    MoneyLineObject
} from "../../../../@types/response";
import {SetMarketMoneyLineAction} from "../../../../store/actions/monitor/SetMarketMoneyLineAction";
import {SetAliveAction} from "../../../../store/actions/SetAliveAction";
import getClient from "../../../../store/selectors/getClient";
import {NewBookedEventAction} from "../../../../store/actions/monitor/NewBookedEventAction";
import {NewHiddenAlertAction} from "../../../../store/actions/monitor/NewHiddenAlertAction";
import {
    NewHiddenAlertAction as NewPlayerHiddenAlertAction
} from "../../../../store/actions/player/NewHiddenAlertAction";
import {UpdateEventAction} from "../../../../store/actions/monitor/UpdateEventAction";
import {PlayerEventAction} from "../../../../store/actions/player/PlayerEventAction";
import {WebSocketContext} from "./WebSocketContext";
import {getMarketCap} from "../../../../store/selectors/getMarketCap";
import {getEventsMoneyLine} from "../../../../store/selectors/getEventsMoneyLine";
import {LogoutAction} from "../../../../store/actions/LogoutAction";
import {NewDropAction} from "../../../../store/actions/dropping/NewDropAction";

const WebSocketProvider: React.FC<Props> = ({children}) => {

    const ws: any = useRef(null)

    const client: any = useSelector(getClient);
    const marketsCaps: MarketCapObject = useSelector(getMarketCap);
    const moneyLines: MoneyLineObject = useSelector(getEventsMoneyLine);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!ws.current && client) {
            connect()
        } else if (!client) {
            ws.current?.close()
        }
    }, [client])

    useEffect(() => {
        if (!client || !ws.current) return
        ws.current.off("event")
        ws.current.on("event", (event: EventStoredObject) => {
            dispatch(UpdateEventAction(event, marketsCaps, moneyLines));
        });
    }, [client, marketsCaps, moneyLines])

    function connect() {
        ws.current = io(socketServiceUrl, {transports: ["websocket"], path: socketServicePath});

        ws.current.on("connect", function () {
            ws.current.emit("authenticate", {id: client.id, group: client.group});
        });

        // COMMON ACTIONS
        ws.current.on("marketCap", (marketCap: SetMarketCapPayload) => {
            dispatch(SetMarketCapAction(marketCap));
        });
        ws.current.on("marketMoneyLine", (marketMoneyLine: MoneyLineObject) => {
            dispatch(SetMarketMoneyLineAction(marketMoneyLine));
        });
        ws.current.on("alive", (heartbeat: AlivePayload) => {
            dispatch(SetAliveAction(heartbeat));
        });
        ws.current.on("logout", () => {
            dispatch(LogoutAction());
        });

        // DROPPING ODDS ACTIONS
        ws.current.on("lastDrop", (drop: InstantDropObject) => {
            dispatch(NewDropAction(drop))
        });

        // MONITOR ACTIONS
        ws.current.on("bookEvent", (event: { book: string }) => {
            dispatch(NewBookedEventAction(event));
        });
        ws.current.on("hideAlert", (event: any) => {
            dispatch(NewHiddenAlertAction({[event.key]: parseFloat(event.margin)}));
        });

        // PLAYERS ACTIONS
        ws.current.on("player", (player: EventPlayerStoredObjects) => {
            dispatch(PlayerEventAction(player));
        });
        ws.current.on("hidePlayer", (event: any) => {
            dispatch(NewPlayerHiddenAlertAction({[event.key]: parseFloat(event.margin)}));
        });
    }

    return (
            <WebSocketContext.Provider value={{socket: ws.current}}>
                {children}
            </WebSocketContext.Provider>
    )

}
export default WebSocketProvider;
