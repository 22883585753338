import {OrderConstants} from "../../constants/HotMatchConstants";
import {EventStoredObject, HotMatchObject, HotMatchSettingsObject} from "../../@types/response";

/**
 * Sort Events
 *
 * @param a
 * @param b
 * @param settings
 */
// TODO
export const sortHotMatch = (a: HotMatchObject, b: HotMatchObject, settings: HotMatchSettingsObject): number => {
    switch (settings.order) {
        case OrderConstants.DELTA:
            if (a.v !== b.v) {
                return b.v - a.v
            } else {
                return a.dt - b.dt
            }
        case OrderConstants.DATE:
            if (a.dt !== b.dt) {
                return a.dt - b.dt
            } else {
                return b.v - a.v
            }
        case OrderConstants.ODD:
            if (a.o !== b.o) {
                return b.o - a.o
            } else {
                return b.v - a.v
            }
        case OrderConstants.TOT:
            if (a.sgt !== b.sgt) {
                return b.sgt - a.sgt
            } else {
                return b.v - a.v
            }
        case OrderConstants.NONE:
        default:
            return 0
    }
}

export const getEventFromHotMatch = (hotMatch: HotMatchObject): EventStoredObject => {
    return {
        _id: hotMatch.eid,
        sportId: hotMatch.sid,
        sport: hotMatch.s,
        categoryId: hotMatch.cid,
        category: hotMatch.c,
        tournamentId: hotMatch.tid,
        tournament: hotMatch.t,
        name: hotMatch.n,
        date: hotMatch.dt,
    }
}