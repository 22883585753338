import {takeLatest} from 'redux-saga/effects';
/*CONSTANTS*/
import {GET_CURRENT_BETFAIR_ODDS, GET_CURRENT_PINNACLE_ODDS} from '../../constants/OddsConstants';
/*SAGAS*/
import {getCurrentBetfairOddsSaga, getCurrentPinnacleOddsSaga} from "./getCurrentOddsSaga";


export function* GetCurrentBetfairOdds(): Generator {
    // @ts-ignore
    yield takeLatest(GET_CURRENT_BETFAIR_ODDS, getCurrentBetfairOddsSaga);
}

export function* GetCurrentPinnacleOdds(): Generator {
    // @ts-ignore
    yield takeLatest(GET_CURRENT_PINNACLE_ODDS, getCurrentPinnacleOddsSaga);
}


