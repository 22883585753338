import {call, put, select} from 'redux-saga/effects';
import {ISkeletonSaga, skeletonSaga} from '../../apis/skeleton/skeletonSaga';
import {GENERIC_ERROR} from '../../constants/ErrorConstants';
import {GET_EVENTS_SUCCESS} from '../../constants/MonitorConstants';
import {MarketCapObject, MoneyLineObject} from "../../@types/response";
import {getMarketCap} from "../../store/selectors/getMarketCap";
import {getEventsMoneyLine} from "../../store/selectors/getEventsMoneyLine";
import {GetSnapshotMonitorCall} from "../../apis/snapshot";

function* tryFunction() {
    const events: Record<string, string> = yield call(GetSnapshotMonitorCall);
    let marketsCap: MarketCapObject = yield select(getMarketCap);
    let moneyLines: MoneyLineObject = yield select(getEventsMoneyLine);
    yield put({
        type: GET_EVENTS_SUCCESS,
        payload: {events, marketsCap, moneyLines}
    });
}

export function* getEventsSaga(): Generator {
    const sagaPayload: ISkeletonSaga = {
        tryApiCall: {function: tryFunction},
        failedType: GENERIC_ERROR
    };
    yield call(skeletonSaga, sagaPayload);
}