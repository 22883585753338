import {FC} from "react";

interface FilterInputProps {
    id: string
    name: string
    label: string
    onChange: any
    value: number
    min?: number
    max?: number
    step?: number
}

const FilterInput: FC<FilterInputProps> = ({
                                               id,
                                               name,
                                               label,
                                               min,
                                               max,
                                               step,
                                               onChange,
                                               value
                                           }) => {
    return <div className="relative flex flex-col mr-2 2xl:text-monitorBase text-monitorSm">
        <label
                htmlFor={id}
                className={"text-white"}>{label}:</label>
        <input
                id={id} name={name}
                min={min} max={max} step={step}
                onChange={onChange} value={value}
                type="number"
                className="bg-toolbar-bgMain text-white"
                placeholder={label}/>

    </div>
}

export default FilterInput