import React from "react";
import {DoClassicSettingsObject, SuggestionObject} from "../../../@types/response";
import {useDispatch, useSelector} from "react-redux";
import FilterInput from "../common/FilterInput";
import getErrorMessage from "../../../store/selectors/getErrorMessage";
import {Autocomplete} from "../common/Autocomplete";
import {getSuggestions} from "../../../store/selectors/monitor/getSuggestions";
import Logo from "../logo";
import Separator from "../Separator";
import {SearchEventAction} from "../../../store/actions/monitor/SearchEventAction";
import {ToolBarClassicViewProps} from "../../../@types/inputs";
import {OrderConstants} from "../../../constants/OddsConstants";
import {BookmakerDropdown} from "./DropDown";
import {GetCurrentOddsAction} from "../../../store/actions/dropping/GetCurrentOdds";
import {HeartBeatDropDown} from "../common/HeartBeatDropDown";
import {getClassicSettings} from "../../../store/selectors/dropping/getClassicSettings";
import {SetClassicSettings} from "../../../store/actions/dropping/SetClassicSettings";
import {getClassicBookmaker} from "../../../store/selectors/dropping/getClassicBookmaker";

export default function DoClassicToolBar({
                                             setTimoutValue,
                                             timeoutValue,
                                         }: ToolBarClassicViewProps) {

    const suggestions: SuggestionObject[] = useSelector(getSuggestions);
    const errorMessage: string = useSelector(getErrorMessage);
    const dispatch = useDispatch();

    const settings: DoClassicSettingsObject = useSelector(getClassicSettings);
    const bookmaker: number = useSelector(getClassicBookmaker);

    return (
            <div className="flex w-full h-14 2xl:text-monitorBase text-monitorSm">
                <Logo/>
                <ul className="flex items-center justify-around py-2 w-full">
                    <Separator/>
                    <li className="mx-4">
                        <Autocomplete
                                placeholder="search event"
                                searchAction={(value) => dispatch(SearchEventAction(value))}
                                suggestions={suggestions}
                        />
                    </li>
                    <Separator/>
                    <li className="mx-4">
                        <div className="relative flex flex-col ml-4">
                            <label htmlFor="referenceFilter" className={"text-white"}>
                                Reference
                            </label>
                            <select
                                    id="referenceFilter"
                                    name="referenceFilter"
                                    className={"text-white bg-toolbar-bgMain"}
                                    onChange={({target}) => {
                                        const _settings = Object.assign({}, settings, {reference: parseInt(target.value)})
                                        dispatch(SetClassicSettings(_settings))
                                    }}
                                    value={settings.reference}
                            >
                                <option value={0}>Opening</option>
                                <option value={15}>15</option>
                                <option value={30}>30</option>
                                <option value={60}>60</option>
                                <option value={120}>120</option>
                                <option value={180}>180</option>
                                <option value={240}>240</option>
                                <option value={300}>300</option>
                            </select>
                        </div>
                    </li>
                    <Separator/>
                    <li className="mx-4">
                        <div className="relative flex flex-col ml-4">
                            <label htmlFor="dataFilter" className={"text-white"}>
                                DATE
                            </label>
                            <select
                                    id="dataFilter"
                                    name="dataFilter"
                                    className={"text-white bg-toolbar-bgMain"}
                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                        const _settings = Object.assign({}, settings, {date: parseInt(e.target.value)})
                                        dispatch(SetClassicSettings(_settings))
                                    }
                                    }
                                    value={settings.date}
                            >
                                <option value={0}>All</option>
                                <option value={1}>Today</option>
                                <option value={2}>Today + 1</option>
                                <option value={3}>Today + 2</option>
                                <option value={4}>Today + 3</option>
                                <option value={5}>Today + 4</option>
                                <option value={6}>Today + 5</option>
                                <option value={7}>Today + 6</option>
                            </select>
                        </div>
                    </li>
                    <Separator/>
                    <li className="mx-4">
                        <FilterInput
                                id="from"
                                name="from"
                                label="FROM"
                                min={1}
                                max={4}
                                step={0.1}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    let newOrder = OrderConstants.FROM_AND_TO
                                    if (e.target.value === "1") {
                                        newOrder = OrderConstants.NONE
                                    }
                                    const _settings = Object.assign({}, settings, {from: parseFloat(e.target.value), order: newOrder})
                                    dispatch(SetClassicSettings(_settings))
                                }}
                                value={settings.from}
                        />
                    </li>
                    <Separator/>
                    <li className="mx-4 ">
                        <FilterInput
                                id="to"
                                name="to"
                                label="TO"
                                min={1}
                                max={50}
                                step={0.1}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    let newOrder = OrderConstants.FROM_AND_TO
                                    if (e.target.value === "0") {
                                        newOrder = OrderConstants.NONE
                                    }
                                    const _settings = Object.assign({}, settings, {to: parseFloat(e.target.value), order: newOrder})
                                    dispatch(SetClassicSettings(_settings))
                                }}
                                value={settings.to}
                        />
                    </li>
                    <Separator/>
                    <li className="mx-4">
                        <div className="relative flex flex-col">
                            <label htmlFor="playabilityFilter" className={"text-white"}>
                                Items
                            </label>
                            <select
                                    id="playabilityFilter"
                                    name="playabilityFilter"
                                    className={"text-white bg-toolbar-bgMain"}
                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                        const _settings = Object.assign({}, settings, {pageSize: parseInt(e.target.value)})
                                        dispatch(SetClassicSettings(_settings))
                                    }}
                                    value={settings.pageSize}
                            >
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                        </div>
                    </li>
                    <Separator/>
                    <li className="flex flex-col items-center">
                        <BookmakerDropdown
                                settings={settings}
                                setSettings={(newSettings) => {
                                    dispatch(SetClassicSettings(newSettings))
                                }}
                        />
                    </li>

                    <Separator/>
                    <li className="mr-2 self-center md:w-60 md:pl-5 lg:w-40">
                        <div
                                className="relative flex-1 cursor-pointer"
                                onClick={() => {
                                    dispatch(GetCurrentOddsAction(bookmaker));
                                    setTimoutValue();
                                }}
                        >
                            <div className="w-full peer rounded-md bg-blue-500  py-2 text-center">
                            <span
                                    className="  text-white text-xs  lg:px-4
                            md:px-1"
                            >
                                Updating in <span>{timeoutValue}</span>
                            </span>
                            </div>
                        </div>
                    </li>

                    <li className="mr-4">
                        <HeartBeatDropDown/>
                    </li>
                </ul>
                <div>{errorMessage}</div>
            </div>
    );
}
