import {FC, useState} from "react";
import {BookmakerData, CategoriesFilter, CategoryObject, HistoryObject, MarketData} from "../../../@types/response";
import {useSelector} from "react-redux";
import {getMarkets} from "../../../store/selectors/getMarkets";
import {getBookmakers} from "../../../store/selectors/getBookmakers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faMinusCircle, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {DrawerCategoryProps, DrawerSportProps} from "../../../@types/inputs";


interface TreeItemProps {
    items: { backOdd: number, updt: number }[]
}

export const TreeSignView: FC<TreeItemProps> = ({items}) => {
    const [expanded, setExpanded] = useState(false);

    const childrens = expanded ? items : [items[0]]

    return <ul className="ml-12" onClick={() => setExpanded(!expanded)}>
        {childrens.map((item, idx) => {
            return <li
                    className='flex relative'
                    key={`sign-${idx}`}
            >
                {items.length > 1 && <div className='absolute -left-8'>
                    {expanded ? <FontAwesomeIcon icon={faMinusCircle}/> : <FontAwesomeIcon icon={faPlusCircle}/>}
                </div>}
                <span className='w-1/3'>{item.backOdd}</span>
                <span className='w-2/3'>
                    {new Date(item.updt).toLocaleDateString()} {new Date(item.updt).toLocaleTimeString()}&nbsp;&nbsp;
                    <small>{item.updt}</small>
                </span>
            </li>
        })}
    </ul>
}

interface TreeBookProps {
    items: any
    label: string
}

const TreeBookView: FC<TreeBookProps> = ({items, label}) => {

    const markets: MarketData = useSelector(getMarkets);

    const [expanded, setExpanded] = useState(true);

    return <>
        <div className='p-2 cursor-pointer' onClick={() => setExpanded(!expanded)}>{label}</div>
        {expanded && Object.keys(items).map((marketId: string) => {
            const market = markets[marketId]
            return <ul className="ml-4">
                <li><span>{market.name}</span>
                    {Object.keys(items[marketId]).map((signId: string) => {
                        return <ul className='ml-8'>
                            <li><span>{market.outcomes[signId].name}</span>
                                <TreeSignView items={items[marketId][signId]}/>
                            </li>
                        </ul>
                    })}
                </li>
            </ul>
        })}
    </>
}

interface TreeViewProps {
    history: HistoryObject
}

export const TreeView: FC<TreeViewProps> = ({history}) => {

    const bookmakers: BookmakerData = useSelector(getBookmakers);

    const colors: { [key: string]: string } = {
        '1': 'bg-blue-200', '2': 'bg-blue-500', '3': 'bg-yellow-200', '12': 'bg-yellow-500', '22': 'bg-green-200',
        '26': 'bg-green-500', '31': 'bg-yellow-200', '33': 'bg-yellow-500', '34': 'bg-indigo-200',
        '101': 'bg-red-200', '102': 'bg-red-500', '103': ''
    }

    return <div>
        {Object.keys(history).map((bookmakerId: string) => {
            return <ul className={colors[bookmakerId]} key={bookmakerId}>
                <li>
                    <TreeBookView
                            items={history[bookmakerId]}
                            label={bookmakers[bookmakerId]?.name ?? bookmakerId.toString()}
                    />
                </li>
            </ul>
        })}
    </div>
}

export const TreeSport: FC<DrawerSportProps> = ({sportId, sportName, categories, selection, onSportChange}) => {
    const [expanded, setExpanded] = useState(true);
    return <ul className='ml-4 mt-2'>
        <li>
            <div>
                <button
                        className='px-2'
                        onClick={() => setExpanded(!expanded)}
                >
                    <FontAwesomeIcon
                            className={'transition-transform duration-500 transform ' + (expanded ? 'rotate-90' : 'rotate-0')}
                            icon={faChevronRight}/>
                </button>

                <label className='inline-flex'>
                    <input
                            type='checkbox'
                            className='2xl:h-5 2xl:w-5 h-3 w-3'
                            checked={selection === true}
                            onChange={() => {
                                const _sport = {[sportId]: (selection === true) ? {} : true}
                                onSportChange(_sport)
                            }}
                    />
                    <span className='ml-2 2xl:text-monitorBase text-monitorSm'>{sportName}</span>
                </label>
            </div>
            {expanded && <ul className='ml-4'>
                {Object.values(categories)
                        .sort((a: CategoryObject, b: CategoryObject) => b.name > a.name ? -1 : 1)
                        .map((category: CategoryObject) => <TreeCategory
                                        key={`category-${category.id}`}
                                        category={category}
                                        selection={selection === true || (selection[category.id] ?? [])}
                                        onCategoryChange={(_category: CategoriesFilter) => {
                                            const isCategorySelected: boolean = Object.values(_category)[0] === true
                                            let newSelection: CategoriesFilter = selection === true
                                                    ? Object.keys(categories).reduce((a, v) => ({...a, [v]: true}), {})
                                                    : Object.assign({}, selection)
                                            if (isCategorySelected) {
                                                // {id: true} //tutti selezionati
                                                //devo contare le selezioni ed in caso seleziona anche lo sport
                                                newSelection = Object.assign({}, newSelection, _category)
                                            } else {
                                                // {id: [1?]} //almeno uno non selezinato
                                                //sicur sport non è selezionato
                                                newSelection = Object.keys(newSelection)
                                                        .filter(id => !Object.keys(_category).includes(id))
                                                        .reduce((a, v) => {
                                                            if (newSelection[v] !== true) {
                                                                return ({...a, [v]: newSelection[v]})
                                                            } else {
                                                                return ({...a, [v]: true})
                                                            }
                                                        }, {})
                                                const _s: true | number[] = Object.values(_category)[0]
                                                if (_s !== true && _s.length > 0) {
                                                    newSelection = Object.assign({}, newSelection, _category)
                                                }
                                            }
                                            const all: boolean = Object.values(newSelection).filter(i => i === true).length === Object.keys(categories).length
                                            onSportChange({[sportId]: all || newSelection})
                                        }}
                                />
                        )}
            </ul>
            }
        </li>
    </ul>
}

export const TreeCategory: FC<DrawerCategoryProps> = ({category, selection, onCategoryChange}) => {
    const [expanded, setExpanded] = useState(false)
    return <li
            className='my-2'
    >
        <div className='flex 2xl:text-monitorBase text-monitorSm'>
            <button
                    className='px-2'
                    onClick={() => setExpanded(!expanded)}
            >
                <FontAwesomeIcon
                        className={'transition-transform duration-500 transform ' + (expanded ? 'rotate-90' : 'rotate-0')}
                        icon={faChevronRight}/>
            </button>
            <label className='inline-flex'>
                <input
                        type='checkbox'
                        className='2xl:h-5 h-3'
                        checked={selection === true}
                        onChange={() => {
                            if (selection === true) {
                                onCategoryChange({[category.id]: []})
                            } else {
                                onCategoryChange({[category.id]: true})
                            }
                        }}
                />
                <span className='ml-2 2xl:text-monitorBase text-monitorSm'>{category.name}</span>
            </label>
        </div>
        {Object.keys(category.tournaments).length && expanded && <ul
                className='pl-10 mt-2 text-monitorBase'
        >
            {Object.values(category.tournaments).map(tournament => {
                const checked = selection === true || selection.includes(tournament.id)
                return <li
                        className='mb-0'
                        key={`tournament-${tournament.id}`}
                >
                    <label className='inline-flex'>
                        <input type='checkbox' className='2xl:h-5 h-3 flex-shrink-0'
                               checked={checked}
                               onChange={() => {
                                   let newSelections: number[] = selection === true ? Object.values(category.tournaments).map(k => k.id) : [...selection]
                                   if (!checked) {
                                       newSelections.push(tournament.id)
                                   } else {
                                       newSelections = newSelections.filter(i => i !== tournament.id)
                                   }
                                   const all: boolean = Object.keys(category.tournaments).length === newSelections.length
                                   // category.tournaments
                                   //     .filter(k => !newSelections.includes(k.id))
                                   //     .map(k => k.id).length === 0
                                   onCategoryChange({[category.id.toString()]: all || newSelections})
                               }}
                        />
                        <span
                                className='ml-2 2xl:text-monitorBase text-monitorSm whitespace-nowrap'>{tournament.name}</span>
                    </label>
                </li>
            })}
        </ul>}
    </li>
}

