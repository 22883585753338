/*CONSTANTS*/
import {
    CLIENT_IS_NOT_LOGGED_IN,
    ErrorCategory,
    PASSWORD_EXPIRED_ERROR,
    RESET_ERROR
} from '../../constants/ErrorConstants';
import {FailedAction} from '../../@types/actions';
import {ErrorsReducer as State} from '../../@types/reducers';
import {LOGIN, LOGIN_FAILED} from '../../constants/LoginConstants';
import {CHANGE_PASSWORD, CHANGE_PASSWORD_ERROR} from '../../constants/UserConstants';
import {GET_MONITOR_EVENTS_ERROR} from "../../constants/MonitorConstants";

const errorCategoryMapping = {
    [LOGIN_FAILED]: ErrorCategory.LOGIN,
    [CHANGE_PASSWORD_ERROR]: ErrorCategory.ACCOUNT_DETAILS,
    [GET_MONITOR_EVENTS_ERROR]: ErrorCategory.MONITOR
};

const initialState: State = {
    code: '',
    message: '',
    type: ErrorCategory.NO_ERROR,
    placeholder: ''
};

const ErrorsReducer = (state = initialState, action: FailedAction): State => {
    switch (action.type) {
        case GET_MONITOR_EVENTS_ERROR:
        case LOGIN_FAILED:
        case CHANGE_PASSWORD_ERROR:
        case PASSWORD_EXPIRED_ERROR: {
            // NOTE - after adding an error, add it to the resetting case too
            return {
                ...state,
                code: action.error.code,
                message: action.error.message,
                placeholder: action.error.placeholder,
                type: errorCategoryMapping[action.type] || initialState.type
            };
        }
        // RESETTING ERRORS
        case LOGIN:
        case CHANGE_PASSWORD: {
            if (
                [
                    ErrorCategory.LOGIN,
                ].includes(state.type)
            ) {
                return initialState;
            }
            return state;
        }
        /*    case RESET_ERROR:
            // prevent to clear code if it is = to PASSWORD_EXPIRED,
            // as we need to keep it in order to call getBalance after password has been changed
            return { ...initialState, code: state.code === PASSWORD_EXPIRED ? state.code : initialState.code }; */
        case CLIENT_IS_NOT_LOGGED_IN:
        case RESET_ERROR:
            return initialState;
        default:
            return state;
    }
};

export default ErrorsReducer;
