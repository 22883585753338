import {BookmakerData, ComparisonSettingsObject, PlayerSettingsObject} from "../../../@types/response";
import {Dropdown, DropDownSettingItem} from "../common/DropDown";
import {FC, Fragment} from "react";
import {MAX_LIMIT, MIN_LIMIT} from "../../../constants/PlayerConstants";
import {AVERAGE, AVERAGE_2, BETFAIR, MarginFilter} from "../../../constants/CommonConstants";

interface ComparisonsDropdownProps {
    settings: PlayerSettingsObject
    bookmakers: BookmakerData
    loading: boolean
    setSetting: (setting: any) => void
}

export const ComparisonsDropdown: FC<ComparisonsDropdownProps> = ({settings, bookmakers, loading, setSetting}) => {
    return <Fragment>
        {loading ?
                <Dropdown label='COMPARISONS' color='default'/>
                : Object.keys(bookmakers).length ?
                        <Dropdown label='COMPARISONS' color='default' isMaxHeight={true}>
                            {["2", "0", "-1"].map((comparisonId) => {
                                const bookmaker = bookmakers[comparisonId]
                                if (!bookmaker)
                                    return null
                                const currentSettings: ComparisonSettingsObject = settings.comparisons[comparisonId]
                                return <DropDownSettingItem
                                        key={comparisonId}
                                        label={bookmaker.name}
                                        settings={currentSettings}
                                        onChange={(newSettings) => {
                                            let newComparisons = Object.assign({}, settings.comparisons)
                                            newComparisons[comparisonId] = newSettings
                                            Object.entries(newComparisons)
                                                    .forEach(([cId, c]) => {
                                                        if (parseInt(comparisonId) === AVERAGE_2) {
                                                            c.status = parseInt(cId) === AVERAGE_2
                                                        } else {
                                                            c.status = c.status && parseInt(cId) !== AVERAGE_2
                                                        }
                                                    })
                                            setSetting(Object.assign({}, settings, {comparisons: newComparisons}))
                                        }}
                                        hasAmountMarket={Number(comparisonId) === BETFAIR}
                                        hasAmountOutcome={Number(comparisonId) === BETFAIR}
                                        hasBookmakers={Number(comparisonId) === AVERAGE || Number(comparisonId) === AVERAGE_2}
                                        margin={MarginFilter.HIDDEN}
                                        marginPercentage={false}
                                        minLimit={MIN_LIMIT}
                                        maxLimit={MAX_LIMIT}
                                        isPlayer={true}
                                />
                            })}
                        </Dropdown> :
                        <Dropdown label='COMPARISONS' color='default'/>
        }
    </Fragment>
}