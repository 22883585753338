import {call, put} from 'redux-saga/effects';
import {ISkeletonSaga, skeletonSaga} from '../../apis/skeleton/skeletonSaga';
import {GENERIC_ERROR} from '../../constants/ErrorConstants';
import {GET_HOT_MATCHES_SUCCESS} from "../../constants/HotMatchConstants";
import {GetHotMatchesCall} from "../../apis/hotmatch";
import {HotMatchSettingsObject} from "../../@types/response";

function* tryFunction(payload: HotMatchSettingsObject) {
    const response: Record<string, string> = yield call(GetHotMatchesCall, payload);
    yield put({
        type: GET_HOT_MATCHES_SUCCESS,
        payload: response
    });
}

export function* getHotMatchesSaga({payload}: { payload: HotMatchSettingsObject }): Generator {
    const sagaPayload: ISkeletonSaga = {
        tryApiCall: {function: tryFunction, payload: payload},
        failedType: GENERIC_ERROR
    };
    yield call(skeletonSaga, sagaPayload);
}