import {skeletonPost} from './skeleton/skeletonPost';
import {BookPayload, HideAlertPayload, HidePlayerPayload, LoginPayload} from '../@types/actions';
import {skeletonGet} from "./skeleton/skeletonGet";
import {GroupFilterObject} from "../@types/response";

const userEndpoint = 'user'

export const LoginCall = (payload: LoginPayload): Promise<any> => {
    return skeletonPost(payload, 'login', userEndpoint)
};

export const LogoutCall = (): any => {
    return skeletonPost(null, 'logout', userEndpoint)
};

export const GetUserInfoCall = (): Promise<any> => {
    return skeletonGet('session', userEndpoint);
};

export const GetBookmakersCall = (): Promise<any> => {
    return skeletonGet('bookmakers', userEndpoint);
};

export const GetInitialEventsCall = (): Promise<any> => {
    return skeletonGet('monitor', userEndpoint);
};

export const GetInitialPlayerCall = (): Promise<any> => {
    return skeletonGet('player', userEndpoint);
};

export const GetInitialExtraCall = (): Promise<any> => {
    return skeletonGet('extra', userEndpoint);
};

export const BookEventCall = (payload: BookPayload): Promise<any> => {
    return skeletonPost(payload, 'book/event', userEndpoint);
};

export const GetTeamCall = (): Promise<any> => {
    return skeletonGet('group', userEndpoint);
};

export const GetBookCall = (filters: GroupFilterObject): Promise<any> => {
    const query = new URLSearchParams({
        dateFrom: filters.dateFrom.toString(),
        dateTo: filters.dateTo.toString(),
        oddFrom: filters.oddFrom.toString(),
        oddTo: filters.oddTo.toString()
    }).toString()
    return skeletonGet(`book?${query}`, userEndpoint);
};

export const BookPlayerCall = (payload: BookPayload): Promise<any> => {
    return skeletonPost(payload, 'book/player', userEndpoint);
};

export const BookExtraCall = (payload: BookPayload): Promise<any> => {
    return skeletonPost(payload, 'book/extra', userEndpoint);
};

export const HideAlertCall = (payload: HideAlertPayload): Promise<any> => {
    return skeletonPost(payload, `hide/event/${payload.eventId.toString()}`, userEndpoint);
};

export const HidePlayerCall = (payload: HidePlayerPayload): Promise<any> => {
    return skeletonPost(payload, `hide/event/${payload.eventId}/players/${payload.playerId}/hide`, userEndpoint);
};
