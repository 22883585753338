import React, {useCallback, useState} from 'react';
import {ModalsContext} from './ModalsContext';
import {
    AlertMonitorObject,
    AlertPlayerObject,
    EventOddObject,
    EventStoredObject,
    OddsObject
} from "../../../@types/response";
import {Props} from '../../../@types/formContext';
import {ExtraModalProps, PlayerMarketModalProps, PlayerModalProps} from "../../../@types/inputs";

interface ContinueModalDataType {
    confirmAction: (v: any) => void;
    confirmValues: { [k: string]: any };
    messageStrings?: (string | number)[];
}

interface EventDetailsModalDataType {
    event: {
        id: number;
        name: string;
    }
}

interface BookEventModalDataType {
    event: EventStoredObject;
    bookmakerId: number;
    odd: OddsObject;
}

interface BookPlayerModalDataType {
    event: EventStoredObject;
    bookmakerId: number;
    odd: number;
}

interface OddHistoryModalDataType {
    odd: EventOddObject;
    event: {
        id: number;
        name: string;
    }
}

interface HideAlertModalDataType {
    alert: AlertMonitorObject;
}

interface HidePlayerModalDataType {
    alert: AlertPlayerObject;
}

export type ModelDataType =
        ContinueModalDataType
        | EventDetailsModalDataType
        | PlayerModalProps
        | PlayerMarketModalProps
        | ExtraModalProps
        | BookEventModalDataType
        | BookPlayerModalDataType
        | OddHistoryModalDataType
        | HideAlertModalDataType
        | HidePlayerModalDataType; //put here other modal type in union

const ModalsProvider: React.FC<Props> = ({children}) => {
    const [modalsData, setModalsData] = useState<{ [k: string]: any }>({});
    const [openedModals, setOpenedModals] = useState<number[]>([]);

    const setModalsDataCallback = useCallback((modalType: number, values: ModelDataType) => {
        setModalsData(prev => ({...prev, [modalType]: {...prev[modalType], ...values}}));
    }, []);

    const setOpenedModalsCallback = useCallback((modalType: number, open: boolean) => {
        setOpenedModals(prev => {
            if (open) {
                return [...prev, modalType];
            } else {
                return prev.filter(type => type !== modalType);
            }
        });
    }, []);

    return (
            <ModalsContext.Provider value={{modalsData, setModalsDataCallback, openedModals, setOpenedModalsCallback}}>
                {children}
            </ModalsContext.Provider>
    );
};

export default ModalsProvider;
