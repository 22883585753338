import {call, put} from 'redux-saga/effects';
import {ISkeletonSaga, skeletonSaga} from '../../apis/skeleton/skeletonSaga';
import {GENERIC_ERROR} from '../../constants/ErrorConstants';
import {DeleteAlertCall} from '../../apis/extra';
import {DeleteAlertMarketPayload} from '../../@types/actions';
import {DELETE_ALERT_SUCCESS} from '../../constants/ExtraConstants';

function* tryFunction(payload: DeleteAlertMarketPayload) {
    yield call(DeleteAlertCall, payload);
    yield put({
        type: DELETE_ALERT_SUCCESS,
        payload: payload
    });
}

export function* deleteAlertSaga({payload}: { payload: DeleteAlertMarketPayload }): Generator {
    const sagaPayload: ISkeletonSaga = {
        tryApiCall: {function: tryFunction, payload: payload},
        failedType: GENERIC_ERROR
    };
    yield call(skeletonSaga, sagaPayload);
}