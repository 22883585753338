import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom';
import {connect, ConnectedProps} from 'react-redux';
import {Store} from 'redux';
import {RoutesEnum, routesPaths} from '../../routes/routes';
// import hasLoginFailed from '../../store/selectors/hasLoginFailed';
import getErrorCode from '../../store/selectors/getErrorCode';
import {LoginAction} from '../../store/actions/LoginAction';

const stateToProps = (state: Store) => ({
            // @ts-ignore
            hasLoginApiError: false,
            hasWrongUsernamePassword: false,
            // @ts-ignore
            errorCode: getErrorCode(state)
            // hasLoginApiError: hasLoginFailed(state),
            // hasWrongUsernamePassword: [
            //     ERROR_LOGIN_WRONG_USERNAME_PASSWORD,
            //     ERROR_LOGIN_WRONG_PASSWORD,
            //     ERROR_LOGIN_WRONG_USERNAME
            // ].includes(getErrorCode(state)),
            // errorCode: getErrorCode(state)
        }),
        connector = connect(stateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
    hasLoginApiError: boolean;
    hasWrongUsernamePassword: boolean;
    errorCode: string | number;
};

const Login = ({
                   hasLoginApiError,
                   hasWrongUsernamePassword,
                   errorCode,
                   dispatch
               }: Props): React.ReactElement<HTMLDivElement> => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();

    function keyPerformLogin(event: { key: string }) {
        if (event.key === 'Enter') {
            // onSubmit()
        }
        console.debug(hasLoginApiError || hasWrongUsernamePassword || errorCode)
    }

    // useEffect(() => {
    //     // no dependencies is specified as only the first state is relevant
    //     //   dispatch(CheckSessionAction());
    //     //CheckSessionAction has been replaced by GetUserInfoAction, as this one returns agent data too
    //     dispatch(GetUserInfoAction());
    //     //dispatch(GetMessagesAction()); //moved to Container, to block app render until messages return
    // }, []);

    const onSubmit = (e: any) => {
        e.preventDefault()
        navigate(routesPaths[RoutesEnum.HOME]);
        dispatch(
                LoginAction({
                    username,
                    password
                })
        );
    }

    return <div className='login h-screen flex justify-center items-center'>
        <form className='p-8 mb-4 flex flex-col bg-login-bg border rounded-md' onSubmit={onSubmit}>
            <div className='py-4 text-center font-semibold'>Sign in to your account</div>
            <div className='mb-4'>
                <label className='block text-grey-darker text-sm font-bold mb-2' htmlFor='username'>
                    Username:
                </label>
                <input
                        type='text'
                        id='username'
                        name='username'
                        className='shadow appearance-none border text-sm rounded w-full py-2 px-3 text-grey-darker'
                        onKeyDown={keyPerformLogin}
                        value={username}
                        onChange={({target}) => setUsername(target.value)}
                />
            </div>

            <div className='mb-6'>
                <label className='block text-grey-darker text-sm font-bold mb-2' htmlFor='password'>
                    Password:
                </label>
                <input
                        type='password'
                        id='password'
                        name='password'
                        className='shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-darker mb-3'
                        onKeyDown={keyPerformLogin}
                        value={password}
                        onChange={({target}) => setPassword(target.value)}
                />
                {false && <p className='text-red text-xs italic'>Please choose a password.</p>}
            </div>

            <div>
                <button
                        id='login-btn'
                        title='Login'
                        name='submit'
                        className='bg-[#161D22] hover:bg-blue-600 text-white font-bold py-2 px-4 rounded w-full'
                        onClick={onSubmit}
                >Login
                </button>
            </div>

            {/*<p className='login__error mt10'>{props.errorMessage}</p>*/}

        </form>
    </div>

}

export default connector(Login);