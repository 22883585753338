import {call, put} from 'redux-saga/effects';
import {GET_SPORTS_ERROR, GET_SPORTS_SUCCESS} from '../../constants/CommonConstants';
import {ISkeletonSaga, skeletonSaga} from '../../apis/skeleton/skeletonSaga';
import {FixtureObject} from "../../@types/response";
import {GetSportsCall} from "../../apis/events";

function* tryFunction() {
    const response: FixtureObject = yield call(GetSportsCall)
    yield put({
        type: GET_SPORTS_SUCCESS,
        payload: response
    });
}

export function* getSportsSaga(): Generator {
    const sagaPayload: ISkeletonSaga = {
        tryApiCall: {function: tryFunction},
        failedType: GET_SPORTS_ERROR
    };
    yield call(skeletonSaga, sagaPayload);
}
