import React, {useCallback, useContext} from 'react';
import {ModalsContext} from './ModalsContext';
import {MODALS_TYPES} from '../../../constants/ModalsConstants';

const ContinueModal: React.FC = () => {
    const {modalsData, openedModals, setOpenedModalsCallback} = useContext(ModalsContext);
    const {confirmAction, confirmValues} = (modalsData || {})[MODALS_TYPES.CONTINUE_MODAL] || {};

    const onConfirmClickCb = useCallback(() => {
        confirmAction(confirmValues);
        setOpenedModalsCallback(MODALS_TYPES.CONTINUE_MODAL, false);
    }, [confirmAction, confirmValues, setOpenedModalsCallback]);

    const onNotConfirmClickCb = useCallback(() => {
        setOpenedModalsCallback(MODALS_TYPES.CONTINUE_MODAL, false);
    }, [setOpenedModalsCallback]);

    return (
            (openedModals.indexOf(MODALS_TYPES.CONTINUE_MODAL) > -1 && (
                    <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog"
                         aria-modal="true">
                        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <div className="fixed inset-0 bg-modal-layer transition-opacity"
                                 aria-hidden="true"/>

                            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"
                                  aria-hidden="true">&#8203;</span>

                            <div
                                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div
                                                className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                            V
                                        </div>
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                            <h3 className="text-lg leading-6 font-medium text-gray-900"
                                                id="modal-title">
                                                Deactivate account
                                            </h3>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    Are you sure you want to deactivate your account? All of your data
                                                    will be
                                                    permanently removed. This action cannot be undone.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <button
                                            type="button"
                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={onConfirmClickCb}
                                    >Deactivate
                                    </button>
                                    <button
                                            type="button"
                                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={onNotConfirmClickCb}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
            )) ||
            null
    );
};
export default ContinueModal;
