const PREFIX = 'ERROR'

export enum ErrorCategory {
    NO_ERROR,
    LOGIN,
    MONITOR,
    ACCOUNT_DETAILS,
    RECOVERY_PASSWORD
}

enum ErrorConstants {
    // SESSION
    RESET_ERROR,
    PASSWORD_EXPIRED_ERROR,
    CLIENT_IS_NOT_LOGGED_IN,
    SESSION_EXPIRED_NOT_FOUND,

    // GENERIC
    API_GENERIC_ERROR,


    //COMMON
    GENERIC_ERROR,
    PASSWORD_EXPIRED
}

export const CLIENT_IS_NOT_LOGGED_IN = PREFIX + '_' + ErrorConstants.CLIENT_IS_NOT_LOGGED_IN;
export const PASSWORD_EXPIRED_ERROR = PREFIX + '_' + ErrorConstants.PASSWORD_EXPIRED_ERROR;
export const RESET_ERROR = PREFIX + '_' + ErrorConstants.RESET_ERROR;

export const GENERIC_ERROR = PREFIX + '_' + ErrorConstants.GENERIC_ERROR;

//ERROR CODES
export const SESSION_EXPIRED_NOT_FOUND = -22301;
export const PASSWORD_EXPIRED = -23012;

