import {skeletonGet} from './skeleton/skeletonGet';

const snapshotMonitorEndpoint = 'sm'
const snapshotPlayerEndpoint = 'sp'

export const GetSnapshotMonitorCall = (): Promise<any> => {
    return skeletonGet('monitor', snapshotMonitorEndpoint);
};

export const GetSnapshotPlayersCall = (): Promise<any> => {
    return skeletonGet('player', snapshotPlayerEndpoint);
};
