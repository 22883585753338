const PREFIX = 'USER'

enum UserConstants {
    GET_USER_INFO,
    GET_USER_INFO_SUCCESS,
    GET_USER_INFO_ERROR,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_ERROR,
}

const GROUP_PREFIX = 'GROUP'

enum GroupConstants {
    GET_BOOKS,
    GET_BOOKS_SUCCESS,
    GET_BOOKS_ERROR
}

export const GET_USER_INFO = PREFIX + '_' + UserConstants.GET_USER_INFO;
export const GET_USER_INFO_SUCCESS = PREFIX + '_' + UserConstants.GET_USER_INFO_SUCCESS;
export const GET_USER_INFO_ERROR = PREFIX + '_' + UserConstants.GET_USER_INFO_ERROR;
export const CHANGE_PASSWORD = PREFIX + '_' + UserConstants.CHANGE_PASSWORD;
export const CHANGE_PASSWORD_SUCCESS = PREFIX + '_' + UserConstants.CHANGE_PASSWORD_SUCCESS;
export const CHANGE_PASSWORD_ERROR = PREFIX + '_' + UserConstants.CHANGE_PASSWORD_ERROR;

export const GET_BOOKS = GROUP_PREFIX + '_' + GroupConstants.GET_BOOKS;
export const GET_BOOKS_SUCCESS = GROUP_PREFIX + '_' + GroupConstants.GET_BOOKS_SUCCESS;
export const GET_BOOKS_ERROR = GROUP_PREFIX + '_' + GroupConstants.GET_BOOKS_ERROR;
