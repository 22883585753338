import {AnyAction} from 'redux';
import {ExtraReducer as State} from '../../@types/reducers';
import {
    BOOK_EVENT_SUCCESS,
    DELETE_ALERT_SUCCESS,
    DELETE_EXTRA_SETTINGS_SUCCESS,
    EDIT_EXTRA_EVENT,
    EXTRA_INITIALIZE_SUCCESS,
    GET_EVENT_SUCCESS,
    GET_EXTRA_EVENTS,
    GET_EXTRA_EVENTS_SUCCESS,
    GET_EXTRA_SETTINGS_SUCCESS,
    HIDE_ALERT_SUCCESS,
    NEW_BOOKED_EVENT,
    NEW_HIDDEN_ALERT,
    SEARCH_EVENT_SUCCESS,
    SET_ORDER,
    SET_SETTINGS,
    STORE_EXTRA_INITIALIZE,
} from "../../constants/ExtraConstants";
import {processEvent, sortEvent} from "../utils/extraUtils";
import {GET_BOOKS_SUCCESS} from "../../constants/UserConstants";
import {AlertExtraObject, EventExtraStoredObject, EventStoredObject} from '../../@types/response';
import {eventToStore} from '../utils/events';
import {MarginMethod} from "../../constants/CommonConstants";

const initialState: State = {
    initializing: true,
    event: undefined,
    events: {},
    extras: [],
    booked: [],
    hides: {},
    books: [],
    suggestions: [],
    template: null,
    templates: [],
    settings: {
        bookmakers: [],
        comparisons: {
            '0': {
                status: true,
                method: MarginMethod.NEW,
                threshold: -10,
                level0: 0,
                level1: -1,
                bookmakers: [1, 12, 107],
            },
        },
        date: 0,
        from: 1,
        to: 1,
        order: 3,
        onlyDrop: false,
        playability: 0,
        sports: {1: true, 2: true, 5: true}
    }
};

const extraReducer = (state = initialState, action: AnyAction): State => {
    switch (action.type) {
        case SET_SETTINGS: {
            const settings = Object.assign({}, state.settings, action.payload)
            return {
                ...state,
                settings: settings,
            }
        }
        case STORE_EXTRA_INITIALIZE: {
            if (state.templates.includes(action.payload.name))
                return state
            return {
                ...state,
                templates: [...state.templates, action.payload.name]
            }
        }
        case EXTRA_INITIALIZE_SUCCESS: {
            return {
                ...state,
                initializing: false,
                booked: action.payload.booked,
                hides: action.payload.hides,
                templates: [],
            };
        }
        case GET_EXTRA_SETTINGS_SUCCESS: {
            return {
                ...state,
                initializing: false,
                settings: action.payload.settings,
                template: action.payload.template
            };
        }
        case DELETE_EXTRA_SETTINGS_SUCCESS: {
            return {
                ...state,
                templates: state.templates.filter((item: string) => item !== action.payload.template)
            };
        }
        case GET_EXTRA_EVENTS: {
            return {
                ...state,
                initializing: true
            }
        }
        case GET_EXTRA_EVENTS_SUCCESS: {
            const events: { [key: string]: EventStoredObject } = action.payload
                .reduce((obj: any, item: any) => Object.assign(obj, {[item._id]: eventToStore(item)}), {});
            const extras: AlertExtraObject[] = action.payload.map((e: EventExtraStoredObject) => processEvent(e, state.settings, state.booked, state.hides)).flat()
            return {
                ...state,
                events: events,
                extras: extras.sort((a: AlertExtraObject, b: AlertExtraObject) => sortEvent(a, b, events, state.settings)),
                initializing: false
            };
        }
        case EDIT_EXTRA_EVENT: {
            const _events = Object.assign({}, state.events, {[action.payload.event._id]: eventToStore(action.payload.event)})
            const _newAlerts: AlertExtraObject[] = processEvent(action.payload.event, state.settings, state.booked, state.hides)
            const _rmAlerts = state.extras.filter(_m => _m.id !== action.payload.event._id)
            let _extras = !_newAlerts.length && state.extras.length === _rmAlerts.length ? state.extras :
                _rmAlerts.concat(_newAlerts)
                    .sort((a: AlertExtraObject, b: AlertExtraObject) => sortEvent(a, b, _events, state.settings))
            return {
                ...state,
                events: _events,
                extras: _extras
            };
        }
        case SET_ORDER: {
            const settings = Object.assign({}, state.settings, {order: action.payload})
            const extras = [...state.extras].sort((a, b) => sortEvent(a, b, state.events, settings))
            return {
                ...state,
                extras,
                settings
            };
        }
        case SEARCH_EVENT_SUCCESS:
            return {
                ...state,
                suggestions: action.payload
            };
        case GET_EVENT_SUCCESS: {
            return {
                ...state,
                event: action.payload
            };
        }
        case GET_BOOKS_SUCCESS: {
            return {
                ...state,
                books: action.payload
            };
        }
        case BOOK_EVENT_SUCCESS:
        case NEW_BOOKED_EVENT: {
            const booked = [...state.booked, action.payload]
            return {
                ...state,
                booked: booked,
                extras: state.extras.filter(alert => action.payload !== `${alert.id}-${alert.marketId}-${alert.signId}`)
            };
        }
        case DELETE_ALERT_SUCCESS: {
            return {
                ...state,
                extras: state.extras
                    .filter(alert => {
                        return !(alert.id === action.payload.eventId
                            && alert.marketId === action.payload.marketId)
                    })
            }
        }
        case HIDE_ALERT_SUCCESS:
        case NEW_HIDDEN_ALERT: {
            const hides = Object.assign({}, state.hides, action.payload)
            return {
                ...state,
                hides: hides,
                extras: state.extras.filter(alert => !Object.keys(action.payload).includes(`${alert.id}-${alert.marketId}-${alert.signId}`))
            };
        }
        default:
            return state;
    }
};

export default extraReducer;
