import {takeLatest} from 'redux-saga/effects';
/*CONSTANTS*/
import {
    BOOK_EVENT,
    DELETE_ALERT,
    DELETE_MONITOR_SETTINGS,
    GET_EVENT,
    GET_EVENTS,
    GET_MONITOR_EVENTS,
    GET_MONITOR_ODDS_EVENTS,
    GET_MONITOR_SETTINGS,
    HIDE_ALERT,
    MONITOR_INITIALIZE,
    SEARCH_EVENT,
    STORE_MONITOR_INITIALIZE
} from '../../constants/MonitorConstants';
/*SAGAS*/
import {getMonitorEventsSaga} from './getMonitorEventsSaga';
import {getMonitorOddsEventsSaga} from './getMonitorOddsEventsSaga';
import {getEventSaga} from "./getEventSaga";
import {GetBookedEventsSaga} from "./getBookedEvenstsSaga";
import {bookEventSaga} from "./bookEventSaga";
import {searchEventSaga} from "./searchEventSaga";
import {storeSettingSaga} from "./storeSettingSaga";
import {hideAlertSaga} from "./hideAlert";
import {getSettingSaga} from "./getSettingSaga";
import {deleteSettingSaga} from "./deleteSettingSaga";
import {getEventsSaga} from './getEventsSaga';
import {deleteAlertSaga} from './deleteAlert';

export function* StoreSetting(): Generator {
    // @ts-ignore
    yield takeLatest(STORE_MONITOR_INITIALIZE, storeSettingSaga);
}

export function* GetSetting(): Generator {
    // @ts-ignore
    yield takeLatest(GET_MONITOR_SETTINGS, getSettingSaga);
}

export function* DeleteSetting(): Generator {
    // @ts-ignore
    yield takeLatest(DELETE_MONITOR_SETTINGS, deleteSettingSaga);
}

export function* GetBookedEvents(): Generator {
    // @ts-ignore
    yield takeLatest(MONITOR_INITIALIZE, GetBookedEventsSaga);
}

export function* GetMonitorEvents(): Generator {
    yield takeLatest(GET_MONITOR_EVENTS, getMonitorEventsSaga);
}

export function* GetMonitorOddsEvents(): Generator {
    yield takeLatest(GET_MONITOR_ODDS_EVENTS, getMonitorOddsEventsSaga);
}

export function* GetEvent(): Generator {
    // @ts-ignore
    yield takeLatest(GET_EVENT, getEventSaga);
}

export function* BookEvent(): Generator {
    // @ts-ignore
    yield takeLatest(BOOK_EVENT, bookEventSaga);
}

export function* DeleteAlert(): Generator {
    // @ts-ignore
    yield takeLatest(DELETE_ALERT, deleteAlertSaga);
}

export function* SearchEvent(): Generator {
    // @ts-ignore
    yield takeLatest(SEARCH_EVENT, searchEventSaga);
}

export function* HideAlert(): Generator {
    // @ts-ignore
    yield takeLatest(HIDE_ALERT, hideAlertSaga);
}

export function* GetEvents(): Generator {
    // @ts-ignore
    yield takeLatest(GET_EVENTS, getEventsSaga);
}


