import React, {Suspense, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Loader from '../components/common/Loader';
import isSessionChecked from '../../store/selectors/isSessionChecked';
import {GetUserInfoAction} from "../../store/actions/GetUserInfoAction";

interface Props {
    children: React.ReactNode;
}

const LoaderCheckSession: React.FC<Props> = ({children}) => {

    const [checkedSession, setCheckSession] = useState<boolean>(false);
    const isCheckCompleted = useSelector(isSessionChecked);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetUserInfoAction())
    }, []);

    useEffect(() => {
        isCheckCompleted && setCheckSession(true);
    }, [isCheckCompleted]);

    return (
            // 'Suspense' doesn't handle API response time yet, it only follow children mounting phases
            <Suspense fallback={<Loader/>}>{(!checkedSession && <Loader/>) || children}</Suspense>
    );
};

export default LoaderCheckSession;
