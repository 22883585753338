import {call} from 'redux-saga/effects';
import {ISkeletonSaga, skeletonSaga} from '../../apis/skeleton/skeletonSaga';
import {UserMonitorPayload} from '../../@types/actions';
import {StoreSettingCall} from "../../apis/extra";
import {STORE_EXTRA_INITIALIZE_ERROR} from "../../constants/ExtraConstants";

function* tryFunction(payload: UserMonitorPayload) {
    yield call(StoreSettingCall, payload);
}

export function* storeSettingSaga({payload}: { payload: UserMonitorPayload }): Generator {
    const sagaPayload: ISkeletonSaga = {
        tryApiCall: {function: tryFunction, payload: payload},
        failedType: STORE_EXTRA_INITIALIZE_ERROR
    };
    yield call(skeletonSaga, sagaPayload);
}
