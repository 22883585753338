import {FC} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle} from "@fortawesome/free-solid-svg-icons/faCircle";
import {Dropdown, DropDownActions} from "./DropDown";
import {HEARTBEAT_PRODUCTS, Product} from "../../../constants/CommonConstants";
import {BookmakerData, BookmakerObject} from "../../../@types/response";
import {useSelector} from "react-redux";
import {getBookmakers} from "../../../store/selectors/getBookmakers";

export const HeartBeatDropDown: FC = () => {

    const bookmakers: BookmakerData = useSelector(getBookmakers);

    const isError = Object.values(bookmakers)
            .flatMap(i => Object.entries(i.products ?? {}))
            .filter(([_, alive]) => !alive)
            .length > 0;

    return <Dropdown color={isError ? "bg-toolbar-bgDropDownErr" : "bg-toolbar-bgDropDown"}>
        {Object.values(bookmakers)
                .sort((a, b) => (b.name > a.name ? -1 : 1))
                .filter((book) => book.id > 0)
                .map((bookmaker: BookmakerObject) => (
                        <DropDownActions
                                key={bookmaker.id}
                                label={bookmaker.name}
                                onClick={() => null}
                                isNetwork={true}
                        >
                            {HEARTBEAT_PRODUCTS.map((product: Product) => <FontAwesomeIcon
                                    key={`${bookmaker.id}-${product}`}
                                    icon={faCircle}
                                    className={
                                        bookmaker.products?.[product] == null
                                                ? "text-gray-500"
                                                : bookmaker.products?.[product]
                                                        ? "text-green-500"
                                                        : "text-red-500"
                                    }
                            />)}
                        </DropDownActions>
                ))}
    </Dropdown>
};
