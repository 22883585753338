import {FC, Fragment, useEffect, useState} from 'react';
import {PlayerMarketData, PlayerSettingsObject} from '../../../@types/response';
import {PLAYER_DETAILS_MARKETS, PLAYER_MARKETS, SPORT_NAME} from "../../../constants/CommonConstants";
import {DropDownItem} from "../common/DropDown";

interface DropDownMarketsProps {
    playerMarkets: PlayerMarketData
    settings: PlayerSettingsObject
    setSetting: (value: PlayerSettingsObject) => void
    setSportMarketOutcomes: (value: string[]) => void
    setSelectAllMarkets: (value: boolean) => void
    sportMarketOutcomes: string[]
    selectAllMarkets: boolean
}

export const DropDownMarkets: FC<DropDownMarketsProps> = ({
                                                              playerMarkets,
                                                              settings,
                                                              setSetting,
                                                              setSportMarketOutcomes,
                                                              selectAllMarkets,
                                                              setSelectAllMarkets,
                                                              sportMarketOutcomes
                                                          }) => {

    const [sportOpen, setSportOpen] = useState<string[]>([])
    const [marketOpen, setMarketOpen] = useState<string[]>([])
    const [hasSomeMarkets, setHasSomeMarkets] = useState<any>({})
    const [hasSomeOutcomes, setHasSomeOutcomes] = useState<any>({})

    useEffect(() => {
        const marketsSet = new Set(sportMarketOutcomes)
        const outcomesSet = new Set(settings.markets)
        const allOutcomes = Object.values(playerMarkets).flatMap(mkt => Object.keys(mkt.outcomes).map(outcome => `${mkt.id}#${outcome}`))
        const someMarkets: any = {}
        const someOutcomes: any = {}
        let marketsList: string [] = []
        if (selectAllMarkets !== allOutcomes.every(i => outcomesSet.has(i))) setSelectAllMarkets(allOutcomes.every(i => outcomesSet.has(i)))
        for (const s of Object.keys(PLAYER_DETAILS_MARKETS)) {
            someMarkets[s] = PLAYER_DETAILS_MARKETS[s].some(i => marketsSet.has(i)) && !PLAYER_DETAILS_MARKETS[s].every(i => marketsSet.has(i));
            for (const m of PLAYER_DETAILS_MARKETS[s]) {
                if (Object.keys(PLAYER_MARKETS[m].outcomes).every(i => outcomesSet.has(m + '#' + i))) {
                    marketsList.push(m)
                }
                someOutcomes[m] = Object.keys(PLAYER_MARKETS[m].outcomes).some(i => outcomesSet.has(m + '#' + i)) && !Object.keys(PLAYER_MARKETS[m].outcomes).every(i => outcomesSet.has(m + '#' + i));
            }
        }
        if (Object.values(hasSomeMarkets).toString() !== Object.values(someMarkets).toString()) setHasSomeMarkets(someMarkets)
        if (Object.values(hasSomeOutcomes).toString() !== Object.values(someOutcomes).toString()) setHasSomeOutcomes(someOutcomes)
        if (marketsList.sort().toString() !== sportMarketOutcomes.sort().toString()) setSportMarketOutcomes(marketsList)
    }, [sportMarketOutcomes, settings]);

    return <Fragment>
        <DropDownItem
                key={"all_markets"}
                label={"Select All"}
                selected={selectAllMarkets}
                onClick={() => {
                    const allOutcomes = Object.values(playerMarkets).flatMap(mkt => Object.keys(mkt.outcomes).map(outcome => `${mkt.id}#${outcome}`))
                    if (selectAllMarkets) {
                        setSetting(Object.assign({}, settings, {markets: []}))
                    } else {
                        setSetting(Object.assign({}, settings, {markets: allOutcomes}))
                    }
                }}
        />
        {Object.keys(PLAYER_DETAILS_MARKETS).map(s => {
            const open = sportOpen.includes(s)
            const allSelectedOutcomesMarkets = new Set(settings.markets.map(i => i.split('#')[0]))
            const marketsSet = new Set(sportMarketOutcomes)
            const selected = PLAYER_DETAILS_MARKETS[s].every(i => marketsSet.has(i))
            const hasOutcomes = PLAYER_DETAILS_MARKETS[s].some(i => allSelectedOutcomesMarkets.has(i))
            const sportOutcomes = Object.values(playerMarkets)
                    .filter(m => PLAYER_DETAILS_MARKETS[s].includes(m.id)).flatMap(mkt => Object.keys(mkt.outcomes).map(outcome => `${mkt.id}#${outcome}`))
            return <Fragment>
                <DropDownItem
                        key={SPORT_NAME[s]}
                        label={SPORT_NAME[s]}
                        selected={selected}
                        isTree={true}
                        half={hasSomeMarkets[s] ? hasSomeMarkets[s] : hasOutcomes ? hasOutcomes : false}
                        open={open}
                        onOpenClick={() => {
                            if (!open) {
                                setSportOpen(sportOpen.concat(s))
                            } else {
                                setSportOpen(sportOpen.filter(sport => sport !== s))
                            }
                        }}
                        onClick={() => {
                            if (!selected) {
                                setSetting(Object.assign({}, settings,
                                        {markets: settings.markets.concat(sportOutcomes)}))
                            } else {
                                setSetting(Object.assign({}, settings, {markets: settings.markets.filter(item => !sportOutcomes.includes(item))}))
                            }
                        }}
                />
                {open && Object.values(playerMarkets).filter(pm => PLAYER_DETAILS_MARKETS[s].includes(pm.id)).flatMap(m => {
                    const marktesOpen = marketOpen.includes(m.id)
                    const outcomesSet = new Set(settings.markets)
                    const marketSelected = Object.keys(PLAYER_MARKETS[m.id].outcomes).every(i => outcomesSet.has(m.id + '#' + i))
                    const marketOutcomes = Object.values(playerMarkets)
                            .filter(markt => markt.id === m.id).flatMap(mkt => Object.keys(mkt.outcomes).map(outcome => `${mkt.id}#${outcome}`))
                    return <Fragment>
                        <DropDownItem
                                key={m.id}
                                label={m.name}
                                selected={marketSelected}
                                isTree={true}
                                half={hasSomeOutcomes[m.id] || false}
                                open={marktesOpen}
                                onOpenClick={() => {
                                    if (!marktesOpen) {
                                        setMarketOpen(marketOpen.concat(m.id))
                                    } else {
                                        setMarketOpen(marketOpen.filter(market => market !== m.id))
                                    }
                                }}
                                onClick={() => {
                                    if (!marketSelected) {
                                        setSetting(Object.assign({}, settings,
                                                {markets: settings.markets.concat(marketOutcomes)}))
                                    } else {
                                        setSetting(Object.assign({}, settings, {markets: settings.markets.filter(item => !marketOutcomes.includes(item))}))
                                    }
                                }}
                                level={1}
                        />{
                            marktesOpen && Object.entries(m.outcomes).map((outcome) => {
                                const visible = settings.markets.includes(m.id + '#' + outcome[0])
                                return <DropDownItem
                                        key={m.id + '#' + outcome[0]}
                                        label={outcome[1].name}
                                        selected={visible}
                                        onClick={() => {
                                            if (!visible) {
                                                setSetting(Object.assign({}, settings, {markets: [...settings.markets, m.id + '#' + outcome[0]]}))
                                            } else {
                                                setSetting(Object.assign({}, settings, {markets: settings.markets.filter((item) => item !== m.id + '#' + outcome[0])}))
                                            }
                                        }}
                                        level={2}
                                />
                            })
                    }</Fragment>
                })}
            </Fragment>
        })
        }
    </Fragment>
}

