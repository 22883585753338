import {faSortDown} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {DoClassicSettingsObject, DroppingOddObject, MarketCapObject, MoneyLineObject} from '../../../@types/response';
import {useDispatch, useSelector} from 'react-redux';
import {OrderConstants} from '../../../constants/OddsConstants';
import DoClassicItem from './DoClassicItem';
import {useEffect, useState} from 'react';
import {filterDroppingOdds} from '../../../store/utils/droppingOddsUtils';
import {getMarketCap} from '../../../store/selectors/getMarketCap';
import {getEventsMoneyLine} from '../../../store/selectors/getEventsMoneyLine';
import {getClassicSettings} from '../../../store/selectors/dropping/getClassicSettings';
import {getClassicBookmaker} from '../../../store/selectors/dropping/getClassicBookmaker';
import {SetClassicSettings} from '../../../store/actions/dropping/SetClassicSettings';
import {getClassicAlerts} from '../../../store/selectors/dropping/getClassicAlerts';
import {DroppingOddsClassic} from '../../../@types/inputs';

export default function DroppingOddsTable() {

    const settings: DoClassicSettingsObject = useSelector(getClassicSettings)
    const bookmaker: number = useSelector(getClassicBookmaker)
    const classicAlerts: DroppingOddsClassic = useSelector(getClassicAlerts);
    const marketCaps: MarketCapObject = useSelector(getMarketCap);
    const marketMoneyLines: MoneyLineObject = useSelector(getEventsMoneyLine);
    const [droppingOdds, setDroppingOdds] = useState<DroppingOddObject[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        const _valid = filterDroppingOdds(classicAlerts[bookmaker], settings, bookmaker, marketCaps, marketMoneyLines);
        setDroppingOdds(_valid);
    }, [classicAlerts, settings, bookmaker, marketCaps, marketMoneyLines]);

    return <div className='xl:monitor-table-header-wrapper'>
        <table className='text-table-primary w-full '>
            <colgroup>
                {/*event_name*/}
                <col className='w-auto'/>
                {/*search*/}
                <col className='w-8'/>
                {/*date*/}
                <col className='w-[10%]'/>
                {/*margin*/}
                <col className='w-16'/>
                {/*market*/}
                <col className='w-[10%]'/>
                {/*sign*/}
                <col className='w-[10%]'/>
                {/*odd_now*/}
                <col className='w-[10%]'/>
                {/*odd_opening*/}
                <col className='w-[10%]'/>
                {/*betfair cap*/}
                <col className='w-[10%]'/>
            </colgroup>
            <thead>
            <tr className='uppercase xl:text-monitorBase text-monitorSm tracking-[-0.11px] items-center leading-normal'>
                <th
                        className='py-1 pl-20 cursor-pointer relative text-start'
                        onClick={() => {
                            const _settings = Object.assign({}, settings, {order: OrderConstants.NONE})
                            settings.order !== OrderConstants.NONE && dispatch(SetClassicSettings(_settings))
                        }}
                >
                    EVENT/COMPETITION
                    {settings.order === OrderConstants.NONE &&
                            <div className='absolute right-[46%] md:pr-2 lg:pr-21 top-0'>
                                <FontAwesomeIcon icon={faSortDown}/>
                            </div>}
                </th>
                <th></th>
                <th
                        className='py-1 text-center cursor-pointer relative'
                        onClick={() => {
                            const _settings = Object.assign({}, settings, {order: OrderConstants.DATE})
                            settings.order !== OrderConstants.DATE && dispatch(SetClassicSettings(_settings))
                        }}
                >
                    Date
                    {settings.order === OrderConstants.DATE && <div className='absolute  right-[16%] top-0'>
                        <FontAwesomeIcon icon={faSortDown}/>
                    </div>}
                </th>
                <th
                        className='py-1 text-center cursor-pointer relative'
                        onClick={() => {
                            const _settings = Object.assign({}, settings, {order: OrderConstants.MARGIN})
                            settings.order !== OrderConstants.MARGIN && dispatch(SetClassicSettings(_settings))
                        }}
                >
                    M
                    {settings.order === OrderConstants.MARGIN && <div className='absolute right-1/2 top-0'>
                        <FontAwesomeIcon icon={faSortDown}/>
                    </div>}
                </th>
                <th className='py-1 text-center cursor-pointer relative'>Market</th>
                <th className='py-1 text-center cursor-pointer relative'>Sign</th>
                <th className='py-1 text-center'>Now</th>
                <th className='py-1 text-center'>{settings.reference === 0 ? 'OPENING' : settings.reference}</th>
                <th className='text-start'>Total</th>
            </tr>
            </thead>
        </table>

        <div
                style={{
                    background: `url('${process.env.PUBLIC_URL}/static/img/table_background.jpg')`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}
                className='xl:monitor-table-wrapper border-2 border-table-primary bg-table-monitor monitor-table-wrapper-1440 overflow-auto dropdown-hidden-scrollbar relative rounded-[40px] mb-4'
        >
            {/* <table className='min-w-max w-3/5 table-auto text-monitor-secondary border-2 border-black'> */}
            <table className='w-full table-auto text-monitor-secondary border-2 border-black'>
                <colgroup>
                    {/*event_name*/}
                    <col className='w-auto'/>
                    {/*search*/}
                    <col className='w-8'/>
                    {/*date*/}
                    <col className='w-[10%]'/>
                    {/*margin*/}
                    <col className='w-16'/>
                    {/*market*/}
                    <col className='w-[10%]'/>
                    {/*sign*/}
                    <col className='w-[10%]'/>
                    {/*odd_now*/}
                    <col className='w-[10%]'/>
                    {/*odd_opening*/}
                    <col className='w-[10%]'/>
                    {/*betfair cap*/}
                    <col className='w-[10%]'/>
                </colgroup>
                <tbody className='xl:text-monitorBase text-monitorSm font-normal'>
                {droppingOdds.map((data: any) => <DoClassicItem
                        key={`${data.id}#${data.marketId}#${data.signId}`}
                        data={data}
                        reference={settings.reference}
                />)}
                </tbody>
            </table>
        </div>
    </div>
}
