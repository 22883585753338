import {useEffect, useState} from "react";
import DoInstantItem from "./DoInstantItem";
import {DropListFilters, DropListProps, InstantDropObject} from "../../../@types/formContext";
import DoInstantHead from "./DoInstantHead";
import {GetSportsAction} from "../../../store/actions/GetSportsAction";
import {useDispatch, useSelector} from "react-redux";
import {filterInstantDroppingOdds} from "../../../store/utils/droppingOddsUtils";
import {getLastChanges} from "../../../store/selectors/dropping/getLastChanges";
import {InstantDropLists} from "../../../@types/inputs";

const DoInstant: React.FC<DropListProps> = ({
                                                defaultSportId,
                                                defaultBookmakers,
                                                defaultThreshold,
                                                beep,
                                                date = 0,
                                                from = 1,
                                                to = 1,
                                            }) => {

    const validItems: InstantDropLists = useSelector(getLastChanges)

    const [validItemsFiltered1, setValidItemsFiltered1] = useState<InstantDropObject[]>([]);
    const [validItemsFiltered2, setValidItemsFiltered2] = useState<InstantDropObject[]>([]);
    const [validItemsFiltered3, setValidItemsFiltered3] = useState<InstantDropObject[]>([]);

    const [search1, setSearch1] = useState<string>('');
    const [search2, setSearch2] = useState<string>('');
    const [search3, setSearch3] = useState<string>('');

    const [filterString1, setFilterString1] = useState<string>('');
    const [filterString2, setFilterString2] = useState<string>('');
    const [filterString3, setFilterString3] = useState<string>('');

    const [itemsString1, setItemsString1] = useState<string>('');
    const [itemsString2, setItemsString2] = useState<string>('');
    const [itemsString3, setItemsString3] = useState<string>('');

    const [filters1, setFilters1] = useState<DropListFilters>({
        from: 0,
        hide: [],
        sport: {[defaultSportId]: true},
        bookmakers: defaultBookmakers,
        threshold: defaultThreshold
    })
    const [filters2, setFilters2] = useState<DropListFilters>({
        from: 0,
        hide: [],
        sport: {[defaultSportId]: true},
        bookmakers: defaultBookmakers,
        threshold: defaultThreshold
    });
    const [filters3, setFilters3] = useState<DropListFilters>({
        from: 0,
        hide: [],
        sport: {[defaultSportId]: true},
        bookmakers: defaultBookmakers,
        threshold: defaultThreshold
    });

    const [enableBeep, setEnableBeep] = useState<boolean>(true);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetSportsAction());
    }, []);

    useEffect(() => {
        let _valid = filterInstantDroppingOdds(
                validItems || {},
                from,
                to,
                date,
                search1,
                filters1
        );
        setValidItemsFiltered1(_valid);
    }, [validItems, from, to, search1, date, filters1]);

    useEffect(() => {
        let _valid2 = filterInstantDroppingOdds(
                validItems || {},
                from,
                to,
                date,
                search2,
                filters2
        );
        setValidItemsFiltered2(_valid2);
    }, [validItems, from, to, search2, date, filters2]);

    useEffect(() => {
        let _valid3 = filterInstantDroppingOdds(
                validItems || {},
                from,
                to,
                date,
                search3,
                filters3
        );
        setValidItemsFiltered3(_valid3);
    }, [validItems, from, to, search3, date, filters3]);

    useEffect(() => {
        if (!beep) return;
        if ((JSON.stringify(validItemsFiltered1) !== itemsString1 && JSON.stringify({
                    filters: filters1,
                    search: search1,
                    from: from,
                    to: to,
                    date: date
                }) === filterString1)
                || (JSON.stringify(validItemsFiltered2) !== itemsString2 && JSON.stringify({
                    filters: filters2,
                    search: search2,
                    from: from,
                    to: to,
                    date: date
                }) === filterString2)
                || (JSON.stringify(itemsString3) !== itemsString3 && JSON.stringify({
                    filters: filters3,
                    search: search3,
                    from: from,
                    to: to,
                    date: date
                }) === filterString3)
        ) {
            setEnableBeep(true)
        } else {
            setEnableBeep(false)
        }
        setItemsString1(JSON.stringify(validItemsFiltered1))
        setItemsString2(JSON.stringify(validItemsFiltered2))
        setItemsString3(JSON.stringify(validItemsFiltered3))
        setFilterString1(JSON.stringify({filters: filters1, search: search1, from: from, to: to, date: date}))
        setFilterString2(JSON.stringify({filters: filters2, search: search2, from: from, to: to, date: date}))
        setFilterString3(JSON.stringify({filters: filters3, search: search3, from: from, to: to, date: date}))
    }, [validItemsFiltered1, validItemsFiltered2, validItemsFiltered3]);

    return (
            <div className="w-full mx-2 overflow-y-hidden">
                <div id="toolbar-monitor">
                    <div className="flex justify-around mx-4">
                        <DoInstantHead
                                id={'l1'}
                                sport={filters1.sport}
                                bookmakers={filters1.bookmakers}
                                threshold={filters1.threshold}
                                setFilter={(newFilters) => setFilters1(Object.assign({}, filters1, newFilters))}
                                search={search1}
                                setSearch={(searchValue) => setSearch1((searchValue))}
                        />
                        <DoInstantHead
                                id={'l2'}
                                sport={filters2.sport}
                                bookmakers={filters2.bookmakers}
                                threshold={filters2.threshold}
                                setFilter={(newFilters) => setFilters2(Object.assign({}, filters2, newFilters))}
                                search={search2}
                                setSearch={(searchValue) => setSearch2((searchValue))}
                        />
                        <DoInstantHead
                                id={'l3'}
                                sport={filters3.sport}
                                bookmakers={filters3.bookmakers}
                                threshold={filters3.threshold}
                                setFilter={(newFilters) => setFilters3(Object.assign({}, filters3, newFilters))}
                                search={search3}
                                setSearch={(searchValue) => setSearch3((searchValue))}
                        />
                    </div>
                    <div
                            style={{
                                background: `url('${process.env.PUBLIC_URL}/static/img/table_background.jpg')`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                            }}
                            className="xl:monitor-table-wrapper border-2 border-table-primary bg-table-monitor
                    monitor-table-wrapper-1440 overflow-auto dropdown-hidden-scrollbar relative rounded-[40px]
                    mb-4 pt-5 mx-2"
                    >
                        <div className="flex justify-evenly">
                            <div className="w-80 flex flex-col mx-auto 2xl:text-instantDropBase text-instantDropSm ">
                                {validItemsFiltered1.map((item) =>
                                        <DoInstantItem
                                                key={`1#${item.tm}`}
                                                handleSingleDelete={(uid: string) => setFilters1(
                                                        Object.assign({}, filters1, {hide: [uid, ...filters1.hide]})
                                                )}
                                                item={item}
                                                beep={enableBeep && (beep || false)}
                                        />
                                )}
                            </div>
                            <div className="w-80 flex flex-col mx-auto 2xl:text-instantDropBase text-instantDropSm">
                                {validItemsFiltered2.map((item) =>
                                        <DoInstantItem
                                                key={`2#${item.tm}`}
                                                handleSingleDelete={(uid: string) => setFilters2(
                                                        Object.assign({}, filters2, {hide: [uid, ...filters2.hide]})
                                                )}
                                                item={item}
                                                beep={enableBeep && (beep || false)}
                                        />
                                )}
                            </div>
                            <div className="w-80 flex flex-col mx-auto 2xl:text-instantDropBase text-instantDropSm">
                                {validItemsFiltered3.map((item) =>
                                        <DoInstantItem
                                                key={`3#${item.tm}`}
                                                handleSingleDelete={(uid: string) => setFilters3(
                                                        Object.assign({}, filters3, {hide: [uid, ...filters3.hide]})
                                                )}
                                                item={item}
                                                beep={enableBeep && (beep || false)}
                                        />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default DoInstant;
