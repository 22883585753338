import {call, put} from 'redux-saga/effects';
import {ISkeletonSaga, skeletonSaga} from '../../apis/skeleton/skeletonSaga';
import {BookPayload} from '../../@types/actions';
import {BOOK_EVENT_ERROR, BOOK_EVENT_SUCCESS} from "../../constants/MonitorConstants";
import {BookEventCall} from "../../apis/user";

function* tryFunction(payload: BookPayload) {
    yield call(BookEventCall, payload);
    yield put({
        type: BOOK_EVENT_SUCCESS,
        payload: `${payload.eventId}-${payload.marketId}-${payload.signId}`
    });
}

export function* bookEventSaga({payload}: { payload: BookPayload }): Generator {
    const sagaPayload: ISkeletonSaga = {
        tryApiCall: {function: tryFunction, payload: payload},
        failedType: BOOK_EVENT_ERROR
    };
    yield call(skeletonSaga, sagaPayload);
}
