import {FC, useRef} from "react";

interface TooltipProps {
    children: any
    tooltipText: string
    isEvent: boolean
}

const Tooltip: FC<TooltipProps> = ({children, tooltipText, isEvent}) => {

    const tipRef = useRef<HTMLDivElement>(null);

    const handleMouseEnter = () => {
        if (tipRef.current) {
            tipRef.current.style.display = 'block';
            tipRef.current.style.marginLeft = "20px";
        }
    }

    function handleMouseLeave() {
        if (tipRef.current) {
            tipRef.current.style.display = 'none';
            tipRef.current.style.marginLeft = "0px";
        }
    }

    const event = isEvent ? 'w-full z-50 left-3/4' : 'left-full'
    return (
            <div
                    className="relative flex items-center"
            >
                <div
                        className={"absolute whitespace-no-wrap bg-gradient-to-r from-black to-gray-700 text-white px-4 py-2 rounded flex items-center transition-none " + event}
                        style={{display: 'none'}}
                        ref={tipRef}
                >
                    <div
                            className="bg-black h-3 w-3 absolute"
                            style={{left: "-6px", transform: "rotate(45deg)"}}
                    />
                    {tooltipText}
                </div>
                <span
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                >{children}</span>
            </div>
    );
}

export default Tooltip
