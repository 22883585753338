import SignChartModal from "../modals/SignChartModal";
import BookEventModal from "../modals/BookEventModal";
import EventDetailsModal from "../modals/EventDetailsModal";
import {useEffect, useState} from "react";
import {GetCurrentOddsAction} from "../../../store/actions/dropping/GetCurrentOdds";
import {useDispatch, useSelector} from "react-redux";
import DroppingOddsTable from "./DoClassicTable";
import {CLASSIC_DROPPING_TIMEOUT_SEC,} from "../../../constants/OddsConstants";
import DoClassicToolBar from "./DoClassicToolBar";
import {DoClassicSettingsObject} from "../../../@types/response";
import {getClassicSettings} from "../../../store/selectors/dropping/getClassicSettings";
import {getClassicBookmaker} from "../../../store/selectors/dropping/getClassicBookmaker";

const DoClassic: React.FC = () => {

    const dispatch = useDispatch();
    const settings: DoClassicSettingsObject = useSelector(getClassicSettings)
    const bookmaker: number = useSelector(getClassicBookmaker)

    const [timeoutValue, setTimoutValue]: any = useState(CLASSIC_DROPPING_TIMEOUT_SEC);

    useEffect(() => {
        dispatch(GetCurrentOddsAction(bookmaker));
        const interval = setInterval(() => {
            setTimoutValue((timeoutValue: number) => timeoutValue - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (timeoutValue === 0) {
            dispatch(GetCurrentOddsAction(bookmaker));
            setTimoutValue(CLASSIC_DROPPING_TIMEOUT_SEC);
        }
    }, [settings, timeoutValue]);

    return (
            <div>
                <div
                        id="toolbar-monitor"
                        className="bg-toolbar-bgMain border-b border-toolbar-borderBottom fixed top-0 right-0 flex items-end justify-around w-full z-10"
                >
                    <DoClassicToolBar
                            setTimoutValue={() => {
                                setTimoutValue(CLASSIC_DROPPING_TIMEOUT_SEC);
                            }}
                            timeoutValue={timeoutValue}
                    />
                </div>
                <DroppingOddsTable/>
                <SignChartModal/>
                <BookEventModal/>
                <EventDetailsModal/>
            </div>
    );
};

export default DoClassic;
