import React, {RefObject, useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {StoreSettingAction} from "../../../store/actions/monitor/StoreSettingAction";
import {GetSettingsAction} from "../../../store/actions/monitor/GetSettingsAction";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faSave, faTrash} from "@fortawesome/free-solid-svg-icons";
import {DeleteSettingsAction} from "../../../store/actions/monitor/DeleteSettingsAction";
import {DropDownActions} from "../common/DropDown";
import {NewTemplateProps, TemplateButtonProps} from "../../../@types/inputs";

const TemplateModal: React.FC<NewTemplateProps> = ({templates, onConfirm}) => {

    const [addTemplate, setAddTemplate] = useState(false);
    const [existing, setExisting] = useState(false);
    const [templateName, setTemplateName] = useState('');

    useEffect(() => {
        setExisting(templates.includes(templateName))
    }, [templateName])

    return <div className='fixed z-10 inset-0 overflow-y-auto' aria-labelledby='modal-title' role='dialog'
                aria-modal='true'>
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
            <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' aria-hidden='true'/>
            <div
                    className={'inline-block align-bottom bg-white rounded-lg text-left shadow-xl ' +
                            'transform transition-all sm:my-8 sm:align-middle sm:max-w-screen-sm sm:w-full'}>
                <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
                    <h3 className='text-lg leading-6 font-medium text-gray-900 relative' id='modal-title'>
                        New Template
                    </h3>
                    <div className='mt-4'>
                        <input
                                type='text'
                                autoFocus
                                className={'bg-gray-50 appearance-none border-2 rounded w-full py-2 px-4 ' +
                                        'text-gray-700 leading-tight border-gray-200'}
                                value={templateName}
                                onChange={(event) => setTemplateName(event.target.value.toUpperCase())}
                        />
                        <small
                                className='block my-2 text-red-600'>{existing ? 'Template name already exist' : '\u00A0'}</small>
                    </div>
                </div>
                <div className='bg-gray-50 px-4 py-3 sm:px-6 flex flex-row-reverse'>
                    <button
                            type='button'
                            className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300
                                shadow-sm px-4 py-2 text-base font-medium bg-gray-50 text-gray-700 hover:bg-gray-100 sm:mt-0
                                sm:ml-3 sm:w-auto sm:text-sm'
                            onClick={() => {
                                if (existing || templateName === '') {
                                    alert("Template name not valid")
                                } else {
                                    onConfirm(templateName)
                                }
                            }}
                    >
                        OK
                    </button>
                    {addTemplate && <button
                            type='button'
                            className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300
                                shadow-sm px-4 py-2 text-base font-medium bg-gray-50 text-gray-700 hover:bg-gray-100 sm:mt-0
                                sm:ml-3 sm:w-auto sm:text-sm'
                            onClick={() => setAddTemplate(false)}
                    >
                        Back
                    </button>}
                </div>
            </div>
        </div>
    </div>
};

const useWindowEventListener = (event: any, callback: any) => {
    useEffect(() => {
        window.addEventListener(event, callback)
        return () => window.removeEventListener(event, callback)
    }, [event, callback])
}

const TemplateButton: React.FC<TemplateButtonProps> = ({settings, template, templates}) => {

    const [showModal, setShowModal] = useState(false);

    const [dropdownShow, setDropdownShow] = React.useState(false);
    const btnDropdownRef: RefObject<HTMLButtonElement> = React.createRef()
    const popoverDropdownRef: RefObject<HTMLDivElement> = React.createRef()

    useWindowEventListener("mousedown", (event: { target: any }) => {
        if (btnDropdownRef.current && btnDropdownRef.current.contains(event.target)) {
            return
        } else if (popoverDropdownRef.current && !popoverDropdownRef.current.contains(event.target)) {
            setDropdownShow(false)
        }
    })

    const dispatch = useDispatch();

    useEffect(() => {
        document.addEventListener('keydown', escFunction, false);

        return () => {
            document.removeEventListener('keydown', escFunction, false);
        };
    }, []);


    const escFunction = useCallback((_event: KeyboardEvent) => {
        if (_event.key === 'Escape') {
            setShowModal(false);
        }
    }, []);

    return <>
        <div className="flex flex-wrap">
            <div className="relative inline-flex align-middle w-full">
                <button
                        className="tpeer h-10 w-full bg-toolbar-bgMain text-white p-2 text-center"
                        type="button"
                        ref={btnDropdownRef}
                        onClick={() => setDropdownShow(!dropdownShow)}
                        style={{width: '8rem'}}
                >
                    {template ?? "Seleziona template"}
                </button>
                <div
                        // ref={popoverDropdownRef}
                        className={
                                (dropdownShow ? "absolute " : "hidden ") +
                                "top-full text-base z-50 bg-dropDown-bgMain py-2 list-none text-left rounded shadow-lg"
                        }
                        style={{minWidth: "12rem"}}
                        ref={popoverDropdownRef}
                >
                    {templates.map((name) => {
                        return <DropDownActions
                                key={name}
                                label={name}
                                onClick={() => {
                                    dispatch(GetSettingsAction(name))
                                    setDropdownShow(false)
                                }}
                                isNetwork={false}
                        >
                            <FontAwesomeIcon
                                    icon={faSave}
                                    className='mx-2 cursor-pointer hover:text-green-800'
                                    onClick={() => {
                                        dispatch(StoreSettingAction(Object.assign({}, settings, {name})))
                                        setDropdownShow(false)
                                    }}
                            />
                            <FontAwesomeIcon
                                    icon={faTrash}
                                    className='mx-2 cursor-pointer hover:text-red-600'
                                    onClick={() => {
                                        if (!confirm('Are you sure you want to delete [' + name + '] template?'))//eslint-disable-line
                                        {
                                            return
                                        }
                                        dispatch((DeleteSettingsAction(name)))
                                        setDropdownShow(false)
                                    }}
                            />
                        </DropDownActions>
                    })}
                    <DropDownActions
                            label='Add template'
                            onClick={() => {
                                setDropdownShow(false)
                                setShowModal(true)
                            }}
                            isNetwork={false}
                    >
                        <FontAwesomeIcon
                                icon={faPlus}
                                className='mx-2 cursor-pointer'
                        />
                    </DropDownActions>
                </div>
            </div>
        </div>
        {(showModal && <TemplateModal
                templates={templates}
                onConfirm={(name: string) => {
                    dispatch(StoreSettingAction(Object.assign({}, settings, {name})))
                    setShowModal(false)
                }}
        />) || null}
    </>
};

export default TemplateButton;

