import React, {useCallback, useContext, useEffect, useState} from 'react';
import {ModalsContext} from './ModalsContext';
import {MODALS_TYPES} from '../../../constants/ModalsConstants';
import {HideAlertAction} from "../../../store/actions/player/HideAlertAction";
import {useDispatch} from "react-redux";
import {AlertPlayerObject} from "../../../@types/response";

const HidePlayerModal: React.FC = () => {
    const {modalsData, openedModals, setOpenedModalsCallback} = useContext(ModalsContext);
    const alert: AlertPlayerObject = (modalsData[MODALS_TYPES.HIDE_PLAYER_MODAL] || {}).alert;

    const [value, setValue] = useState<number>(1);

    const dispatch = useDispatch();

    const onConfirmClickCb = useCallback((e: React.SyntheticEvent) => {
        e.preventDefault()
        if (isNaN(value)) {
            return
        }
        dispatch(HideAlertAction({
            eventId: alert.eventId,
            marketId: alert.marketId,
            signId: alert.signId,
            sbv: alert.sbv,
            playerId: alert.playerId,
            margin: alert.margin - value
        }))
        setOpenedModalsCallback(MODALS_TYPES.HIDE_PLAYER_MODAL, false);
    }, [alert, value, setOpenedModalsCallback]);

    const onCancelClickCb = useCallback(() => {
        setOpenedModalsCallback(MODALS_TYPES.HIDE_PLAYER_MODAL, false);
    }, [setOpenedModalsCallback]);


    const escFunction = useCallback((event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            setOpenedModalsCallback(MODALS_TYPES.HIDE_PLAYER_MODAL, false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, []);

    return (
            (openedModals.indexOf(MODALS_TYPES.HIDE_PLAYER_MODAL) > -1 && (
                    <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog"
                         aria-modal="true">
                        <form
                                className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
                                onSubmit={onConfirmClickCb}
                        >
                            <div className="fixed inset-0 bg-modal-layer transition-opacity"
                                 aria-hidden="true"/>

                            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"
                                  aria-hidden="true">&#8203;</span>

                            <div
                                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                            <h3 className="text-lg leading-6 font-medium text-gray-900"
                                                id="modal-title">
                                                Hide alert in monitor
                                            </h3>
                                            <div className="mt-2 flex">
                                                <label className='py-2 mr-4'
                                                       htmlFor='margin-value'>
                                                    Margin
                                                </label>
                                                <input
                                                        type='text'
                                                        id='margin-value'
                                                        autoFocus
                                                        className='bg-gray-50 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500'
                                                        defaultValue={1}
                                                        onChange={(e) => setValue(parseInt(e.target.value))}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <button
                                            type='submit'
                                            className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-success text-base font-medium text-gray-50 hover:bg-green-600 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                                    >
                                        Confirm
                                    </button>
                                    <button
                                            type='button'
                                            className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-100 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                                            onClick={onCancelClickCb}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
            )) ||
            null
    );
};
export default HidePlayerModal;
