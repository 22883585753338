import {call, put} from 'redux-saga/effects';
import {ISkeletonSaga, skeletonSaga} from '../../apis/skeleton/skeletonSaga';
import {BlacklistPlayerCall} from '../../apis/player';
import {GENERIC_ERROR} from '../../constants/ErrorConstants';
import {BlacklistPlayerProps} from '../../@types/actions';
import {GET_EVENT} from '../../constants/PlayerConstants';

function* tryFunction(payload: BlacklistPlayerProps) {
    yield call(BlacklistPlayerCall, payload.eventId, payload.playerId, payload.bookmakerId);
    yield put({
        type: GET_EVENT,
        payload: {eventId: payload.eventId}
    });
}

export function* addToBlacklistSaga({payload}: { payload: BlacklistPlayerProps }): Generator {
    const sagaPayload: ISkeletonSaga = {
        tryApiCall: {function: tryFunction, payload: payload},
        failedType: GENERIC_ERROR
    };
    yield call(skeletonSaga, sagaPayload);
}
