import React, {useCallback, useContext, useEffect, useState} from 'react';
import {ModalsContext} from './ModalsContext';
import {MODALS_TYPES} from '../../../constants/ModalsConstants';
import {EventOddObject, EventStoredObject, MarketData, SignOddsObject} from '../../../@types/response';
import {GetOddHistoryCall} from '../../../apis/event';
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {useSelector} from 'react-redux';
import {getMarkets} from '../../../store/selectors/getMarkets';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock} from "@fortawesome/free-solid-svg-icons";

type ChartProps = {
    xMin: number, xMax: number, xTicks: number[],
    minOdd: number, maxOdd: number, yTicks: number[]
}

const SignChartModal: React.FC = () => {

    const {modalsData, openedModals, setOpenedModalsCallback} = useContext(ModalsContext);

    const markets: MarketData = useSelector(getMarkets);
    const [showChart, setShowChart] = useState<boolean>(true);

    const odd: EventOddObject = (modalsData[MODALS_TYPES.SIGN_CHART_MODAL] || {}).odd;
    const event: EventStoredObject = (modalsData[MODALS_TYPES.SIGN_CHART_MODAL] || {}).event;
    const [original, setOriginal] = useState<SignOddsObject[][]>([]);
    const [history, setHistory] = useState<SignOddsObject[]>([]);
    const [chart, setChart] = useState<ChartProps>({xMin: 0, xMax: 1, xTicks: [], minOdd: 0, maxOdd: 1, yTicks: []});

    useEffect(() => {
        document.addEventListener('keydown', escFunction, false);
        return () => {
            document.removeEventListener('keydown', escFunction, false);
        };

    }, []);

    useEffect(() => {
        odd && GetOddHistoryCall(odd).then((result: SignOddsObject[]) => {
            let charts: SignOddsObject[] = [], lists: SignOddsObject[][] = [[], [], []], backOdd: number = 0
            const itemsInList = Math.ceil(result.length / lists.length)
            result
                    .filter((item) => item.backOdd > 1 || !item['backOdd'])
                    .forEach((item, idx) => {
                        if (idx > 0) {
                            charts.push({
                                backOdd: backOdd,
                                updt: item['updt'] - 1
                            })
                        }
                        charts.push(item)
                        backOdd = item['backOdd']
                        const pos = Math.floor(idx / itemsInList)
                        lists[pos].push(item)
                    })
            charts.push({
                backOdd: backOdd,
                updt: new Date().getTime()
            })
            setHistory(charts)
            setOriginal(lists)
        })
    }, [odd])
    const onCancelClickCb = useCallback(() => {
        setOpenedModalsCallback(MODALS_TYPES.SIGN_CHART_MODAL, false);
    }, [setOpenedModalsCallback]);

    const escFunction = useCallback((_event: KeyboardEvent) => {
        if (_event.key === 'Escape') {
            setOpenedModalsCallback(MODALS_TYPES.SIGN_CHART_MODAL, false);
        }
    }, []);

    useEffect(() => {
        let xMin: number = 0, xMax: number = 1, xTicks: number[] = [],
                minOdd: number = 0, maxOdd: number = 0, yTicks: number[] = [],
                step: number, hours: number;
        if (!history.length) {
            setChart({xMin, xMax, xTicks, minOdd, maxOdd, yTicks})
            return
        }

        const values = history.map(item => item['backOdd'])
        minOdd = Math.min(...values)
        maxOdd = Math.max(...values)

        const minDate = new Date(history[0]['updt'])
        minDate.setMinutes(0)
        minDate.setSeconds(0)
        xMin = minDate.getTime()

        const maxDate = new Date(history[history.length - 1]['updt'])
        xMax = maxDate.getTime()
        hours = (xMax - xMin) / (60 * 60 * 1000) // hours in range
        let size;
        if (hours > 168) {                      //1w
            size = 1000 * 60 * 60 * 24          //1d
        } else if (hours > 72) {                //3d
            size = 1000 * 60 * 60 * 8           // 8h
        } else if (hours > 24) {                //1d
            size = 1000 * 60 * 60               //1h
        } else {
            size = 1000 * 60 * 30               //30m
        }

        step = (xMax - xMin) / size + 1
        for (let i = 0; i < step; i++) {
            xTicks.push(xMin + i * size)
        }

        step = (maxOdd - minOdd) * 2 + 1
        const start = Math.floor(minOdd)
        for (let i = 0; i < step; i++) {
            yTicks.push(start + i * 0.5)
        }

        setChart({xMin, xMax, xTicks, minOdd, maxOdd, yTicks})
    }, [history])

    return (
            (openedModals.indexOf(MODALS_TYPES.SIGN_CHART_MODAL) > -1 && (
                    <div className='fixed z-10 inset-0 overflow-y-auto' aria-labelledby='modal-title' role='dialog'
                         aria-modal='true'>
                        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
                            <div className='fixed inset-0 bg-modal-layer transition-opacity'
                                 aria-hidden='true'/>

                            <span className='hidden sm:inline-block sm:align-middle sm:h-screen'
                                  aria-hidden='true'>&#8203;</span>
                            <div
                                    className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full'>
                                <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
                                    <div>{event.name}</div>
                                    <div className='flex'>
                                        <div className='w-1/3'>
                                            <label className='mr-2'>Market</label>
                                            <span>{markets[odd.marketId].name}</span>
                                        </div>
                                        <div className='w-1/3'>
                                            <label className='mr-2'>Sign</label>
                                            <span>{markets[odd.marketId].outcomes[odd.signId].name}</span>
                                        </div>
                                        <div className='w-1/3'>
                                            <label className='mr-2'>Odd</label>
                                            <span>{history.length && history[history.length - 1].backOdd}</span>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                        <div className='w-1/3'>
                                            <label className='mr-2'>Opening</label>
                                            <span>{history.length && history[0].backOdd}</span>
                                        </div>
                                        <div className='w-1/3'>
                                            <label className='mr-2'>Height</label>
                                            <span>{chart.maxOdd}</span>
                                        </div>
                                        <div className='w-1/3'>
                                            <label className='mr-2'>Low</label>
                                            <span>{chart.minOdd}</span>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                        <div className='mt-3 text-center'>
                                            <div style={{height: '400px', width: '45rem'}}>
                                                <div className='cursor-pointer'
                                                     onClick={() => setShowChart(!showChart)}
                                                >X
                                                </div>
                                                {showChart ?
                                                        <ResponsiveContainer>
                                                            <LineChart data={history}>
                                                                <CartesianGrid
                                                                        strokeDasharray="3"
                                                                />
                                                                <XAxis
                                                                        dataKey='updt'
                                                                        type='number'
                                                                        domain={[chart.xMin, chart.xMax]}
                                                                        tickFormatter={(updt) => {
                                                                            const _updt = new Date(updt)
                                                                            return _updt.getDate().toString().padStart(2, '0') + '/' + (_updt.getMonth() + 1).toString().padStart(2, '0') +
                                                                                    ' ' + _updt.getHours().toString().padStart(2, '0') + ':' + _updt.getMinutes().toString().padStart(2, '0')
                                                                        }}
                                                                        ticks={chart.xTicks}
                                                                />
                                                                <YAxis
                                                                        type='number'
                                                                        interval={0}
                                                                        ticks={chart.yTicks}
                                                                        domain={['dataMin-0.25', 'dataMax+0.25']}
                                                                />
                                                                <Tooltip
                                                                        labelFormatter={(payload) => new Date(payload).toLocaleTimeString()}
                                                                />
                                                                <Line type='linear' dataKey='backOdd' stroke='#8884d8'
                                                                      dot={false}/>
                                                            </LineChart>
                                                        </ResponsiveContainer>
                                                        :
                                                        <div className='flex text-left h-400px'>
                                                            {original.map((items) => {
                                                                return <div className='flex-col w-1/3 pr2'>
                                                                    {items.map((item) => {
                                                                        const updt = new Date(item.updt)
                                                                        if (item.backOdd <= 1) return null
                                                                        return <div className='flex pr-2'>
                                                                            <div className='w-1/5'>
                                                                                <FontAwesomeIcon icon={faClock}/>
                                                                            </div>
                                                                            <div className='w-2/5'>
                                                                                {updt.getDate().toString().padStart(2, '0')}/{(updt.getMonth() + 1).toString().padStart(2, '0')}&nbsp;
                                                                                {updt.getHours().toString().padStart(2, '0')}:{updt.getMinutes().toString().padStart(2, '0')}
                                                                            </div>
                                                                            <div className='w-2/5 font-bold text-blue-800'>
                                                                                {item.backOdd}
                                                                            </div>
                                                                        </div>
                                                                    })}
                                                                </div>
                                                            })}
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse'>
                                    <button
                                            type='button'
                                            className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                                            onClick={onCancelClickCb}
                                    >
                                        OK
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
            )) ||
            null
    );
};
export default SignChartModal;
