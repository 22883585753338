import {LOGOUT_FAILED, LOGOUT_SUCCESS} from '../../constants/LoginConstants';
import {call, put} from 'redux-saga/effects';
import {ISkeletonSaga, skeletonSaga} from '../../apis/skeleton/skeletonSaga';
import {LogoutCall} from "../../apis/user";

function* tryFunction() {
    yield call(LogoutCall);
    localStorage.removeItem('sessionToken');
    yield put({
        type: LOGOUT_SUCCESS
    });
}

export function* logoutSaga(): Generator {
    const sagaPayload: ISkeletonSaga = {
        tryApiCall: {function: tryFunction},
        failedType: LOGOUT_FAILED
    };
    yield call(skeletonSaga, sagaPayload);
}
