import {AccountResponse} from '../../@types/response';
import {AxiosResponse} from 'axios';

export const responseHandler = (response: AxiosResponse<AccountResponse>) => {
    if (response.data.error?.code || response.status === -1) {
        throw {
            ...response.data.error,
            // placeholder: messageParam ? (response.data.data || {})[messageParam] || '' : ''
        };
    }
    return response.data.data;
};
