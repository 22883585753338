import React, {useEffect, useMemo, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Dropdown, DropDownItem} from "../common/DropDown";
import {BookmakerData, BookmakerObject, ExtraSettingsObject, SuggestionObject, User,} from "../../../@types/response";
import {useDispatch, useSelector} from "react-redux";
import {getBookmakers} from "../../../store/selectors/getBookmakers";
import FilterInput from "../common/FilterInput";
import getErrorMessage from "../../../store/selectors/getErrorMessage";
import {AutocompleteExtra} from "../common/Autocomplete";
import {getSuggestions} from "../../../store/selectors/extra/getSuggestions";
import {faTrophy} from "@fortawesome/free-solid-svg-icons/faTrophy";
import {Drawer} from "../common/Drawer";
import TemplateButton from "./_template";
import {getTemplate} from "../../../store/selectors/extra/getTemplate";
import {getTemplates} from "../../../store/selectors/extra/getTemplates";
import {faRetweet} from "@fortawesome/free-solid-svg-icons";
import Logo from "../logo";
import Separator from "../Separator";
import {SetSettingsAction} from "../../../store/actions/extra/SetSettingsAction";
import {GetAlertsAction} from "../../../store/actions/extra/GetAlertsAction";
import {getSettings} from "../../../store/selectors/extra/getSettings";
import {SearchEventAction} from "../../../store/actions/extra/SearchEventAction";
import {isInitialized} from "../../../store/selectors/extra/isInitialized";
import {HeartBeatDropDown} from "../common/HeartBeatDropDown";
import {getComparisons} from "../../../store/selectors/getComparisons";
import {ComparisonsDropdown} from "./DropDown";
import getClient from "../../../store/selectors/getClient";
import {ENABLED_BOOKMAKERS} from "../../../constants/ExtraConstants";

export default function Toolbar() {
    const allBookmakers: BookmakerData = useSelector(getBookmakers);
    const comparisons: BookmakerObject[] = useSelector(getComparisons);

    const template: null | string = useSelector(getTemplate);
    const templates: string[] = useSelector(getTemplates);
    const suggestions: SuggestionObject[] = useSelector(getSuggestions);
    const errorMessage: string = useSelector(getErrorMessage);
    const storedSettings: ExtraSettingsObject = useSelector(getSettings);
    const loading: boolean = useSelector(isInitialized);
    const client: User | undefined = useSelector(getClient);

    const [pendingSettings, setPendingSettings] = useState<boolean>(false);
    const [showTournament, setShowTournament] = useState<boolean>(false);
    const [settings, setSetting] = useState<ExtraSettingsObject>(storedSettings);

    const dispatch = useDispatch();

    const bookmakers: BookmakerData = useMemo(() => {
        return Object.keys(allBookmakers)
                .filter(id => ENABLED_BOOKMAKERS.has(parseInt(id)) && (client?.client == null || client.client === parseInt(id)))
                .reduce((a, v) => ({...a, [v]: allBookmakers[v]}), {})
    }, [allBookmakers])

    useEffect(() => {
        setSetting(storedSettings);
    }, [storedSettings]);

    useEffect(() => {
        setPendingSettings(JSON.stringify(settings) !== JSON.stringify(storedSettings));
    }, [settings, storedSettings]);

    useEffect(() => {
        document.title = `${template ? template + ' - ' : ''}EXTRA - OddMonitor`;
    }, [template]);

    return (
            <div className="flex w-full h-14 2xl:text-monitorBase text-monitorSm">
                <Drawer
                        settings={settings}
                        isOpen={showTournament}
                        onClose={() => null}
                        onChange={(sports) => setSetting(Object.assign({}, settings, {sports}))}
                />
                <Logo/>
                <ul className="flex items-center justify-around py-2 w-full">
                    <Separator/>
                    <li className="mx-4">
                        <AutocompleteExtra
                                placeholder="search event"
                                searchAction={(value) => dispatch(SearchEventAction(value))}
                                suggestions={suggestions}
                        />
                    </li>
                    <Separator/>
                    <li className="mx-4">
                        <div className="relative flex flex-col ml-4">
                            <label htmlFor="dataFilter" className={"text-white"}>
                                DATE
                            </label>
                            <select
                                    id="dataFilter"
                                    name="dataFilter"
                                    className={"text-white bg-toolbar-bgMain"}
                                    onChange={({target}) =>
                                            setSetting(Object.assign({}, settings, {date: parseInt(target.value)}))
                                    }
                                    value={settings.date}
                            >
                                <option value={0}>All</option>
                                <option value={1}>Today</option>
                                <option value={2}>Today + 1</option>
                                <option value={3}>Today + 2</option>
                                <option value={4}>Today + 3</option>
                                <option value={5}>Today + 4</option>
                                <option value={6}>Today + 5</option>
                                <option value={7}>Today + 6</option>
                            </select>
                        </div>
                    </li>
                    <Separator/>
                    <li className="mx-4">
                        <FilterInput
                                id="from"
                                name="from"
                                label="FROM"
                                min={1}
                                max={50}
                                step={0.1}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (isNaN(parseInt(e.target.value))) return;
                                    setSetting(Object.assign({}, settings, {from: parseFloat(e.target.value)}));
                                }}
                                value={settings.from}
                        />
                    </li>
                    <Separator/>
                    <li className="mx-4">
                        <FilterInput
                                id="to"
                                name="to"
                                label="TO"
                                min={1}
                                max={100}
                                step={0.1}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (isNaN(parseInt(e.target.value))) return;
                                    setSetting(Object.assign({}, settings, {to: parseFloat(e.target.value)}));
                                }}
                                value={settings.to}
                        />
                    </li>
                    <Separator/>
                    <li className="flex flex-col items-center">
                        <Dropdown label="BOOKMAKERS" color="default">
                            {Object.values(bookmakers)
                                    .sort((a, b) => (b.name > a.name ? -1 : 1))
                                    .map((bookmaker) => {
                                        if (bookmaker.isComparison) return null;
                                        const visible = settings.bookmakers.includes(bookmaker.id);
                                        return (
                                                <DropDownItem
                                                        key={bookmaker.name}
                                                        label={bookmaker.name}
                                                        selected={visible}
                                                        onClick={() => {
                                                            if (!visible) {
                                                                setSetting(
                                                                        Object.assign({}, settings, {
                                                                            bookmakers: [...settings.bookmakers, bookmaker.id],
                                                                        })
                                                                );
                                                            } else {
                                                                setSetting(
                                                                        Object.assign({}, settings, {
                                                                            bookmakers: settings.bookmakers.filter(
                                                                                    (item) => item !== bookmaker.id
                                                                            ),
                                                                        })
                                                                );
                                                            }
                                                        }}
                                                />
                                        );
                                    })}
                        </Dropdown>
                    </li>
                    <Separator/>
                    <li className="flex flex-col items-center">
                        <ComparisonsDropdown
                                settings={settings}
                                comparisons={comparisons}
                                loading={loading}
                                setSetting={(settings) => {
                                    setSetting(settings)
                                }}
                        />
                    </li>
                    <Separator/>
                    <li className="mx-4">
                        <TemplateButton settings={settings} template={template} templates={templates}/>
                    </li>
                    <Separator/>

                    <li>
                        <button
                                className={`p-3 rounded-md ${
                                        loading
                                                ? "bg-toolbar-settingsLoading text-white "
                                                : pendingSettings
                                                        ? "bg-toolbar-settingsWarning text-black "
                                                        : "bg-toolbar-settings text-white "
                                }`}
                                onClick={() => {
                                    dispatch(SetSettingsAction(settings));
                                    dispatch(GetAlertsAction());
                                }}
                        >
                            <FontAwesomeIcon className={loading ? "animate-spin" : ""} icon={faRetweet}/>
                        </button>
                    </li>
                    <li className="mx-4">
                        <button
                                className={"peer h-10 bg-toolbar-bgMain text-white mx-3"}
                                onClick={() => setShowTournament(!showTournament)}
                        >
                            <FontAwesomeIcon icon={faTrophy}/>
                        </button>
                    </li>
                    <li className="mr-4">
                        <HeartBeatDropDown/>
                    </li>
                </ul>
                <div>{errorMessage}</div>
            </div>
    );
}
