import {call, put} from 'redux-saga/effects';
import {ISkeletonSaga, skeletonSaga} from '../../apis/skeleton/skeletonSaga';
import {GetEventCall} from "../../apis/event";
import {GetEventPayload} from "../../@types/actions";
import {GET_EVENT_ERROR, GET_EVENT_SUCCESS} from "../../constants/ExtraConstants";

function* tryFunction(payload: GetEventPayload) {
    const response: Record<string, string> = yield call(GetEventCall, payload);
    yield put({
        type: GET_EVENT_SUCCESS,
        payload: response
    });
}

export function* getEventSaga({payload}: { payload: GetEventPayload }): Generator {
    const sagaPayload: ISkeletonSaga = {
        tryApiCall: {function: tryFunction, payload},
        failedType: GET_EVENT_ERROR
    };
    yield call(skeletonSaga, sagaPayload);
}