import React, {FC, RefObject, useContext, useEffect} from 'react';
import {EventStoredObject, PlayerSuggestionObject, SuggestionObject} from '../../../@types/response';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {ModalsContext} from "../modals/ModalsContext";
import {MODALS_TYPES} from "../../../constants/ModalsConstants";
import {EventModalProps} from "../../../@types/inputs";
import {formatTimestamp} from "../../../store/utils/commonUtils";

interface DropDownItemProps {
    onSelectionClick: any,
    label: string,
    date?: string,
    player?: string
}

export const DropDownItem: FC<DropDownItemProps> = ({onSelectionClick, label, date, player}) => {

    return <div
            className='2xl:text-monitorBase text-monitorSm py-2 px-4 font-normal flex w-full whitespace-nowrap hover:bg-blue-400 cursor-pointer'
    >
        <div
                className='ml-3 text-dropDown-text font-medium flex-grow text-left'
                onClick={onSelectionClick}
        ><b>{player ? player + ' - ' : ''}</b>{label + (date ? ' [' + date + ']' : '')}</div>
        <div
                className='ml-4 flex-shrink'
        >
            <FontAwesomeIcon icon={faSearch}/>
        </div>
    </div>
}

interface DropDownProps {
    searchAction: (value: string) => void
    placeholder?: string
    suggestions: SuggestionObject[]
}

interface DropDownPlayerProps {
    searchAction: (value: string) => void
    placeholder?: string
    suggestions: PlayerSuggestionObject[]
}

const useWindowEventListener = (event: any, callback: any) => {
    useEffect(() => {
        window.addEventListener(event, callback)
        return () => window.removeEventListener(event, callback)
    }, [event, callback])
}

export const Autocomplete: FC<DropDownProps> = ({searchAction, suggestions, placeholder = ''}) => {
    const [value, setValue] = React.useState('');
    const [dropdownShow, setDropdownShow] = React.useState(suggestions.length > 0);
    const popoverDropdownRef: RefObject<HTMLDivElement> = React.createRef()

    const {setOpenedModalsCallback, setModalsDataCallback} = useContext(ModalsContext);

    function onShowEventDetails(item: SuggestionObject) {
        const _event: EventModalProps = {id: item.id, name: item.name, sportId: item.sportId}
        setModalsDataCallback(MODALS_TYPES.EVENT_DETAILS_MODAL, {event: _event});
        setOpenedModalsCallback(MODALS_TYPES.EVENT_DETAILS_MODAL, true);
    }

    useWindowEventListener('mousedown', (event: { target: any }) => {
        if (popoverDropdownRef.current && !popoverDropdownRef.current.contains(event.target)) {
            setDropdownShow(false)
        }
    })
    return <div className='flex flex-wrap'>
        <div className='flex'>
            <input
                    id="event-search"
                    className='2xl:text-monitorBase text-monitorSm text-white bg-input focus:outline-none rounded-xl p-1'
                    placeholder={placeholder}
                    value={value}
                    onFocus={() => setDropdownShow(true)}
                    onChange={(e) => {
                        setValue(e.target.value)
                        searchAction(e.target.value)
                        setDropdownShow(e.target.value.length >= 3)
                    }}
            />
            <FontAwesomeIcon className='relative right-6 top-1 text-icon' icon={faSearch}/>
        </div>

        <div
                className={
                        (dropdownShow && suggestions.length > 0 ? 'absolute ' : 'hidden ') +
                        'top-full text-base z-50 bg-white py-2 list-none text-left rounded shadow-lg left-52 '
                }
                style={{minWidth: '12rem'}}
                ref={popoverDropdownRef}
        >
            {suggestions.map((item) => {
                return <DropDownItem
                        key={item.id}
                        onSelectionClick={() => {
                            setDropdownShow(false)
                            onShowEventDetails(item)
                        }}
                        label={item.name}/>
            })}
        </div>
    </div>
}

export const AutocompletePlayer: FC<DropDownPlayerProps> = ({searchAction, suggestions, placeholder = ''}) => {
    const [value, setValue] = React.useState('');
    const [dropdownShow, setDropdownShow] = React.useState(suggestions.length > 0);
    const popoverDropdownRef: RefObject<HTMLDivElement> = React.createRef()

    const {setOpenedModalsCallback, setModalsDataCallback} = useContext(ModalsContext);

    function onShowEventDetails(item: SuggestionObject) {
        const _event: EventModalProps = {id: item.id, name: item.name, sportId: item.sportId}
        setModalsDataCallback(MODALS_TYPES.PLAYER_MARKET_MODAL, {event: _event, marketId: '', signId: ''});
        setOpenedModalsCallback(MODALS_TYPES.PLAYER_MARKET_MODAL, true);
    }

    function onShowPlayerEventDetails(item: PlayerSuggestionObject) {
        const _event: EventStoredObject = {
            _id: item.eventId,
            name: item.eventName,
            sportId: 0,
            sport: '',
            category: '',
            categoryId: 0,
            tournament: '',
            tournamentId: 0,
            date: item.eventDate
        }
        setModalsDataCallback(MODALS_TYPES.PLAYER_DETAILS_MODAL, {event: _event, playerId: item.id || ''});
        setOpenedModalsCallback(MODALS_TYPES.PLAYER_DETAILS_MODAL, true);
    }

    useWindowEventListener('mousedown', (event: { target: any }) => {
        if (popoverDropdownRef.current && !popoverDropdownRef.current.contains(event.target)) {
            setDropdownShow(false)
        }
    })
    return <div className='flex flex-wrap'>
        <div className='flex'>
            <input
                    className='2xl:text-monitorBase text-monitorSm text-white bg-input focus:outline-none rounded-xl p-1'
                    placeholder={placeholder}
                    value={value}
                    onFocus={() => setDropdownShow(true)}
                    onChange={(e) => {
                        setValue(e.target.value)
                        searchAction(e.target.value)
                        setDropdownShow(e.target.value.length >= 3)
                    }}
            />
            <FontAwesomeIcon className='relative right-6 top-1 text-icon' icon={faSearch}/>
        </div>

        <div
                className={
                        (dropdownShow && suggestions.length > 0 ? 'absolute ' : 'hidden ') +
                        'top-full text-base z-50 bg-white py-2 list-none text-left rounded shadow-lg left-52 '
                }
                style={{minWidth: '12rem'}}
                ref={popoverDropdownRef}
        >
            {suggestions.map((item) => {
                return <DropDownItem
                        key={item.id}
                        onSelectionClick={() => {
                            setDropdownShow(false)
                            item.id ? onShowPlayerEventDetails(item) : onShowEventDetails({
                                id: item.eventId,
                                name: item.eventName,
                                sportId: 0
                            })
                        }}
                        label={item.eventName}
                        player={item.name || undefined}
                        date={formatTimestamp(item.eventDate)}
                />
            })}
        </div>
    </div>
}

export const AutocompleteExtra: FC<DropDownProps> = ({searchAction, suggestions, placeholder = ''}) => {
    const [value, setValue] = React.useState('');
    const [dropdownShow, setDropdownShow] = React.useState(suggestions.length > 0);
    const popoverDropdownRef: RefObject<HTMLDivElement> = React.createRef()

    const {setOpenedModalsCallback, setModalsDataCallback} = useContext(ModalsContext);

    function onShowEventDetails(item: SuggestionObject) {
        const _event = {_id: item.id, name: item.name, sportId: item.sportId}
        setModalsDataCallback(MODALS_TYPES.EXTRA_DETAILS_MODAL, {
            event: _event,
            marketId: ''
        });
        setOpenedModalsCallback(MODALS_TYPES.EXTRA_DETAILS_MODAL, true);
    }

    useWindowEventListener('mousedown', (event: { target: any }) => {
        if (popoverDropdownRef.current && !popoverDropdownRef.current.contains(event.target)) {
            setDropdownShow(false)
        }
    })
    return <div className='flex flex-wrap'>
        <div className='flex'>
            <input
                    id="event-search"
                    className='2xl:text-monitorBase text-monitorSm text-white bg-input focus:outline-none rounded-xl p-1'
                    placeholder={placeholder}
                    value={value}
                    onFocus={() => setDropdownShow(true)}
                    onChange={(e) => {
                        setValue(e.target.value)
                        searchAction(e.target.value)
                        setDropdownShow(e.target.value.length >= 3)
                    }}
            />
            <FontAwesomeIcon className='relative right-6 top-1 text-icon' icon={faSearch}/>
        </div>

        <div
                className={
                        (dropdownShow && suggestions.length > 0 ? 'absolute ' : 'hidden ') +
                        'top-full text-base z-50 bg-white py-2 list-none text-left rounded shadow-lg left-52 '
                }
                style={{minWidth: '12rem'}}
                ref={popoverDropdownRef}
        >
            {suggestions.map((item) => {
                return <DropDownItem
                        key={item.id}
                        onSelectionClick={() => {
                            setDropdownShow(false)
                            onShowEventDetails(item)
                        }}
                        label={item.name}/>
            })}
        </div>
    </div>
}