import {call, put} from 'redux-saga/effects';
import {ISkeletonSaga, skeletonSaga} from '../../apis/skeleton/skeletonSaga';
import {GetSettingsCall} from "../../apis/player";
import {GET_SETTINGS_SUCCESS} from "../../constants/PlayerConstants";
import {GENERIC_ERROR} from "../../constants/ErrorConstants";

function* tryFunction(template: string) {
    const settings: Record<string, string> = yield call(GetSettingsCall, template);
    yield put({
        type: GET_SETTINGS_SUCCESS,
        payload: {template, settings}
    });
}

export function* getSettingSaga({payload}: { payload: string }): Generator {
    const sagaPayload: ISkeletonSaga = {
        tryApiCall: {function: tryFunction, payload: payload},
        failedType: GENERIC_ERROR
    };
    yield call(skeletonSaga, sagaPayload);
}
