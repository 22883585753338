import {skeletonGet} from './skeleton/skeletonGet';
import {DeleteAlertMarketPayload, GetOddHistoryPayload, SearchEventPayload} from "../@types/actions";
import {skeletonDelete} from "./skeleton/skeletonDelete";

const eventEndpoint = 'event'

export const GetEventCall = ({eventId}: { eventId: number }): Promise<any> => {
    return skeletonGet(`${eventId}/main`, eventEndpoint);
};

export const SearchEventCall = (payload: SearchEventPayload): Promise<any> => {
    return skeletonGet(`search/${payload.event}`, eventEndpoint);
};

export const GetOddHistoryCall = (payload: GetOddHistoryPayload): Promise<any> => {
    const query = `b=${payload.bookmakerId}&m=${payload.marketId}&s=${payload.signId}${!payload.spread || payload.spread === '#' ? '' : `&p=${payload.spread}`}`
    return skeletonGet(`odd-history/${payload.eventId}?${query}`, eventEndpoint);
};

export const GetEventsRawCall = (): Promise<any> => {
    return skeletonGet('', eventEndpoint);
};

export const DeleteAlertCall = (payload: DeleteAlertMarketPayload): Promise<any> => {
    return skeletonDelete(`${payload.eventId}/${payload.bookmakerId}/${payload.marketId}`, eventEndpoint);
};