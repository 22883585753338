export const formatDate = (date: Date, year: boolean = false): string => {

    let resultParts = [date.getDate().toString().padStart(2, '0'), (date.getMonth() + 1).toString().padStart(2, '0')]
    if (year) {
        resultParts.push(date.getFullYear().toString().padStart(2, '0'))
    }

    return resultParts.join('/') + ' ' +
        date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0')
}

export const formatTimestamp = (time: number, year: boolean = false): string => {
    return formatDate(new Date(time), year)
}

export const getSbvMarket = (marketId: string): { market: number, svb: null | string } => {
    if (!marketId.includes('@'))
        return {market: parseInt(marketId), svb: null};
    const mp = marketId.split('@')
    return {market: parseInt(mp[0]), svb: mp[1]};
}

export const toMoney = (value?: number): string => {
    if (!value) return '0'
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}