import React, {useEffect, useMemo, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Dropdown, DropDownItem} from '../common/DropDown';
import {
    BookmakerData,
    PlayerMarketData,
    PlayerSettingsObject,
    PlayerSuggestionObject,
    User
} from '../../../@types/response';
import {useDispatch, useSelector} from 'react-redux';
import {getBookmakers} from '../../../store/selectors/getBookmakers';
import FilterInput from '../common/FilterInput';
import {faTrophy} from "@fortawesome/free-solid-svg-icons/faTrophy";
import {Drawer} from "../common/Drawer";
import {faRetweet} from '@fortawesome/free-solid-svg-icons';
import Logo from '../logo';
import Separator from '../Separator';
import {SetSettingsAction} from '../../../store/actions/player/SetSettingsAction';
import {getSettings} from "../../../store/selectors/player/getSettings";
import {isInitialized} from '../../../store/selectors/player/isInitialized';
import {getPlayerMarkets} from '../../../store/selectors/getPlayerMarkets';
import FilterNameInput from '../common/FilterNameInput';
import {ToolBarPlayerProps} from '../../../@types/inputs';
import {ComparisonsDropdown} from "./DropDown";
import {HeartBeatDropDown} from '../common/HeartBeatDropDown';
import {AutocompletePlayer} from '../common/Autocomplete';
import getClient from "../../../store/selectors/getClient";
import {ENABLED_BOOKMAKERS} from "../../../constants/PlayerConstants";
import {SearchPlayerEventAction} from "../../../store/actions/player/SearchPlayerEventAction";
import {getPlayerSuggestions} from "../../../store/selectors/player/getPlayerSuggestions";
import {DropDownMarkets} from "./DropDownMarkets";

export default function Toolbar({search, setSearch}: ToolBarPlayerProps) {

    const allBookmakers: BookmakerData = useSelector(getBookmakers);

    const playerMarkets: PlayerMarketData = useSelector(getPlayerMarkets);
    const storedSettings: PlayerSettingsObject = useSelector(getSettings);
    const suggestions: PlayerSuggestionObject[] = useSelector(getPlayerSuggestions);
    const loading: boolean = useSelector(isInitialized)
    const client: User | undefined = useSelector(getClient);

    const [pendingSettings, setPendingSettings] = useState<boolean>(false)
    const [showTournament, setShowTournament] = useState<boolean>(false)
    const [settings, setSetting] = useState<PlayerSettingsObject>(storedSettings)
    const [selectAllBookmakers, setSelectAllBookmakers] = useState<boolean>(false)
    const [sportMarketOutcomes, setSportMarketOutcomes] = useState<string[]>([])
    const [selectAllMarkets, setSelectAllMarkets] = useState<boolean>(false)


    const dispatch = useDispatch()

    const bookmakers: BookmakerData = useMemo(() => {
        return Object.keys(allBookmakers)
                .filter(id => ENABLED_BOOKMAKERS.has(parseInt(id)) && (
                        client?.client == null || client.client === parseInt(id)
                ))
                .reduce((a, v) => ({...a, [v]: allBookmakers[v]}), {})
    }, [allBookmakers, client])

    useEffect(() => {
        setSetting(storedSettings)
    }, [storedSettings])

    useEffect(() => {
        setPendingSettings(JSON.stringify(settings) !== JSON.stringify(storedSettings))
    }, [settings, storedSettings])

    return <div className='flex w-full h-14 2xl:text-monitorBase text-monitorSm'>
        <Drawer
                settings={settings}
                isOpen={showTournament}
                onClose={() => null}
                onChange={(sports) => setSetting(Object.assign({}, settings, {sports}))}
        />
        <Logo/>
        <ul className='flex items-center justify-around py-2 w-full'>
            <Separator/>
            <li className="mx-2">
                <AutocompletePlayer
                        placeholder="search event"
                        searchAction={(value) => dispatch(SearchPlayerEventAction(value))}
                        suggestions={suggestions}
                />
            </li>
            <Separator/>
            <li className='mx-2'>
                <div className='relative flex flex-col ml-4'>
                    <label
                            htmlFor='dataFilter'
                            className={'text-white'}>EVENT</label>
                    <select
                            id='dataFilter'
                            name='dataFilter'
                            className={'text-white bg-toolbar-bgMain'}
                            onChange={({target}) => setSetting(Object.assign({}, settings, {date: parseInt(target.value)}))}
                            value={settings.date}
                    >
                        <option value={0}>All</option>
                        <option value={-1}>Next Hour</option>
                        <option value={-2}>Next 2 Hours</option>
                        <option value={1}>Today</option>
                        <option value={2}>Today + 1</option>
                        <option value={3}>Today + 2</option>
                        <option value={4}>Today + 3</option>
                        <option value={5}>Today + 4</option>
                        <option value={6}>Today + 5</option>
                        <option value={7}>Today + 6</option>
                    </select>

                </div>
            </li>
            <Separator/>
            <li className='mx-2'>
                <FilterInput
                        id='from'
                        name='from'
                        label='FROM'
                        min={1}
                        max={4}
                        step={0.1}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (isNaN(parseInt(e.target.value))) return
                            setSetting(Object.assign({}, settings, {from: parseFloat(e.target.value)}))
                        }}
                        value={settings.from}
                />
            </li>
            <Separator/>
            <li className='mx-2'>
                <FilterInput
                        id='to'
                        name='to'
                        label='TO'
                        min={0}
                        max={50}
                        step={0.1}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (isNaN(parseInt(e.target.value))) return
                            setSetting(Object.assign({}, settings, {to: parseFloat(e.target.value)}))
                        }}
                        value={settings.to}
                />
            </li>
            <Separator/>
            <li className='mx-4'>
                <div className='relative flex flex-col'>
                    <Dropdown label='MARKETS' color='default'>
                        <DropDownMarkets playerMarkets={playerMarkets}
                                         settings={settings} setSetting={setSetting}
                                         setSelectAllMarkets={setSelectAllMarkets}
                                         selectAllMarkets={selectAllMarkets}
                                         sportMarketOutcomes={sportMarketOutcomes}
                                         setSportMarketOutcomes={setSportMarketOutcomes}/>
                    </Dropdown>
                </div>
            </li>
            <Separator/>
            <li className='flex flex-col items-center'>
                <Dropdown label='BOOKMAKERS' color='default'>
                    <DropDownItem
                            key={"all_bookmakers"}
                            label={"Select All"}
                            selected={selectAllBookmakers}
                            onClick={() => {
                                if (selectAllBookmakers) {
                                    setSetting(Object.assign({}, settings, {bookmakers: settings.bookmakers.filter((item) => bookmakers[item.toString()].isComparison)}))
                                    setSelectAllBookmakers(false)
                                } else {
                                    setSetting(Object.assign({}, settings, {
                                        bookmakers: [...settings.bookmakers, ...Object.keys(bookmakers).filter((b) => !bookmakers[b].isComparison).map(Number)].sort((a, b) => {
                                            return a - b
                                        })
                                    }))
                                    setSelectAllBookmakers(true)
                                }
                            }}
                    />
                    {Object.values(bookmakers)
                            .filter(b => !b.isComparison && client?.bookmakers.includes(b.id))
                            .sort((a, b) => b.name > a.name ? -1 : 1)
                            .map((bookmaker) => {
                                const visible = settings.bookmakers.includes(bookmaker.id)
                                return <DropDownItem
                                        key={bookmaker.name}
                                        label={bookmaker.name}
                                        selected={visible}
                                        onClick={() => {
                                            if (!visible) {
                                                setSetting(Object.assign({}, settings, {bookmakers: [...settings.bookmakers, bookmaker.id]}))
                                            } else {
                                                setSetting(Object.assign({}, settings, {bookmakers: settings.bookmakers.filter((item) => item !== bookmaker.id)}))
                                            }
                                        }}
                                />
                            })}
                </Dropdown>
            </li>
            <Separator/>
            <li className='flex flex-col items-center'>
                <ComparisonsDropdown
                        settings={settings}
                        bookmakers={allBookmakers}
                        loading={loading}
                        setSetting={(settings) => {
                            setSetting(settings)
                        }}
                />
            </li>
            <Separator/>
            <li>
                <button
                        className={`p-3 rounded-md ${loading ? 'bg-toolbar-settingsLoading text-white ' : (pendingSettings ? 'bg-toolbar-settingsWarning text-black ' : 'bg-toolbar-settings text-white ')}`}
                        onClick={() => {
                            dispatch(SetSettingsAction(settings))
                        }}>
                    <FontAwesomeIcon className={loading ? 'animate-spin' : ''} icon={faRetweet}/>
                </button>
            </li>
            <li className='mx-4'>
                <button
                        className={'peer h-10 bg-toolbar-bgMain text-white mx-3'}
                        onClick={() => setShowTournament(!showTournament)}>
                    <FontAwesomeIcon icon={faTrophy}/>
                </button>
            </li>
            <li className='mr-4'>
                <HeartBeatDropDown/>
            </li>
            <Separator/>
            <li className="mx-4  flex items-center ">
                <FilterNameInput
                        id="player-search"
                        placeholder="search ..."
                        setValue={setSearch}
                        value={search}
                />
            </li>
        </ul>
    </div>
}