import {call, put} from 'redux-saga/effects';
import {ISkeletonSaga, skeletonSaga} from '../../apis/skeleton/skeletonSaga';
import {GetEventsMoneyLineCall} from "../../apis/events";
import {GENERIC_ERROR} from "../../constants/ErrorConstants";
import {GET_EVENT_MONEY_LINE_SUCCESS} from "../../constants/CommonConstants";


function* tryFunction() {
    const response: Record<string, string> = yield call(GetEventsMoneyLineCall);
    yield put({
        type: GET_EVENT_MONEY_LINE_SUCCESS,
        payload: response
    });
}

export function* getEventsMoneyLineSaga(): Generator {
    const sagaPayload: ISkeletonSaga = {
        tryApiCall: {function: tryFunction},
        failedType: GENERIC_ERROR
    };
    yield call(skeletonSaga, sagaPayload);
}