import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ExtraTable from '../layouts/components/extra/ExtraTable';
import Toolbar from '../layouts/components/extra/Toolbar';
import ExtraItem from '../layouts/components/extra/ExtraItem';
import {AlertExtraObject, BookmakerData, EventsStoredObjects, User,} from '../@types/response';
import {getBookmakers} from '../store/selectors/getBookmakers';
import {getExtras} from '../store/selectors/extra/getExtras';
import {GetInitAction} from '../store/actions/extra/GetInitAction';
import BookEventModal from '../layouts/components/modals/BookEventModal';
import getClient from '../store/selectors/getClient';
import SignChartModal from '../layouts/components/modals/SignChartModal';
import {getEvents} from '../store/selectors/extra/getEvents';
import {ENABLED_BOOKMAKERS} from '../constants/ExtraConstants';
import ExtraDetailsModal from '../layouts/components/modals/ExtraDetailsModal';

const Extra: React.FC = () => {
    const dispatch = useDispatch();

    const events: EventsStoredObjects = useSelector(getEvents);
    const extras: AlertExtraObject[] = useSelector(getExtras);
    const bookmakers: BookmakerData = useSelector(getBookmakers);
    const client: undefined | User = useSelector(getClient);

    const enabledBookmakers: Set<number> = useMemo(() => {
        return new Set(Object.keys(bookmakers)
                .filter((id) => ENABLED_BOOKMAKERS.has(parseInt(id)))
                .map((b) => parseInt(b)))
    }, [bookmakers])

    useEffect(() => {
        dispatch(GetInitAction(client?.id || ''));
        document.title = 'EXTRA - OddMonitor';
    }, []);

    if (!Object.keys(bookmakers).length) {
        return (
                <div>
                    <div
                            id='toolbar-extra'
                            className='bg-toolbar-bgMain border-b border-toolbar-borderBottom fixed top-0 right-0 flex items-end justify-around w-full z-10'
                    >
                        <Toolbar/>
                    </div>
                    <div className='mt-16'>
                        <ExtraTable/>
                    </div>
                    <div>Loading</div>
                </div>
        );
    }

    return <React.Fragment>
        <div
                id='toolbar-extra'
                className='bg-toolbar-bgMain border-b border-toolbar-borderBottom fixed top-0 right-0 flex items-end justify-around w-full z-10'
        >
            <Toolbar/>
        </div>
        <div className='mt-16 mx-4'>
            <ExtraTable>
                {extras
                        //TODO: move this check in utils?
                        .filter(alert => enabledBookmakers.has(alert.bookmakerId) && (client?.client == null || client.client === alert.bookmakerId))
                        .map((alert: AlertExtraObject, idx: number) => <ExtraItem alert={alert} key={`${alert.id}x${idx}`} event={events[alert.id]}/>)}
            </ExtraTable>
        </div>

        <ExtraDetailsModal/>
        <BookEventModal/>
        <SignChartModal/>
    </React.Fragment>;
};

export default Extra;
