import React, {useEffect, useState} from "react";
import DoClassic from "../layouts/components/dropping/DoClassic";
import EventDetailsModal from "../layouts/components/modals/EventDetailsModal";

const DroppingOddsClassic: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setLoading(false);
        document.title = "CLASSIC DROPPING ODDS - OddMonitor";
    }, []);

    if (loading) {
        return null;
    }

    return <React.Fragment>
        <div className="mt-16 mx-4">
            <DoClassic/>
        </div>
        <EventDetailsModal/>
    </React.Fragment>
};
export default DroppingOddsClassic;
