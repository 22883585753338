import React, {useEffect} from "react";

const Home: React.FC = () => {

    useEffect(() => {
        document.title = "DEFAULT - OddMonitor";
    }, []);

    return <div style={{
        background: `url('${process.env.PUBLIC_URL}/static/img/center-shadow.png')`,
        backgroundRepeat: "repeat-x"
    }}>
        <div className="relative mx-auto" style={{height: "450px", width: "960px"}}>
            <div
                    className="absolute"
                    style={{
                        left: "0px",
                        width: "370px",
                        height: "404px",
                        top: "50px",
                        opacity: 1,
                        zIndex: 1,
                    }}
            >
                <img
                        className="carousel-image"
                        alt=""
                        src={process.env.PUBLIC_URL + "/static/img/gallery-img1.png"}
                        style={{width: "370px", height: "404px"}}
                />
            </div>
            <div
                    className="absolute"
                    style={{
                        left: "274.5px",
                        width: "411px",
                        height: "449px",
                        top: "50px",
                        opacity: 1,
                        zIndex: 4,
                    }}
            >
                <img
                        className="carousel-image"
                        alt=""
                        src={process.env.PUBLIC_URL + "/static/img/gallery-img3.png"}
                        style={{width: "411px", height: "449pxpx"}}
                />
            </div>
            <div
                    className="absolute"
                    style={{
                        left: "590px",
                        width: "370px",
                        height: "404px",
                        top: "50px",
                        opacity: 1,
                        zIndex: 1,
                    }}
            >
                <img
                        className="carousel-image"
                        alt=""
                        src={process.env.PUBLIC_URL + "/static/img/gallery-img2.png"}
                        style={{width: "370px", height: "404px"}}
                />
            </div>
        </div>
    </div>;
};

export default Home;
