import {MARGIN_LIMIT} from "./CommonConstants";

const PREFIX = 'EXTRA'

enum ExtraConstants {
    EXTRA_INITIALIZE,
    EXTRA_INITIALIZE_SUCCESS,
    EXTRA_INITIALIZE_ERROR,
    STORE_EXTRA_INITIALIZE,
    STORE_EXTRA_INITIALIZE_ERROR,
    GET_EXTRA_SETTINGS,
    GET_EXTRA_SETTINGS_SUCCESS,
    DELETE_EXTRA_SETTINGS,
    DELETE_EXTRA_SETTINGS_SUCCESS,
    GET_EXTRA_EVENTS,
    GET_EXTRA_EVENTS_ERROR,
    GET_EXTRA_EVENTS_SUCCESS,
    NEW_EXTRA_EVENT,
    EDIT_EXTRA_EVENT,
    CLEAR_EXTRA_EVENT,
    NEW_BOOKED_EVENT,
    BOOK_EVENT,
    BOOK_EVENT_SUCCESS,
    BOOK_EVENT_ERROR,
    SEARCH_EVENT,
    SEARCH_EVENT_SUCCESS,
    GET_EVENT,
    GET_EVENT_SUCCESS,
    GET_EVENT_ERROR,
    DELETE_ALERT,
    DELETE_ALERT_SUCCESS,
    HIDE_ALERT,
    HIDE_ALERT_SUCCESS,
    NEW_HIDDEN_ALERT,
    GET_EXTRA_ODDS_EVENTS_SUCCESS,
    GET_EXTRA_ODDS_EVENTS,
    GET_EVENTS,
    GET_EVENTS_SUCCESS,
}

const SETTINGS = 'SETTINGS'

enum SettingConstants {
    SET_ORDER,
    SET_SPORT_FILTER,
    SET_SETTINGS,
}

export enum OrderConstants {
    NONE,
    DATE,
    MARKET,
    COMPARISON,
    BOOK
}

export const STORE_EXTRA_INITIALIZE = PREFIX + '_' + ExtraConstants.STORE_EXTRA_INITIALIZE;
export const STORE_EXTRA_INITIALIZE_ERROR = PREFIX + '_' + ExtraConstants.STORE_EXTRA_INITIALIZE_ERROR;
export const EXTRA_INITIALIZE = PREFIX + '_' + ExtraConstants.EXTRA_INITIALIZE;
export const EXTRA_INITIALIZE_SUCCESS = PREFIX + '_' + ExtraConstants.EXTRA_INITIALIZE_SUCCESS;
export const EXTRA_INITIALIZE_ERROR = PREFIX + '_' + ExtraConstants.EXTRA_INITIALIZE_ERROR;

export const GET_EXTRA_SETTINGS = PREFIX + '_' + ExtraConstants.GET_EXTRA_SETTINGS;
export const GET_EXTRA_SETTINGS_SUCCESS = PREFIX + '_' + ExtraConstants.GET_EXTRA_SETTINGS_SUCCESS;
export const DELETE_EXTRA_SETTINGS = PREFIX + '_' + ExtraConstants.DELETE_EXTRA_SETTINGS;
export const DELETE_EXTRA_SETTINGS_SUCCESS = PREFIX + '_' + ExtraConstants.DELETE_EXTRA_SETTINGS_SUCCESS;

export const GET_EXTRA_EVENTS = PREFIX + '_' + ExtraConstants.GET_EXTRA_EVENTS;
export const GET_EXTRA_EVENTS_ERROR = PREFIX + '_' + ExtraConstants.GET_EXTRA_EVENTS_ERROR;
export const GET_EXTRA_EVENTS_SUCCESS = PREFIX + '_' + ExtraConstants.GET_EXTRA_EVENTS_SUCCESS;
export const NEW_EXTRA_EVENT = PREFIX + '_' + ExtraConstants.NEW_EXTRA_EVENT;
export const EDIT_EXTRA_EVENT = PREFIX + '_' + ExtraConstants.EDIT_EXTRA_EVENT;
export const CLEAR_EXTRA_EVENT = PREFIX + '_' + ExtraConstants.CLEAR_EXTRA_EVENT;

export const GET_EXTRA_ODDS_EVENTS = PREFIX + '_' + ExtraConstants.GET_EXTRA_ODDS_EVENTS;
export const GET_EXTRA_ODDS_EVENTS_SUCCESS = PREFIX + '_' + ExtraConstants.GET_EXTRA_ODDS_EVENTS_SUCCESS;

export const SEARCH_EVENT = PREFIX + '_' + ExtraConstants.SEARCH_EVENT
export const SEARCH_EVENT_SUCCESS = PREFIX + '_' + ExtraConstants.SEARCH_EVENT_SUCCESS;
export const GET_EVENT = PREFIX + '_' + ExtraConstants.GET_EVENT;
export const GET_EVENT_SUCCESS = PREFIX + '_' + ExtraConstants.GET_EVENT_SUCCESS;
export const GET_EVENT_ERROR = PREFIX + '_' + ExtraConstants.GET_EVENT_ERROR;
export const GET_EVENTS = PREFIX + '_' + ExtraConstants.GET_EVENTS;
export const GET_EVENTS_SUCCESS = PREFIX + '_' + ExtraConstants.GET_EVENTS_SUCCESS;

export const BOOK_EVENT = PREFIX + '_' + ExtraConstants.BOOK_EVENT;
export const BOOK_EVENT_SUCCESS = PREFIX + '_' + ExtraConstants.BOOK_EVENT_SUCCESS;
export const BOOK_EVENT_ERROR = PREFIX + '_' + ExtraConstants.BOOK_EVENT_ERROR;

export const NEW_BOOKED_EVENT = PREFIX + '_' + ExtraConstants.NEW_BOOKED_EVENT;
export const DELETE_ALERT = PREFIX + '_' + ExtraConstants.DELETE_ALERT;
export const DELETE_ALERT_SUCCESS = PREFIX + '_' + ExtraConstants.DELETE_ALERT_SUCCESS;
export const HIDE_ALERT = PREFIX + '_' + ExtraConstants.HIDE_ALERT;
export const HIDE_ALERT_SUCCESS = PREFIX + '_' + ExtraConstants.HIDE_ALERT_SUCCESS;
export const NEW_HIDDEN_ALERT = PREFIX + '_' + ExtraConstants.NEW_HIDDEN_ALERT;

export const SET_SPORT_FILTER = SETTINGS + '_' + SettingConstants.SET_SPORT_FILTER;
export const SET_ORDER = SETTINGS + '_' + SettingConstants.SET_ORDER;
export const SET_SETTINGS = SETTINGS + '_' + SettingConstants.SET_SETTINGS;

export const ENABLED_BOOKMAKERS = new Set<number>([0, 1, 12, 107])

export const MIN_LIMIT = -7;
export const MAX_LIMIT = MARGIN_LIMIT;