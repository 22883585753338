import React from 'react';
import {ModelDataType} from './ModalsProvider';

export type ModalsContextType = {
    modalsData: { [k: string]: any };
    setModalsDataCallback: (modalType: number, values: ModelDataType) => void;
    openedModals: number[];
    setOpenedModalsCallback: (modalType: number, open: boolean) => void;
};
export const ModalsContext = React.createContext<ModalsContextType>({
    modalsData: {},
    setModalsDataCallback: () => false,
    openedModals: [],
    setOpenedModalsCallback: () => false
});
