import {takeLatest} from 'redux-saga/effects';
/*CONSTANTS*/
import {
    ADD_TO_BLACKLIST,
    BOOK_EVENT,
    GET_EVENT,
    GET_PLAYERS,
    GET_SETTINGS,
    HIDE_ALERT,
    PLAYER_INITIALIZE, SEARCH_PLAYER_EVENT,
    SET_SETTINGS
} from '../../constants/PlayerConstants';
/*SAGAS*/
import {getSettingSaga} from "./getSettingSaga";
import {getPlayersSaga} from './getPlayersSaga';
import {addToBlacklistSaga} from './addToBlacklistSaga';
import {bookEventSaga} from "./bookEventSaga";
import {hideAlertSaga} from "./hideAlertSaga";
import {initializeSaga} from './initializeSaga';
import {getEventPlayersSaga} from './getEventPlayersSaga';
import {setSettingSaga} from "./setSettingSaga";
import {searchPlayerEventSaga} from "./searchPlayerEventSaga";

export function* Initialize(): Generator {
    // @ts-ignore
    yield takeLatest(PLAYER_INITIALIZE, initializeSaga);
}

export function* SetSetting(): Generator {
    // @ts-ignore
    yield takeLatest(SET_SETTINGS, setSettingSaga);
}

export function* GetSetting(): Generator {
    // @ts-ignore
    yield takeLatest(GET_SETTINGS, getSettingSaga);
}

export function* GetPlayers(): Generator {
    // @ts-ignore
    yield takeLatest(GET_PLAYERS, getPlayersSaga);
}

export function* BookEvent(): Generator {
    // @ts-ignore
    yield takeLatest(BOOK_EVENT, bookEventSaga);
}

export function* SearchPlayerEvent(): Generator {
    // @ts-ignore
    yield takeLatest(SEARCH_PLAYER_EVENT, searchPlayerEventSaga);
}

export function* AddToBlacklist(): Generator {
    // @ts-ignore
    yield takeLatest(ADD_TO_BLACKLIST, addToBlacklistSaga);
}

export function* HideAlert(): Generator {
    // @ts-ignore
    yield takeLatest(HIDE_ALERT, hideAlertSaga);
}

export function* GetEvent(): Generator {
    // @ts-ignore
    yield takeLatest(GET_EVENT, getEventPlayersSaga);
}