import {call, put} from 'redux-saga/effects';
import {ISkeletonSaga, skeletonSaga} from '../../apis/skeleton/skeletonSaga';
import {GENERIC_ERROR} from '../../constants/ErrorConstants';
import {GET_PLAYERS_SUCCESS} from '../../constants/PlayerConstants';
import {GetSnapshotPlayersCall} from "../../apis/snapshot";

function* tryFunction() {
    const response: Record<string, string> = yield call(GetSnapshotPlayersCall);
    yield put({
        type: GET_PLAYERS_SUCCESS,
        payload: response
    });
}

export function* getPlayersSaga(): Generator {
    const sagaPayload: ISkeletonSaga = {
        tryApiCall: {function: tryFunction},
        failedType: GENERIC_ERROR
    };
    yield call(skeletonSaga, sagaPayload);
}