import axios from 'axios';
import {responseHandler} from './responseHandler';
import {
    CLIENT_IS_NOT_LOGGED_IN,
    PASSWORD_EXPIRED,
    PASSWORD_EXPIRED_ERROR,
    SESSION_EXPIRED_NOT_FOUND
} from '../../constants/ErrorConstants';
import {apiServiceUrl} from "./skeletonSaga";

export const skeletonGet = (action: string, endpoint: string): Promise<unknown> => {
    const token = localStorage.getItem('sessionToken');
    const headers = {"Authorization": `Bearer ${token}`};
    return axios
        .get(apiServiceUrl + endpoint + '/' + action, {headers})
        .then(responseHandler)
        .catch(function (error) {
            if (error.code === PASSWORD_EXPIRED) {
                throw {...error, type: PASSWORD_EXPIRED_ERROR};
            }
            if (error.code === SESSION_EXPIRED_NOT_FOUND) {
                throw {...error, type: CLIENT_IS_NOT_LOGGED_IN};
            }
            throw error;
        });
};
