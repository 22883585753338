import {EventStoredObject} from "../../../@types/response";
import {useContext} from "react";
import {ModalsContext} from "../modals/ModalsContext";
import {MODALS_TYPES} from "../../../constants/ModalsConstants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

export default function Odds({
                                 backOdd,
                                 alert,
                                 event,
                             }: { backOdd: number, alert: any, event: EventStoredObject }) {

    const {setOpenedModalsCallback, setModalsDataCallback} = useContext(ModalsContext);

    return (
            <div className='flex'>
                <div
                        data-value={backOdd.toFixed(2)}
                        className={`p-[0.4rem] relative rounded-md mx-[1px] text-center cursor-pointer w-1/1`}
                        onClick={() => {
                            if (!event) return
                            setModalsDataCallback(MODALS_TYPES.BOOK_PLAYER_MODAL, {
                                event: event,
                                alert: alert,
                                odd: backOdd
                            });
                            setOpenedModalsCallback(MODALS_TYPES.BOOK_PLAYER_MODAL, true);
                        }}
                >
                    <div
                    >
                        {backOdd.toFixed(2)}
                        <div className='absolute -right-2 top-0 cursor-pointer  text-red-500 btn-remove'>
                            <FontAwesomeIcon
                                    icon={faTimes}
                            />
                        </div>
                    </div>
                </div>
            </div>
    )
}
