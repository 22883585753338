import {fork} from 'redux-saga/effects';
import * as Watcher from './watcher';


export default function* rootSaga(): Generator {
    yield fork(Watcher.Initialize);
    yield fork(Watcher.GetPlayers);
    yield fork(Watcher.GetSetting);
    yield fork(Watcher.AddToBlacklist);
    yield fork(Watcher.HideAlert);
    yield fork(Watcher.GetEvent);
    yield fork(Watcher.SetSetting);
    yield fork(Watcher.BookEvent);
    yield fork(Watcher.SearchPlayerEvent);
}
