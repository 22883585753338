import {DoClassicComparisonSettingsObject, DoClassicSettingsObject} from "../../../@types/response";
import {Dropdown} from "../common/DropDown";
import {BETFAIR, MarginMethod, PINNACLE} from "../../../constants/CommonConstants";
import React, {FC} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {getClassicBookmaker} from "../../../store/selectors/dropping/getClassicBookmaker";
import {SetBookmakerAction} from "../../../store/actions/dropping/SetBookmakerAction";

interface ComparisonsDropdownProps {
    settings: DoClassicSettingsObject
    setSettings: (setting: DoClassicSettingsObject) => void
}

interface SettingItemProps {
    thisBookmaker: number
    label: string
    settings: DoClassicComparisonSettingsObject
    onChange: (settings: DoClassicComparisonSettingsObject) => void
    hasAmountOutcome: boolean
}

export const BookmakerDropdown: FC<ComparisonsDropdownProps> = ({
                                                                    settings,
                                                                    setSettings
                                                                }) => {
    return <Dropdown label="BOOKMAKER" color="defaultRight" isMaxHeight={true}>
        {[BETFAIR, PINNACLE].map((comparisonId) => {
            const currentSettings: DoClassicComparisonSettingsObject = settings.comparisons[comparisonId.toString()]
            return <SettingItem
                    key={comparisonId}
                    thisBookmaker={comparisonId}
                    label={comparisonId === BETFAIR ? 'Betfair' : 'Pinnacle'}
                    settings={currentSettings}
                    onChange={(newSettings) => {
                        const newComparisons: {
                            [comparisonId: string]: DoClassicComparisonSettingsObject
                        } = Object.assign({}, settings.comparisons)
                        newComparisons[(comparisonId)] = newSettings
                        setSettings(Object.assign({}, settings, {
                            comparisons: newComparisons
                        }))
                    }}
                    hasAmountOutcome={comparisonId === BETFAIR}
            />
        })}
    </Dropdown>
}

const SettingItem: FC<SettingItemProps> = ({
                                               label,
                                               thisBookmaker,
                                               settings,
                                               onChange,
                                               hasAmountOutcome
                                           }) => {
    const [pending, setPending] = React.useState(false);
    const [method, setMethod] = React.useState<null | number>(settings.method);
    const [amountMarket, setAmountMarket] = React.useState(settings.amountMarket);
    const [amountOutcome, setAmountOutcome] = React.useState(settings.amountOutcome);
    const bookmaker = useSelector(getClassicBookmaker)
    const dispatch = useDispatch();

    return (
            <div className="px-3 py-2 w-[26rem]">
                <div className="border border-1.5 rounded-md border-gray-300 p-3">
                    <div className="flex text-gray-700 font-medium">
                        <button
                                type="button"
                                className={"text-sm py-1 px-4 font-normal block w-full whitespace-nowrap"}
                                onClick={() => {
                                    bookmaker === BETFAIR ? dispatch(SetBookmakerAction(PINNACLE)) : dispatch(SetBookmakerAction(BETFAIR))
                                    onChange({
                                        status: bookmaker === thisBookmaker,
                                        method: method,
                                        amountMarket: amountMarket,
                                        amountOutcome: amountOutcome
                                    });
                                    setPending(false);
                                }}
                        >
                            <label className="flex items-center cursor-pointer">
                                <div className="relative">
                                    <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"/>
                                    <div
                                            className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"
                                            style={
                                                thisBookmaker === bookmaker
                                                        ? {
                                                            transform: "translateX(100%)",
                                                            backgroundColor: "#48bb78",
                                                        }
                                                        : undefined
                                            }
                                    />
                                </div>
                                <div className="ml-3 text-lg text-black font-bold">{label}</div>
                            </label>
                        </button>
                        <button
                                className={`peer w-32 bg-white border border-1.5 rounded-md border-gray-300 p-1 cursor-pointer
                ${pending ? "border-yellow-400 text-yellow-400" : ""}`}
                                onClick={() => {
                                    onChange({
                                        status: settings.status,
                                        method: method,
                                        amountMarket: amountMarket,
                                        amountOutcome: amountOutcome
                                    });
                                    setPending(false);
                                }}
                        >
                            <FontAwesomeIcon icon={faCheck}/>
                            {pending && <span className="text-sm px-2">Save</span>}
                        </button>
                    </div>
                    <div className="flex mt-1">
                    </div>
                    <hr className="m-4"/>
                    <div className="flex mt-1">
                        <div className="flex w-1/2">
                            <div className="w-1/2">
                                <label className="inline-flex">
                                    <span>Method</span>
                                </label>
                            </div>
                            <select
                                    className='w-1/2 bg-white border border-1.5 rounded-md mx-1 overflow-hidden'
                                    onChange={(e) => {
                                        setMethod(parseInt(e.target.value));
                                        setPending(true);
                                    }}
                                    disabled={method === null}
                                    value={method !== null ? method : ''}
                            >
                                <option value={MarginMethod.ORIGINAL}>original</option>
                                <option value={MarginMethod.NEW}>new</option>
                            </select>
                        </div>
                        <div className="flex w-3/5">
                            <div className="w-2/3 ml-1">Min Market</div>
                            <div className="w-1/2 bg-white border border-1.5 rounded-md px-1 mx-1 flex">
                                <input
                                        type="number"
                                        min={0}
                                        step={50}
                                        className="w-5/6 px-1 text-gray-900 placeholder-transparent focus:outline-none"
                                        value={amountMarket}
                                        onChange={(e) => {
                                            const value = !isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : 0;
                                            setPending(true);
                                            setAmountMarket(value);
                                        }}
                                />
                                <p> € </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex mt-1">
                        <div className="flex w-1/2">
                        </div>
                        <div className="flex w-3/5">
                            {hasAmountOutcome && (<>
                                <div className="w-2/3 ml-1">Min Outcome</div>
                                <div className="w-1/2 bg-white border border-1.5 rounded-md px-1 mx-1 flex">
                                    <input
                                            type="number"
                                            min={0}
                                            step={50}
                                            className="w-5/6 px-1 text-gray-900 placeholder-transparent focus:outline-none"
                                            value={amountOutcome}
                                            onChange={(e) => {
                                                const value = !isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : 0;
                                                setPending(true);
                                                setAmountOutcome(value);
                                            }}
                                    />
                                    <p> € </p>
                                </div>
                            </>)}
                        </div>
                    </div>
                </div>
            </div>
    );
};