import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {BookmakerData, BooksObject, MarketData} from '../@types/response';
import {getBookmakers} from '../store/selectors/getBookmakers';
import {getMarkets} from '../store/selectors/getMarkets';
import getBooks from "../store/selectors/monitor/getBooks";
import Toolbar from "../layouts/components/group/Toolbar";

const Group: React.FC = () => {

    const bookmakers: BookmakerData = useSelector(getBookmakers);
    const markets: MarketData = useSelector(getMarkets);
    const books: BooksObject[] = useSelector(getBooks);

    useEffect(() => {
        document.title = 'Team - OddMonitor';
    }, []);

    if (!Object.keys(bookmakers).length) {
        return (
                <div>
                    <div
                            id='toolbar-group'
                            className='bg-toolbar-bgMain border-b border-toolbar-borderBottom fixed top-0 right-0 flex items-end justify-around w-full z-10'
                    >
                        <Toolbar/>
                    </div>
                    <div className='mt-16'>
                    </div>
                    <div>Loading</div>
                </div>
        );
    }
    return <React.Fragment>
        <div
                id='toolbar-group'
                className='bg-toolbar-bgMain border-b border-toolbar-borderBottom fixed top-0 right-0 flex items-end justify-around w-full z-10'
        >
            <Toolbar/>
        </div>
        <div className='mt-16 mx-4'>
            <div className='w-full inline-block'>
                <table className='text-table-primary w-full table-fixed'>
                    <colgroup>
                        {/*event_name*/}
                        <col className='w-auto'/>
                        {/*book*/}
                        <col className='w-[10%]'/>
                        {/*market*/}
                        <col className='w-[10%]'/>
                        {/*sign*/}
                        <col className='w-[8%]'/>
                        {/*odd*/}
                        <col className='w-[8%]'/>
                        {/*amount*/}
                        <col className='w-[10%]'/>
                        {/*user*/}
                        <col className='w-[15%]'/>
                        {/*date*/}
                        <col className='w-[10%]'/>
                    </colgroup>
                    <thead>
                    <tr className='uppercase 2xl:text-monitorBase text-monitorSm tracking-[-0.11px] leading-normal'>
                        <th>Name</th>
                        <th>Book</th>
                        <th>Market</th>
                        <th>Sign</th>
                        <th>Odd</th>
                        <th>Amount</th>
                        <th>User</th>
                        <th>Time</th>
                    </tr>
                    </thead>
                    <tbody className='2xl:text-monitorBase text-monitorSm font-normal'>
                    {books.map((book: BooksObject, idx: number) => {
                        const market = markets[book.market] ?? book.market
                        const sign = market?.outcomes?.[book.sign]?.name ?? book.sign
                        const indt = new Date(book.indt)
                        return <tr
                                id={`book-${idx}`}
                                key={`book-${idx}`}
                                className={'border-b-2 tracking-[1px] border-black hover:bg-gray-600 bg-table-secondary text-center'}
                        >
                            <td className='items-start pl-4 relative'>
                                <div className='flex flex-col py-1 2xl:max-w-[30rem] max-w-xs'>
                                    <div className='text-left text-white truncate 2xl:text-monitorBase text-[0.68rem] font-medium'>
                                        {book.eventName}
                                    </div>
                                    <div className='text-left 2xl:text-monitorBase truncate text-[0.68rem]'
                                         data-id='competition'>
                                        {book.playerName}&nbsp;
                                    </div>
                                </div>
                            </td>

                            <td>{bookmakers[book.bookmaker.toString()]?.name ?? ''}</td>
                            <td>{market.name}</td>
                            <td>
                                {sign}
                                {book.sbv && <i className="font-thin text-table-sbv">[{book.sbv}]&nbsp;</i>}
                            </td>
                            <td>{book.odd}</td>
                            <td>{book.amount.toFixed(2)}€</td>
                            <td>{book.userId}</td>
                            <td>
                                {indt.toLocaleDateString()} {indt.toLocaleTimeString()}
                            </td>
                        </tr>
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    </React.Fragment>
}

export default Group;