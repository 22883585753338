import {AnyAction} from 'redux';
import {DroppingOddsReducer as State} from '../../@types/reducers';
import {
    CLASSIC_DROPPING_BOOKMAKERS_DEFAULT,
    CLASSIC_DROPPING_REFERENCE_DEFAULT,
    GET_CURRENT_ODDS_SUCCESS,
    NEW_EVENT_CHANGE,
    OrderConstants,
    SET_CLASSIC_BOOKMAKER,
    SET_CLASSIC_SETTINGS
} from "../../constants/OddsConstants";
import {InstantDropObject} from "../../@types/formContext";
import {getUid, processDroppingOdd, updateInstantDropLists} from "../utils/droppingOddsUtils";
import {BETFAIR} from "../../constants/CommonConstants";
import {DroppingOddObject} from "../../@types/response";

const initialState: State = {
    classicOddsList: [],
    classicAlerts: {},
    classicBookmaker: BETFAIR,
    classicSettings: {
        order: OrderConstants.NONE,
        from: 1,
        to: 1,
        date: 0,
        reference: CLASSIC_DROPPING_REFERENCE_DEFAULT,
        pageSize: 20,
        comparisons: CLASSIC_DROPPING_BOOKMAKERS_DEFAULT
    },
    instants: {},
    last: null
};

const droppingOddsReducer = (state = initialState, action: AnyAction): State => {
    switch (action.type) {
        case GET_CURRENT_ODDS_SUCCESS: {
            const classicAlerts = action.payload
                .map((i: DroppingOddObject) => processDroppingOdd(i, state.classicSettings, state.classicBookmaker))
            return {
                ...state,
                classicOddsList: action.payload,
                classicAlerts: Object.assign({}, state.classicAlerts, {[action.bookmakerId]: classicAlerts})
            };
        }
        case SET_CLASSIC_SETTINGS: {
            const classicSettings = Object.assign({}, state.classicSettings, action.payload)
            const classicAlerts = state.classicOddsList.map(i => processDroppingOdd(i, classicSettings, state.classicBookmaker))
            return {
                ...state,
                classicSettings: classicSettings,
                classicAlerts: Object.assign({}, state.classicAlerts, {[action.bookmakerId]: classicAlerts})
            };
        }
        case SET_CLASSIC_BOOKMAKER: {
            return {
                ...state,
                classicBookmaker: action.payload
            }
        }
        case NEW_EVENT_CHANGE: {
            const item: InstantDropObject = Object.assign({}, action.payload, {uid: getUid(action.payload)})
            return {
                ...state,
                instants: updateInstantDropLists(item, Object.assign({}, state.instants)),
                last: item
            };
        }
        default:
            return state;
    }
};

export default droppingOddsReducer;
