import {call, put} from 'redux-saga/effects';
import {ISkeletonSaga, skeletonSaga} from '../../apis/skeleton/skeletonSaga';
import {GET_MONITOR_EVENTS_ERROR, GET_MONITOR_ODDS_EVENTS_SUCCESS} from "../../constants/MonitorConstants";
import {GetMonitorAlertsCall} from "../../apis/monitor";

function* tryFunction() {
    const response: Record<string, string> = yield call(GetMonitorAlertsCall);
    yield put({
        type: GET_MONITOR_ODDS_EVENTS_SUCCESS,
        payload: response
    });
}


export function* getMonitorOddsEventsSaga(): Generator {
    const sagaPayload: ISkeletonSaga = {
        tryApiCall: {function: tryFunction},
        failedType: GET_MONITOR_EVENTS_ERROR
    };
    yield call(skeletonSaga, sagaPayload);
}
