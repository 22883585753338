import {call, put} from 'redux-saga/effects';
import {GET_USER_INFO_ERROR, GET_USER_INFO_SUCCESS} from '../../constants/UserConstants';
import {ISkeletonSaga, skeletonSaga} from '../../apis/skeleton/skeletonSaga';
import {GetBookmakersCall, GetUserInfoCall} from "../../apis/user";
import {BookmakerData, MarketCapObject, MoneyLineObject, User} from "../../@types/response";
import {GET_EVENT_MONEY_LINE_SUCCESS, GET_MARKET_CAP_SUCCESS, SET_BOOKMAKERS} from "../../constants/CommonConstants";
import {GetEventsMoneyLineCall, GetMarketCapCall} from "../../apis/events";
import {ENABLED_BOOKMAKERS, SET_SETTINGS_AVERAGE_BOOKMAKERS} from "../../constants/PlayerConstants";

function* tryFunction() {
    const token = localStorage.getItem('sessionToken');
    if (!token) {
        throw Error('Token not valid');
    }

    const user: User = yield call(GetUserInfoCall)
    if (!user) {
        throw Error('User not logged in');
    }

    yield put({
        type: GET_USER_INFO_SUCCESS,
        payload: Object.assign({}, user)
    });

    const bookmakers: BookmakerData = yield call(GetBookmakersCall);
    yield put({
        type: SET_BOOKMAKERS,
        payload: bookmakers
    });

    yield put({
        type: SET_SETTINGS_AVERAGE_BOOKMAKERS,
        payload: user.averages.filter(b => ENABLED_BOOKMAKERS.has(b))
    });

    const moneyLines: MoneyLineObject = yield call(GetEventsMoneyLineCall);
    yield put({
        type: GET_EVENT_MONEY_LINE_SUCCESS,
        payload: moneyLines
    });

    const marketCap: MarketCapObject = yield call(GetMarketCapCall);
    yield put({
        type: GET_MARKET_CAP_SUCCESS,
        payload: marketCap
    });
}

export function* getUserInfoSaga(): Generator {
    const sagaPayload: ISkeletonSaga = {
        tryApiCall: {function: tryFunction},
        failedType: GET_USER_INFO_ERROR
    };
    yield call(skeletonSaga, sagaPayload);
}
