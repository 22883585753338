const PREFIX = 'HOT_MATCH'

enum HotMatchConstants {
    GET_HOT_MATCHES,
    GET_HOT_MATCHES_SUCCESS,
    GET_SETTINGS,
    GET_SETTINGS_SUCCESS
}

const SETTINGS = 'HOT_MATCH_SETTINGS'

enum SettingConstants {
    SET_ORDER,
    SET_SETTINGS,
}

export const GET_HOT_MATCHES = PREFIX + '_' + HotMatchConstants.GET_HOT_MATCHES;
export const GET_HOT_MATCHES_SUCCESS = PREFIX + '_' + HotMatchConstants.GET_HOT_MATCHES_SUCCESS;

export const GET_SETTINGS = PREFIX + '_' + HotMatchConstants.GET_SETTINGS;
export const GET_SETTINGS_SUCCESS = PREFIX + '_' + HotMatchConstants.GET_SETTINGS_SUCCESS;

/** SETTINGS **/
export const SET_SETTINGS = SETTINGS + '_' + SettingConstants.SET_SETTINGS;
export const SET_ORDER = SETTINGS + '_' + SettingConstants.SET_ORDER;

export enum OrderConstants {
    NONE,
    DATE,
    DELTA,
    ODD,
    TOT
}