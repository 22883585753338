import {call, put} from 'redux-saga/effects';
import {GET_BOOKS_ERROR, GET_BOOKS_SUCCESS} from '../../constants/UserConstants';
import {ISkeletonSaga, skeletonSaga} from '../../apis/skeleton/skeletonSaga';
import {GetBookCall} from "../../apis/user";
import {BooksObject, GroupFilterObject} from "../../@types/response";

function* tryFunction(payload: GroupFilterObject) {
    const books: BooksObject[] = yield call(GetBookCall, payload)
    yield put({
        type: GET_BOOKS_SUCCESS,
        payload: books
    });
}

export function* getGroupBooksSaga({payload}: { payload: GroupFilterObject }): Generator {
    const sagaPayload: ISkeletonSaga = {
        tryApiCall: {function: tryFunction, payload: payload},
        failedType: GET_BOOKS_ERROR
    };
    yield call(skeletonSaga, sagaPayload);
}
