import {call, put} from 'redux-saga/effects';
import {ISkeletonSaga, skeletonSaga} from '../../apis/skeleton/skeletonSaga';
import {GENERIC_ERROR} from '../../constants/ErrorConstants';
import {HideAlertCall} from '../../apis/user';
import {HideAlertPayload} from '../../@types/actions';
import {HIDE_ALERT_SUCCESS} from "../../constants/MonitorConstants";

function* tryFunction(payload: HideAlertPayload) {
    yield call(HideAlertCall, payload);
    yield put({
        type: HIDE_ALERT_SUCCESS,
        payload: {[`${payload.eventId}-${payload.marketId}-${payload.signId}`]: payload.margin}
    });
}

export function* hideAlertSaga({payload}: { payload: HideAlertPayload }): Generator {
    const sagaPayload: ISkeletonSaga = {
        tryApiCall: {function: tryFunction, payload: payload},
        failedType: GENERIC_ERROR
    };
    yield call(skeletonSaga, sagaPayload);
}