import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {routes} from '../../routes/routes';
import LoaderCheckSession from './LoaderCheckSession';
import ModalsProvider from '../components/modals/ModalsProvider';
import ContinueModal from '../components/modals/ContinueModal';
import WebSocketProvider from '../components/common/websocket/WebSocketProvider';
import LeftMenu from './LeftMenu';
import {useSelector} from 'react-redux';
import isLoggedIn from '../../store/selectors/isLoggedIn';
import LoginForm from '../components/LoginForm';

const Container: React.FC = () => {
    const loggedIn = useSelector(isLoggedIn);
    return (
            <Router>
                <main className='bg-monitor-primary font-sans flex font-bold leading-normal tracking-normal min-h-screen'>
                    <LeftMenu/>
                    <div className='relative flex-grow container-fluid z-20'>
                        <ModalsProvider>
                            <LoaderCheckSession>
                                <WebSocketProvider>
                                    <div className='main-content' id='mainContent'>
                                        <Routes>
                                            {routes.map((props) => {
                                                const {component: Component} = props || {};
                                                if (loggedIn) {
                                                    return <Route path={props.path} key={props.path}
                                                                  element={<Component {...props} />}/>
                                                } else {
                                                    return <Route path={props.path} key={props.path}
                                                                  element={<LoginForm/>}/>
                                                }
                                            })}
                                        </Routes>
                                    </div>
                                </WebSocketProvider>
                                <ContinueModal/>
                            </LoaderCheckSession>
                        </ModalsProvider>
                    </div>
                </main>
            </Router>
    );
};

export default Container;
