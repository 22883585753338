import {fork} from 'redux-saga/effects';
import * as Watcher from './watcher';


export default function* rootSaga(): Generator {
    yield fork(Watcher.StoreSetting);
    yield fork(Watcher.GetSetting);
    yield fork(Watcher.GetMonitorEvents);
    yield fork(Watcher.GetMonitorOddsEvents);
    yield fork(Watcher.GetEvent);
    yield fork(Watcher.GetBookedEvents);
    yield fork(Watcher.BookEvent);
    yield fork(Watcher.SearchEvent);
    yield fork(Watcher.HideAlert);
    yield fork(Watcher.DeleteSetting);
    yield fork(Watcher.GetEvents);
    yield fork(Watcher.DeleteAlert);
}
