import {applyMiddleware, combineReducers, compose, createStore, Reducer} from 'redux';
import createSagaMiddleware, {Saga, Task} from 'redux-saga';
import {ExtendedStore, GeneralObject, ReducerMap, SagaGenerator, SagaInjector} from './storeTypes';
import errorsReducer from './reducers/errorsReducer';
import commonReducer from "./reducers/commonReducer";
import commonSagas from "../middlewares/commonSagas";
import monitorReducer from "./reducers/monitorReducer";
import monitorSagas from "../middlewares/monitorsSagas";
import droppingOddsReducer from './reducers/droppingOddsReducer';
import droppingOddsSagas from "../middlewares/droppingSagas";
import hotMatchReducer from './reducers/hotMatchReducer';
import hotMatchSagas from '../middlewares/hotMatchSagas';
import playerReducer from './reducers/playerReducer';
import playersSagas from '../middlewares/playersSagas';
import extraReducer from "./reducers/extraReducer";
import extraSagas from '../middlewares/extraSagas';

const sagaMiddleware = createSagaMiddleware();

function createReducer(asyncReducers: ReducerMap) {
    return combineReducers({
        common: commonReducer,
        errors: errorsReducer,
        monitor: monitorReducer,
        player: playerReducer,
        odds: droppingOddsReducer,
        hotMatch: hotMatchReducer,
        extra: extraReducer,
        ...asyncReducers
    });
}

function createSagaInjector(
    runSaga: <S extends Saga>(saga: S, ...args: Parameters<S>) => Task,
    rootSaga: () => SagaGenerator
) {
    const injectedSagas = new Map();

    const isInjected = (key: string): boolean => injectedSagas.has(key);

    const injectSaga: SagaInjector = (key: string, saga: () => SagaGenerator): void => {
        if (!isInjected(key)) {
            injectedSagas.set(key, runSaga(saga));
        }
    };

    injectSaga('root', rootSaga);

    return injectSaga;
}

export default function configureStore(initialState?: GeneralObject) {
    const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    let store: ExtendedStore = createStore(
        createReducer({}),
        initialState,
        composeEnhancers(applyMiddleware(sagaMiddleware))
    );

    store.syncSagas = {
        monitorSagas,
        playersSagas,
        droppingOddsSagas,
        hotMatchSagas,
        extraSagas
    };

    // @ts-ignore
    store.injectSaga = createSagaInjector(sagaMiddleware.run, commonSagas);
    // @ts-ignore
    Object.keys(store.syncSagas || {}).forEach(key => store.injectSaga(key, store.syncSagas[key]));

    store.asyncReducers = {};

    store.injectReducer = (key: string, asyncReducer: Reducer) => {
        if (!(store.asyncReducers || {})[key]) {
            (store.asyncReducers || {})[key] = asyncReducer;
            store.replaceReducer(createReducer(store.asyncReducers || {}));
        }
    };

    return store;
}

