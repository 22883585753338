import React, {useEffect, useState} from "react";
import DoInstant from "../layouts/components/dropping/DoInstant";
import {InstantDropObject} from "../@types/formContext";
import EventDetailsModal from "../layouts/components/modals/EventDetailsModal";
import {OrderConstants} from "../constants/OddsConstants";
import InstantToolBar from "../layouts/components/dropping/DoInstantToolBar";
import SignChartModal from "../layouts/components/modals/SignChartModal";

const DroppingOddsInstant: React.FC = () => {

    const [loading, setLoading] = useState<boolean>(true);
    const [drop] = useState<InstantDropObject>();
    const [from, setFrom] = useState<number>(1);
    const [to, setTo] = useState<number>(1);
    const [order, setOrder] = useState<OrderConstants>(OrderConstants.MARGIN);
    const [beep, setBeep] = useState(false);
    const [date, setDate] = useState<number>(0)

    useEffect(() => {
        setLoading(false);
        document.title = "INSTANT DROPPING ODDS - OddMonitor";
    }, []);

    if (loading) {
        return null;
    }

    return <React.Fragment>
        <div
                id="toolbar-monitor"
                className="bg-toolbar-bgMain border-b border-toolbar-borderBottom fixed top-0 right-0 flex items-end justify-around w-full z-10"
        >
            <InstantToolBar
                    from={from}
                    setFrom={setFrom}
                    setBeep={setBeep}
                    beep={beep}
                    setTo={setTo}
                    to={to}
                    setDate={setDate}
            />
        </div>
        <div className=" mt-10">
            <div className="flex justify-center w-full">
                <DoInstant
                        drop={drop}
                        defaultSportId={1}
                        defaultBookmakers={new Set()}
                        defaultThreshold={3}
                        beep={beep}
                        order={order}
                        setOrder={setOrder}
                        from={from}
                        to={to}
                        date={date}
                />
            </div>
            <EventDetailsModal/>
            <SignChartModal/>
        </div>
    </React.Fragment>;
};
export default DroppingOddsInstant;
