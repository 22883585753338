import React, {useCallback, useContext, useEffect, useState} from 'react';
import {ModalsContext} from './ModalsContext';
import {MODALS_TYPES} from '../../../constants/ModalsConstants';
import {AlertMonitorObject, EventStoredObject, OddsObject} from '../../../@types/response';
import {useDispatch} from 'react-redux';
import {BookEventAction} from '../../../store/actions/monitor/BookEventAction';

const BookEventModal: React.FC = () => {

    const {modalsData, openedModals, setOpenedModalsCallback} = useContext(ModalsContext);

    const event: EventStoredObject = (modalsData[MODALS_TYPES.BOOK_EVENT_MODAL] || {}).event;
    const alert: AlertMonitorObject = (modalsData[MODALS_TYPES.BOOK_EVENT_MODAL] || {}).alert
    const selection: OddsObject = (modalsData[MODALS_TYPES.BOOK_EVENT_MODAL] || {}).odd

    const [odd, setOdd] = useState<undefined | number>(selection?.odd);
    const [amount, setAmount] = useState<number>(100);

    const dispatch = useDispatch();

    useEffect(() => {
        document.addEventListener('keydown', escFunction, false);

        return () => {
            document.removeEventListener('keydown', escFunction, false);
        };
    }, []);

    const onCancelClickCb = useCallback(() => {
        setOpenedModalsCallback(MODALS_TYPES.BOOK_EVENT_MODAL, false);
    }, [setOpenedModalsCallback]);

    const onConfirmClickCb = useCallback(() => {
        dispatch(BookEventAction({
            eventId: event._id,
            eventName: event.name,
            bookmakerId: alert.bookmakerId,
            marketId: alert.marketId.toString(),
            signId: selection.signId.toString(),
            odd: odd ?? selection.odd,
            amount
        }))
        setOpenedModalsCallback(MODALS_TYPES.BOOK_EVENT_MODAL, false);
        setOdd(undefined)
        setAmount(100)
    }, [odd, amount, event, selection, setOpenedModalsCallback])

    const escFunction = useCallback((_event: KeyboardEvent) => {
        if (_event.key === 'Escape') {
            setOpenedModalsCallback(MODALS_TYPES.BOOK_EVENT_MODAL, false);
            // } else if (_event.key === 'Enter') {
            //     onConfirmClickCb()
        }
    }, []);

    return (
            (openedModals.indexOf(MODALS_TYPES.BOOK_EVENT_MODAL) > -1 && (
                    <div className='fixed z-10 inset-0 overflow-y-auto' aria-labelledby='modal-title' role='dialog'
                         aria-modal='true'>
                        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
                            <div className='fixed inset-0 bg-modal-layer transition-opacity'
                                 aria-hidden='true'/>
                            <span className='hidden sm:inline-block sm:align-middle sm:h-screen'
                                  aria-hidden='true'>&#8203;</span>
                            <div
                                    className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-screen-sm sm:w-full'>
                                <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
                                    <h3 className='text-lg leading-6 font-medium text-gray-900 relative'
                                        id='modal-title'>
                                        Bet confirm
                                    </h3>
                                    <p className='mt-2'>
                                        Bet for [ <b>{selection.sign}</b> ] on <b>{event.name}</b>
                                    </p>
                                    <div className='mt-2'>
                                        <div className='md:flex md:items-center mb-2'>
                                            <div className='md:w-1/6'>
                                                <label className='block md:text-right mb-1 md:mb-0 pr-4'
                                                       htmlFor='inline-full-name'>
                                                    Odd
                                                </label>
                                            </div>
                                            <div className='md:w-1/6'>
                                                <input
                                                        className='bg-gray-50 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500'
                                                        defaultValue={selection.odd}
                                                        autoFocus
                                                        onChange={(e) => setOdd(parseFloat(e.target.value))}
                                                />
                                            </div>
                                        </div>
                                        <div className='md:flex md:items-center mb-2'>
                                            <div className='md:w-1/6'>
                                                <label className='block md:text-right mb-1 md:mb-0 pr-4'
                                                       htmlFor='inline-full-name'>
                                                    Amount
                                                </label>
                                            </div>
                                            <div className='md:w-1/6'>
                                                <input
                                                        className='bg-gray-50 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500'
                                                        defaultValue={amount}
                                                        onChange={(e) => setAmount(parseInt(e.target.value))}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse'>
                                    <button
                                            type='button'
                                            className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-success text-base font-medium text-gray-50 hover:bg-green-600 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                                            onClick={onConfirmClickCb}
                                    >
                                        Confirm
                                    </button>
                                    <button
                                            type='button'
                                            className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-100 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                                            onClick={onCancelClickCb}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
            )) ||
            null
    );
};
export default BookEventModal;
