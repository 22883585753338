import {FC, Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {BookmakerData, PlayerBookmakersStoredObject} from "../../../@types/response";
import {useSelector} from "react-redux";
import {getBookmakers} from "../../../store/selectors/getBookmakers";
import {getPlayers} from "../../../store/utils/playerUtils";
import {ENABLED_BOOKMAKERS} from "../../../constants/PlayerConstants";
import {getBetfairUrl} from "../../../store/utils/events";
import {BETFAIR} from "../../../constants/CommonConstants";
import {getSettings} from "../../../store/selectors/player/getSettings";

interface MarketTableProps {
    marketId: string;
    signId: string;
    odds: PlayerBookmakersStoredObject;
    mapMode: boolean;
    searchValue: string;
    mapClick: boolean;
    average: boolean;
    sbv: { [playerId: string]: { [bookmaker: string]: string } };

    mapPlayers(playersToMap: MapPlayerObject): void

    mapClicked(): void;
}

export interface MapPlayerObject {
    [key: string]: {
        bookmakerId: number;
        playerId: string;
    }
}

export const MarketTable: FC<MarketTableProps> = ({
                                                      odds,
                                                      marketId,
                                                      signId,
                                                      mapMode,
                                                      searchValue,
                                                      mapClick,
                                                      mapPlayers,
                                                      mapClicked,
                                                      average,
                                                      sbv
                                                  }) => {
    const bookmakers: BookmakerData = useSelector(getBookmakers);
    const averageBookmakers: number[] = useSelector(getSettings).comparisons[0]?.bookmakers || []
    const [playersToMap, setPlayersToMap] = useState<MapPlayerObject>({});

    const getPlayersList = useCallback(() => {
        return Object.values(getPlayers(odds, averageBookmakers))
                .sort((a, b) => {
                    if (average && Object.keys(sbv).length > 0) {
                        if (b.maps === a.maps && marketId in a.averages && marketId in b.averages && a.averages[marketId][Object.keys(sbv).length > 0 ? sbv[a.id]['0'] : '#'] && b.averages[marketId][Object.keys(sbv).length > 0 ? sbv[b.id]['0'] : '#'] && signId in a.averages[marketId][Object.keys(sbv).length > 0 ? sbv[a.id]['0'] : '#'] && signId in b.averages[marketId][Object.keys(sbv).length > 0 ? sbv[b.id]['0'] : '#']) {
                            return a.averages[marketId][Object.keys(sbv).length > 0 ? sbv[a.id]['0'] : '#'][signId]['odd'] - b.averages[marketId][Object.keys(sbv).length > 0 ? sbv[b.id]['0'] : '#'][signId]['odd']
                        } else {
                            return b.maps - a.maps
                        }
                    } else {
                        return b.maps - a.maps
                    }
                })
    }, [odds, marketId])

    const eventBookmakers: number[] = useMemo(() => {
        return Object.entries(bookmakers)
                .filter(([id, bookmaker]) => ENABLED_BOOKMAKERS.has(parseInt(id)) && bookmaker.details >= 0)
                .sort(([_a, a], [_b, b]) => b.details - a.details)
                .map(([b]) => parseInt(b))
    }, [bookmakers])

    useEffect(() => {
        if (!mapMode && Object.keys(playersToMap).length) {
            setPlayersToMap({})
        }
    }, [mapMode, playersToMap])

    useEffect(() => {
        if (mapClick) {
            if (Object.keys(playersToMap).length === 2) {
                mapPlayers(playersToMap)
                setPlayersToMap({})
            }
            mapClicked()
        }
    }, [mapClick, mapClicked, mapPlayers, playersToMap])


    return <table className='w-full border-spacing-0'>
        <thead>
        <tr style={{top: 0}} className="sticky bg-eventDetail-primary pb-1">
            <th style={{top: 0}} className="sticky"></th>
            <th style={{top: 0}} className='sticky text-center left-0'/>
            {eventBookmakers
                    .filter(bid => bid in bookmakers)
                    .map(bid => {
                        return <Fragment key={bid}>
                            <th style={{top: 0}}
                                className={'sticky font-extralight text-sm text-center px-2 pb-2 whitespace-nowrap'}>
                                {bookmakers[bid].name ?? "-"}
                            </th>
                            <th className="sticky"/>
                        </Fragment>
                    })}
            {average &&
                    <th style={{top: 0}}
                        className={'sticky font-extralight text-sm text-center px-2 pb-2 whitespace-nowrap'}>
                        Average
                    </th>
            }
            <th className="sticky"/>
        </tr>
        </thead>
        <tbody>
        {getPlayersList()
                .filter(n => n.name.toLowerCase().includes(searchValue.toLowerCase()))
                .map((player) => {
                    let firstBookmaker: number = -1
                    for (const bi in eventBookmakers) {
                        if (firstBookmaker === -1) {
                            if (odds?.[eventBookmakers[bi]]?.players?.[player.id]?.markets?.[marketId.toString()]?.[sbv[player.id] ? sbv[player.id][eventBookmakers[bi]] : '#']?.[signId]?.odd > 1) {
                                firstBookmaker = eventBookmakers[bi]
                            }
                        }
                    }
                    return <tr key={`book-${player.id}`}
                               className={'border border-monitor-primary'}>
                        <th className={`text-center px-1.5 bg-eventDetail-bookmaker`}>
                            {mapMode && (<input type="checkbox"
                                                disabled={Object.keys(playersToMap).length >= 2 && !Object.keys(playersToMap).includes(`${firstBookmaker}#${player.id}`)}
                                                id={player.id}
                                                checked={Object.keys(playersToMap).includes(`${firstBookmaker}#${player.id}`)}
                                                onChange={() => {
                                                    Object.keys(playersToMap).includes(`${firstBookmaker}#${player.id}`) ?
                                                            setPlayersToMap(() => {
                                                                const asArray = Object.entries(playersToMap);
                                                                const filtered = asArray.filter(([key]) => key !== `${firstBookmaker}#${player.id}`);
                                                                return Object.fromEntries(filtered);
                                                            }) :
                                                            setPlayersToMap(Object.assign({...playersToMap}, {
                                                                [`${firstBookmaker}#${player.id}`]: {
                                                                    bookmakerId: firstBookmaker,
                                                                    playerId: player.id
                                                                }
                                                            }))
                                                }}
                            />)}
                        </th>
                        <th className={`text-left left-0 z-10 border-eventDetail-primary bg-eventDetail-bookmaker`}>
                            {player.name}
                        </th>
                        {eventBookmakers.map(bookmakerId => {
                            if (!Object.keys(bookmakers).includes(bookmakerId.toString())) return null
                            const bookmaker = bookmakers[bookmakerId]
                            const betfairUrl = getBetfairUrl(
                                    odds[BETFAIR]?.players[player.id]?.markets[marketId.toString()]?.[Object.keys(sbv).length > 0 ? sbv[player.id][BETFAIR] : '#']?.[signId]?.bMarketId,
                                    odds[BETFAIR]?.players[player.id]?.markets[marketId.toString()]?.[Object.keys(sbv).length > 0 ? sbv[player.id][BETFAIR] : '#']?.[signId]?.bSelectionId
                            )

                            const backOdd = odds[bookmaker.id]?.players[player.id]?.markets[marketId.toString()]?.[Object.keys(sbv).length > 0 ? sbv[player.id][bookmaker.id] : '#']?.[signId]?.odd
                            const spread = (!average && Object.keys(sbv).length > 0 && sbv?.[player.id]?.[bookmaker.id]) ? sbv[player.id][bookmaker.id] : null
                            return <>
                                <td onClick={bookmaker.id === BETFAIR ? () => {
                                    betfairUrl && window.open(betfairUrl, "betfair", "width=925,height=660")
                                } : () => {
                                }}
                                    className={`text-center px-1.5 bg-eventDetail-bookmaker ${(bookmaker.id === BETFAIR && betfairUrl) ? 'cursor-pointer' : ''}`}>
                                    {spread ? <i className='font-thin text-table-sbv'>[{spread}]&nbsp;</i> : ''}
                                    <b>{backOdd > 1 ? backOdd : '-'}</b>
                                </td>
                                <td/>
                            </>
                        })}
                        {average &&
                                <td className={`text-center px-1.5 bg-eventDetail-bookmaker`}>
                                    <b>{player.averages[marketId][Object.keys(sbv).length > 0 ? sbv[player.id]['0'] : '#'] ? player.averages[marketId][Object.keys(sbv).length > 0 ? sbv[player.id]['0'] : '#'][signId] ? player.averages[marketId][Object.keys(sbv).length > 0 ? sbv[player.id]['0'] : '#'][signId].odd : '' : ''}</b>
                                </td>}
                    </tr>
                })}
        </tbody>
    </table>
}
