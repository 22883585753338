import React, {FC, RefObject, useEffect} from 'react';
import {DraweProps} from '../../../@types/inputs';
import {useDispatch, useSelector} from 'react-redux';
import {FixtureObject, SportsFilter} from '../../../@types/response';
import {getSports} from "../../../store/selectors/getSports";
import {GetSportsAction} from "../../../store/actions/GetSportsAction";
import {SPORT_NAME} from "../../../constants/CommonConstants";
import {TreeSport} from "./TreeView";

const useWindowEventListener = (event: any, callback: any) => {
    useEffect(() => {
        window.addEventListener(event, callback)
        return () => window.removeEventListener(event, callback)
    }, [event, callback])
}

export const Drawer: FC<DraweProps> = ({isOpen, onClose, onChange, settings}) => {

    const dispatch = useDispatch();
    const overDrawerRef: RefObject<HTMLDivElement> = React.createRef()

    useWindowEventListener("mousedown", (event: { target: any }) => {
        if (overDrawerRef.current && overDrawerRef.current.contains(event.target)) {
            return
        } else if (overDrawerRef.current && !overDrawerRef.current.contains(event.target)) {
            onClose()
        }
    })

    useEffect(() => {
        dispatch(GetSportsAction())
    }, [])

    const sports: FixtureObject = useSelector(getSports);
    return <aside
            className={' fixed top-14 pb-6 transform -right-80 2xl:w-80 w-56 h-full bg-drawer-bgSport text-drawer-textSport overflow-auto dropdown-hidden-scrollbar ' +
                    'ease-in-out transition-all duration-300 z-30 ' + (isOpen ? '-translate-x-80' : 'translate-x-0')}
            ref={overDrawerRef}
    >
        <ul className=' py-2'>
            {Object.keys(sports).map(sportId => <li key={`sport-${sportId}`}>
                        <TreeSport
                                sportId={sportId}
                                sportName={SPORT_NAME[sportId.toString()] ?? 'Soccer'}
                                categories={sports[sportId]}
                                selection={settings.sports[sportId.toString()] ?? {}}
                                onSportChange={(sportFilter: SportsFilter) => {
                                    onChange(Object.assign({}, settings.sports, sportFilter))
                                }}
                        />
                    </li>
            )}
        </ul>
    </aside>
}

