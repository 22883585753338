import {skeletonGet} from './skeleton/skeletonGet';

const eventsEndpoint = 'events'

export const GetEventsMoneyLineCall = (): Promise<any> => {
    return skeletonGet('moneyline', eventsEndpoint);
};

export const GetMarketCapCall = (): Promise<any> => {
    return skeletonGet('market', eventsEndpoint);
};

export const GetSportsCall = (): Promise<any> => {
    return skeletonGet('main', eventsEndpoint);
};
