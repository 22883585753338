import {FC, useEffect, useState} from "react";

interface FilterDateRangeProps {
    id: string
    name: string
    label: string
    onChange: (dateFrom: string, dateTo: string) => void
    defaultDateFrom: string
    defaultDateTo: string
}

const FilterDateRange: FC<FilterDateRangeProps> = ({
                                                       id,
                                                       name,
                                                       label,
                                                       defaultDateFrom,
                                                       defaultDateTo,
                                                       onChange
                                                   }) => {

    const [dateFrom, setDateFrom] = useState(defaultDateFrom);
    const [dateTo, setDateTo] = useState(defaultDateTo);

    useEffect(() => {
        onChange(dateFrom, dateTo);
    }, [dateFrom, dateTo]);

    return <div className="relative flex flex-col mr-2 2xl:text-monitorBase text-monitorSm">
        <label
                htmlFor={id}
                className={"text-white"}
        >
            {label}
        </label>
        <div className="flex space-x-4">
            <input
                    name={`${name}From`}
                    type="date"
                    className="px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    max={dateTo}
                    value={dateFrom}
                    onChange={(e) => {
                        setDateFrom(e.target.value);
                    }}
            />
            <input
                    name={`${name}To`}
                    type="date"
                    className="px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    min={dateFrom}
                    value={dateTo}
                    onChange={(e) => {
                        const date = e.target.value;
                        setDateTo(date);
                    }}
            />
        </div>
    </div>
}

export default FilterDateRange