import {skeletonGet} from './skeleton/skeletonGet';
import {skeletonPost} from "./skeleton/skeletonPost";
import {UserMonitorPayload} from "../@types/actions";
import {skeletonDelete} from "./skeleton/skeletonDelete";

const monitorEndpoint = 'monitor'

export const GetMonitorAlertsCall = (): Promise<any> => {
    return skeletonGet('', monitorEndpoint);
};

export const GetSettingsCall = (template: string = 'default'): Promise<any> => {
    return skeletonGet(`template/${template}`, monitorEndpoint);
};

export const StoreSettingCall = (payload: UserMonitorPayload): Promise<any> => {
    return skeletonPost(payload, `template/${payload.name}`, monitorEndpoint);
};

export const DeleteSettingCall = (template: string): Promise<any> => {
    return skeletonDelete(`template/${template}`, monitorEndpoint);
};

