import {createContext} from 'react'
import {Socket} from 'socket.io-client';

export type WebSocketContextType = {
    socket: null | Socket
};

export const WebSocketContext = createContext<WebSocketContextType>({
    socket: null
})

