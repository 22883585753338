import {call, put} from 'redux-saga/effects';
import {ISkeletonSaga, skeletonSaga} from '../../apis/skeleton/skeletonSaga';
import {GetInitialPlayerCall} from "../../apis/user";
import {PLAYER_INITIALIZE_ERROR, PLAYER_INITIALIZE_SUCCESS} from "../../constants/PlayerConstants";

function* tryFunction() {
    const response: Record<string, string> = yield call(GetInitialPlayerCall);
    yield put({
        type: PLAYER_INITIALIZE_SUCCESS,
        payload: response
    });
}

export function* initializeSaga(): Generator {
    const sagaPayload: ISkeletonSaga = {
        tryApiCall: {function: tryFunction},
        failedType: PLAYER_INITIALIZE_ERROR
    };
    yield call(skeletonSaga, sagaPayload);
}