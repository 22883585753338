import {ChangeEvent, FC, useCallback, useEffect, useRef, useState} from "react";

interface RangeBarProps {
    min: number;
    max: number;
    defaultMin?: number;
    defaultMax?: number;
    onChange: (min: number, max: number) => void;
}

const RangeBar: FC<RangeBarProps> = ({
                                         min,
                                         max,
                                         defaultMin = min,
                                         defaultMax = max,
                                         onChange
                                     }) => {
    const range = max - min;
    const [minVal, setMinVal] = useState(max - defaultMin);
    const [maxVal, setMaxVal] = useState(max - defaultMax);
    const minValRef = useRef(defaultMin);
    const maxValRef = useRef(defaultMax);
    const range1 = useRef<HTMLDivElement>(null);
    const range2 = useRef<HTMLDivElement>(null);

    // Convert to percentage
    const getPercent = useCallback(
            (value: number) => Math.round((value / range) * 100),
            [min, max]
    );

    // Set width of the range to decrease from the left side
    useEffect(() => {
        const minPercent = getPercent(minVal);
        const maxPercent = getPercent(maxVal);

        if (range1.current) {
            range1.current.style.left = `${minPercent}%`;
            range1.current.style.width = `${maxPercent - minPercent}%`;
        }
    }, [minVal, getPercent]);

    // Set width of the range to decrease from the right side
    useEffect(() => {
        const minPercent = getPercent(minVal);
        const maxPercent = getPercent(maxVal);

        if (range2.current) {
            range2.current.style.width = `${100 - maxPercent}%`;
        }
        if (range1.current) {
            range1.current.style.width = `${maxPercent - minPercent}%`;
        }
    }, [maxVal, getPercent]);

    // Get min and max values when their state changes
    useEffect(() => {
        onChange((max - minVal) / 10, (max - maxVal) / 10);
    }, [minVal, maxVal]);

    return <div className="container pt-3 mx-auto" style={{width: '200px'}}>
        <input
                type="range"
                min={0}
                max={range}
                value={minVal}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const value = Math.min(Number(event.target.value), maxVal - 1);
                    setMinVal(value);
                    minValRef.current = value;
                }}
                className="thumb z-40"
        />
        <input
                type="range"
                min={0}
                max={range}
                value={maxVal}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const value = Math.max(Number(event.target.value), minVal + 1);
                    setMaxVal(value);
                    maxValRef.current = value;
                }}
                className="thumb z-40"
        />

        <div className="relative w-full">
            <span className='absolute -left-8 top-1'>MIN</span>
            <div className="bg-monitor-margin-l2 h-2 z-0 w-full rounded-full absolute -left-6"/>
            <div className="bg-monitor-margin-l2 h-2 z-0 w-full rounded-full absolute"/>
            <div ref={range1} className="bg-monitor-margin-l1 z-10 h-2 rounded-full absolute"/>
            <div ref={range2} className="bg-monitor-margin-l0 z-20 h-2 rounded-full absolute right-0"/>
            <div className="bg-monitor-margin-l0 z-20 h-2 rounded-full absolute w-8 h-2 -right-6"/>
            <span className='absolute -right-8 top-1'>MAX</span>
        </div>
        <div className="flex items-center justify-between text-sm m-4 mb-0">
            <div
                    className="py-1 px-2 text-center border border-gray-200 rounded-lg bg-gray-50 focus:border-yellow-400 focus:outline-none"
            >{(max - minVal) / 10}
            </div>
            <div
                    className="py-1 px-2 text-center border border-gray-200 rounded-lg bg-gray-50 focus:border-yellow-400 focus:outline-none"
            >{(max - maxVal) / 10}
            </div>
        </div>
        <div className="clear-both"/>
    </div>
};

export default RangeBar;
