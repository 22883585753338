import {call, put} from 'redux-saga/effects';
import {ISkeletonSaga, skeletonSaga} from '../../apis/skeleton/skeletonSaga';
import {GetExtraAlertsCall} from "../../apis/extra";
import {GET_EXTRA_EVENTS_ERROR, GET_EXTRA_EVENTS_SUCCESS} from "../../constants/ExtraConstants";

function* tryFunction() {
    const response: Record<string, string> = yield call(GetExtraAlertsCall);
    yield put({
        type: GET_EXTRA_EVENTS_SUCCESS,
        payload: response
    });
}


export function* getExtraEventsSaga(): Generator {
    const sagaPayload: ISkeletonSaga = {
        tryApiCall: {function: tryFunction},
        failedType: GET_EXTRA_EVENTS_ERROR
    };
    yield call(skeletonSaga, sagaPayload);
}
