import {call, put} from 'redux-saga/effects';
import {ISkeletonSaga, skeletonSaga} from '../../apis/skeleton/skeletonSaga';
import {GetInitialEventsCall} from "../../apis/user";
import {MONITOR_INITIALIZE_ERROR, MONITOR_INITIALIZE_SUCCESS} from "../../constants/MonitorConstants";

function* tryFunction() {
    const response: Record<string, string> = yield call(GetInitialEventsCall);
    yield put({
        type: MONITOR_INITIALIZE_SUCCESS,
        payload: response
    });
}

export function* GetBookedEventsSaga(): Generator {
    const sagaPayload: ISkeletonSaga = {
        tryApiCall: {function: tryFunction},
        failedType: MONITOR_INITIALIZE_ERROR
    };
    yield call(skeletonSaga, sagaPayload);
}