import {faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DropDownItem, DropDownSports, InstantDropdown} from "../common/DropDown";
import React, {useCallback} from "react";
import {DropListHeadProps} from "../../../@types/formContext";
import {BookmakerData} from "../../../@types/response";
import {useSelector} from "react-redux";
import {getBookmakers} from "../../../store/selectors/getBookmakers";
import FilterNameInput from "../common/FilterNameInput";

const DoInstantHead: React.FC<DropListHeadProps> = ({
                                                        id,
                                                        sport,
                                                        bookmakers,
                                                        threshold,
                                                        setFilter,
                                                        search,
                                                        setSearch
                                                    }) => {

    const allBookmakers: BookmakerData = useSelector(getBookmakers);

    const selectAll = useCallback(() => {
        return new Set(Object.values(allBookmakers)
                .filter(b => b.details >= 0)
                .map((b) => b.id))
    }, [allBookmakers])

    return (
            <div
                    id="toolbar-monitor"
                    className=" bg-toolbar-bgMain border border-toolbar-borderBottom flex items-end mx-3 py-1 justify-around"
            >
                <div className="">
                    <ul className="flex justify-center items-center toolbarInstant toolbar-monitor">
                        <li className=" w-4/12 relative pt-2">
                            <InstantDropdown label="Bookmakers" color="default">
                                <DropDownItem
                                        key={0}
                                        label="Tutti i bookmakers"
                                        selected={selectAll().size === bookmakers.size}
                                        onClick={() => {
                                            if (selectAll().size === bookmakers.size) {
                                                setFilter({bookmakers: new Set()});
                                            } else {
                                                setFilter({bookmakers: selectAll()});
                                            }
                                        }}
                                />
                                {Object.values(allBookmakers)
                                        .filter(b => b.details >= 0)
                                        .sort((a, b) => (b.name > a.name ? -1 : 1))
                                        .map((b) => {
                                            return (
                                                    <DropDownItem
                                                            key={b.name}
                                                            label={b.name}
                                                            selected={bookmakers.has(b.id)}
                                                            onClick={() => {
                                                                const newSet = new Set(bookmakers);
                                                                if (bookmakers.has(b.id)) {
                                                                    newSet.delete(b.id);
                                                                } else {
                                                                    newSet.add(b.id);
                                                                }
                                                                setFilter({bookmakers: newSet});
                                                            }}
                                                    />
                                            );
                                        })}
                            </InstantDropdown>
                        </li>
                        <li className=" w-4/12 relative pt-2">
                            <InstantDropdown label="Tournament" color="default">
                                <DropDownSports
                                        sportsFilter={sport}
                                        onFilterChange={(_filter) => {
                                            setFilter({sport: _filter});
                                        }}
                                />
                            </InstantDropdown>
                        </li>
                        <li className=" w-4/12 relative pt-3  ">
                            <div className="flex">
                                <div>
                                    <label
                                            htmlFor={`${id}-threshold`}
                                            className=" mr-2 text-white text-xs text-baseDropping"
                                    >Threshold</label>
                                </div>
                                <div>
                                    <input
                                            id={`${id}-threshold`}
                                            className="bg-transparent w-10 bg-opacity-0 border bg-gray-500 text-white text-xs text-end"
                                            type="number"
                                            value={threshold}
                                            name="threshold"
                                            min={3}
                                            onChange={({target}) => {
                                                setFilter({
                                                    threshold: parseInt(target.value)
                                                });
                                            }}
                                    />
                                </div>
                            </div>
                        </li>
                        <li className=" w-1/12 relative pt-3 ml-2 mr-2  text-white">
                            <button
                                    className="cursor-pointer"
                                    onClick={() => {
                                        setFilter({from: new Date().getTime()})
                                    }}
                            >
                                <FontAwesomeIcon icon={faTrashCan} fontSize={12}/>
                            </button>
                        </li>
                        <li className="mx-1  flex items-center w-1/3 ">
                            <FilterNameInput
                                    id={id}
                                    placeholder="search ..."
                                    setValue={setSearch} value={search}
                                    small={true}
                            />
                        </li>
                    </ul>
                </div>
            </div>
    );
};

export default DoInstantHead;
