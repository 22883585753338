import {takeLatest} from 'redux-saga/effects';
/*CONSTANTS*/
import {LOGIN, LOGOUT} from '../../constants/LoginConstants';
import {GET_BOOKS, GET_USER_INFO} from "../../constants/UserConstants";
/*SAGAS*/
import {loginSaga} from './loginSaga';
import {logoutSaga} from './logoutSaga';
import {getUserInfoSaga} from "./getUserInfoSaga";
import {getGroupBooksSaga} from "./getGroupBooksSaga";
import {getSportsSaga} from "./getSportsSaga";
import {GET_EVENT_MONEY_LINE, GET_MARKET_CAP, GET_SPORTS} from "../../constants/CommonConstants";
import {getEventsMoneyLineSaga} from "./getEventsMoneyLineSaga";
import {getMarketCapSaga} from "./getMarketCapSaga";

export function* login(): Generator {
    // @ts-ignore
    yield takeLatest(LOGIN, loginSaga);
}

export function* getUserInfo(): Generator {
    yield takeLatest(GET_USER_INFO, getUserInfoSaga);
}

export function* logout(): Generator {
    yield takeLatest(LOGOUT, logoutSaga);
}

export function* getGroupBooks(): Generator {
    // @ts-ignore
    yield takeLatest(GET_BOOKS, getGroupBooksSaga);
}

export function* getSports(): Generator {
    yield takeLatest(GET_SPORTS, getSportsSaga);
}

export function* getEventMoneyLine(): Generator {
    yield takeLatest(GET_EVENT_MONEY_LINE, getEventsMoneyLineSaga);
}

export function* getMarketCap(): Generator {
    yield takeLatest(GET_MARKET_CAP, getMarketCapSaga);
}