import {BookmakerObject, ComparisonSettingsObject} from "../../../@types/response";
import {Dropdown, DropDownSettingItem} from "../common/DropDown";
import {AVERAGE, MarginFilter} from "../../../constants/CommonConstants";
import {MAX_LIMIT, MIN_LIMIT} from "../../../constants/MonitorConstants";
import {FC, Fragment} from "react";

interface ComparisonsDropdownProps {
    settings: any
    comparisons: BookmakerObject[]
    loading: boolean
    setSetting: (setting: any) => void
}
export const ComparisonsDropdown: FC<ComparisonsDropdownProps> = ({settings, comparisons, loading, setSetting}) => {
    return <Fragment>
        {loading ?
                <Dropdown label="COMPARISONS" color="default"/>
                :
                <Dropdown label="COMPARISONS" color="default" isMaxHeight={true}>
                    {comparisons.filter(comp => settings.comparisons[comp.id.toString()]).map((comparison) => {
                        const currentSettings: ComparisonSettingsObject = settings.comparisons[comparison.id.toString()];
                        return <DropDownSettingItem
                                key={comparison.id}
                                label={comparison.name}
                                settings={currentSettings}
                                onChange={(newSettings) => {
                                    const newComparisons: {
                                        [comparisonId: string]: ComparisonSettingsObject
                                    } = Object.assign(
                                            {}, settings.comparisons, {[comparison.id]: newSettings});
                                    setSetting(Object.assign({}, settings, {comparisons: newComparisons}));
                                }}
                                margin={MarginFilter.ENABLED}
                                hasAmountMarket={false}
                                hasAmountOutcome={false}
                                hasBookmakers={comparison.id === AVERAGE}
                                minLimit={MIN_LIMIT}
                                maxLimit={MAX_LIMIT}
                                marginPercentage={true}
                        />
                    })}
                </Dropdown>
        }
    </Fragment>
}