import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {GroupFilterObject,} from "../../../@types/response";
import {useDispatch, useSelector} from "react-redux";
import FilterInput from "../common/FilterInput";
import {faRetweet} from "@fortawesome/free-solid-svg-icons";
import Logo from "../logo";
import Separator from "../Separator";
import {GetGroupBooksAction} from "../../../store/actions/GetGroupBooksAction";
import {isLoading} from "../../../store/selectors/hotmatch/isLoading";
import FilterDateRange from "../common/FilterDateRange";
import {GetTeamCall} from "../../../apis/user";

export default function Toolbar() {

    const dispatch = useDispatch();

    const loading: boolean = useSelector(isLoading);

    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);

    const [team, setTeam] = useState<{ [id: string]: string }>({});
    const [filters, setFilters] = useState<GroupFilterObject>({
        dateFrom: sevenDaysAgo.toISOString().split("T")[0],
        dateTo: today.toISOString().split("T")[0],
        oddFrom: 1,
        oddTo: 1
    });

    useEffect(() => {
        GetTeamCall().then((result) => {
            setTeam(result)
        })
    }, []);

    return (
            <div className="flex w-full h-14 2xl:text-monitorBase text-monitorSm">
                <Logo/>
                <ul className="flex items-center justify-around py-2 w-full">
                    <Separator/>
                    <li className="mx-4">
                        <FilterDateRange
                                id="date"
                                name="date"
                                label="DATE"
                                defaultDateFrom={filters.dateFrom}
                                defaultDateTo={filters.dateTo}
                                onChange={(dateFrom, dateTo) => {
                                    setFilters(Object.assign({}, filters, {dateFrom, dateTo}));
                                }}
                        />
                    </li>
                    <Separator/>
                    <li className="mx-4">
                        <FilterInput
                                id="oddFrom"
                                name="oddFrom"
                                label="FROM"
                                min={1}
                                max={50}
                                step={0.1}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (isNaN(parseInt(e.target.value))) return;
                                    setFilters(Object.assign({}, filters, {oddFrom: parseFloat(e.target.value)}));
                                }}
                                value={filters.oddFrom ?? 0}
                        />
                    </li>
                    <Separator/>
                    <li className="mx-4">
                        <FilterInput
                                id="oddTo"
                                name="oddTo"
                                label="TO"
                                min={1}
                                max={100}
                                step={0.1}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (isNaN(parseInt(e.target.value))) return;
                                    setFilters(Object.assign({}, filters, {oddTo: parseFloat(e.target.value)}));
                                }}
                                value={filters.oddTo ?? 0}
                        />
                    </li>
                    <Separator/>
                    <li className="mx-4">
                        <select
                                id="userId"
                                name="userId"
                                className={"text-white bg-toolbar-bgMain"}
                                onChange={({target}) =>
                                        setFilters(Object.assign({}, filters, {userId: target.value}))
                                }
                                value={filters.userId ?? ''}
                        >
                            <option value={''}>All</option>
                            {Object.entries(team).map(([id, name]) =>
                                    <option value={id}>{name}</option>
                            )}
                        </select>
                    </li>
                    <Separator/>
                    <li>
                        <button
                                className={"p-3 rounded-md bg-toolbar-settingsLoading text-white"}
                                onClick={() => {
                                    dispatch(GetGroupBooksAction(filters));
                                }}
                        >
                            <FontAwesomeIcon className={loading ? "animate-spin" : ""} icon={faRetweet}/>
                        </button>
                    </li>
                </ul>
            </div>
    );
}
