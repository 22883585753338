import {call, put} from 'redux-saga/effects';
import {ISkeletonSaga, skeletonSaga} from '../../apis/skeleton/skeletonSaga';
import {GetCurrentOddsCall} from "../../apis/dropping";
import {GET_CURRENT_ODDS_SUCCESS} from "../../constants/OddsConstants";
import {GENERIC_ERROR} from "../../constants/ErrorConstants";


function* tryFunction(payload: number) {
    const response: Record<string, string> = yield call(GetCurrentOddsCall, payload);
    yield put({
        type: GET_CURRENT_ODDS_SUCCESS,
        payload: response,
        bookmakerId: payload
    });
}

export function* getCurrentPinnacleOddsSaga(): Generator {
    const sagaPayload: ISkeletonSaga = {
        tryApiCall: {function: tryFunction, payload: 3},
        failedType: GENERIC_ERROR
    };
    yield call(skeletonSaga, sagaPayload);
}

export function* getCurrentBetfairOddsSaga(): Generator {
    const sagaPayload: ISkeletonSaga = {
        tryApiCall: {function: tryFunction, payload: 2},
        failedType: GENERIC_ERROR
    };
    yield call(skeletonSaga, sagaPayload);
}