import {call, put} from 'redux-saga/effects';
import {ISkeletonSaga, skeletonSaga} from '../../apis/skeleton/skeletonSaga';
import {DeleteSettingCall} from "../../apis/monitor";
import {DELETE_MONITOR_SETTINGS_SUCCESS} from "../../constants/MonitorConstants";
import {GENERIC_ERROR} from "../../constants/ErrorConstants";

function* tryFunction(template: string) {
    yield call(DeleteSettingCall, template);
    yield put({
        type: DELETE_MONITOR_SETTINGS_SUCCESS,
        payload: {template}
    });
}

export function* deleteSettingSaga({payload}: { payload: string }): Generator {
    const sagaPayload: ISkeletonSaga = {
        tryApiCall: {function: tryFunction, payload: payload},
        failedType: GENERIC_ERROR
    };
    yield call(skeletonSaga, sagaPayload);
}
