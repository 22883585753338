import React from 'react';
import {Provider} from 'react-redux';
import configureRoutes from '../../routes/routes';
import Container from './Container';

const store = configureRoutes();

const App: React.FC = () => {
    return (
            <Provider store={store}>
                <Container/>
            </Provider>
    );
};

export default App;
