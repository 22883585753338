import {takeLatest} from 'redux-saga/effects';
/*CONSTANTS*/
import {GET_HOT_MATCHES} from "../../constants/HotMatchConstants";
/*SAGAS*/
import {getHotMatchesSaga} from "./getHotMatchesSaga";


export function* GetHotMatches(): Generator {
    // @ts-ignore
    yield takeLatest(GET_HOT_MATCHES, getHotMatchesSaga);
}