import {call, put} from 'redux-saga/effects';
import {ISkeletonSaga, skeletonSaga} from '../../apis/skeleton/skeletonSaga';
import {GetEventPlayersCall} from "../../apis/player";
import {GENERIC_ERROR} from '../../constants/ErrorConstants';
import {GET_EVENT_SUCCESS} from '../../constants/PlayerConstants';

function* tryFunction(eventId: number) {
    const response: Record<string, string> = yield call(GetEventPlayersCall, eventId);
    yield put({
        type: GET_EVENT_SUCCESS,
        payload: response
    });
}

export function* getEventPlayersSaga({payload}: { payload: { eventId: number } }): Generator {
    const sagaPayload: ISkeletonSaga = {
        tryApiCall: {function: tryFunction, payload: payload.eventId},
        failedType: GENERIC_ERROR
    };
    yield call(skeletonSaga, sagaPayload);
}