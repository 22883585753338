import {call, put} from 'redux-saga/effects';
import {ISkeletonSaga, skeletonSaga} from '../../apis/skeleton/skeletonSaga';
import {SearchEventCall} from "../../apis/event";
import {SearchEventPayload} from "../../@types/actions";
import {SEARCH_EVENT_SUCCESS} from "../../constants/MonitorConstants";
import {GENERIC_ERROR} from "../../constants/ErrorConstants";

function* tryFunction(payload: SearchEventPayload) {
    if (payload.event.length < 3) {
        yield put({
            type: SEARCH_EVENT_SUCCESS,
            payload: []
        });
    } else {
        const response: Record<string, string> = yield call(SearchEventCall, payload);
        yield put({
            type: SEARCH_EVENT_SUCCESS,
            payload: response
        });
    }
}

export function* searchEventSaga({payload}: { payload: SearchEventPayload }): Generator {
    const sagaPayload: ISkeletonSaga = {
        tryApiCall: {function: tryFunction, payload},
        failedType: GENERIC_ERROR
    };
    yield call(skeletonSaga, sagaPayload);
}