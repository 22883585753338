import React, {useEffect} from 'react'
import Toolbar from '../layouts/components/hotmatch/Toolbar';
import HotMatchesTable from '../layouts/components/hotmatch/HotMatchesTable';
import {HotMatchObject, HotMatchSettingsObject} from '../@types/response';
import HotMatchItem from '../layouts/components/hotmatch/HotMatchItem';
import EventDetailsModal from '../layouts/components/modals/EventDetailsModal';
import BookEventModal from '../layouts/components/modals/BookEventModal';
import SignChartModal from '../layouts/components/modals/SignChartModal';
import HideAlertModal from '../layouts/components/modals/HideAlertModal';
import {useDispatch, useSelector} from 'react-redux';
import {getHotMatches} from '../store/selectors/hotmatch/getHotMatches';
import {GetHotMatchesAction} from '../store/actions/hotmatch/GetHotMatchesAction';
import {getSettings} from '../store/selectors/hotmatch/getSettings';
import PlayerDetailsModal from "../layouts/components/modals/PlayerDetailsModal";

const HotMatch: React.FC = () => {
    const dispatch = useDispatch();

    const hotMatches: HotMatchObject[] = useSelector(getHotMatches);
    const settings: HotMatchSettingsObject = useSelector(getSettings);

    useEffect(() => {
        dispatch(GetHotMatchesAction(settings))
        document.title = 'HOT MATCHES - OddMonitor';
    }, []);

    return <React.Fragment>
        <div
                id='toolbar-hotmatch'
                className='bg-toolbar-bgMain border-b border-toolbar-borderBottom fixed top-0 right-0 flex items-end justify-around w-full z-10'
        >
            <Toolbar/>
        </div>
        <div className='mt-16 mx-4'>
            <HotMatchesTable>
                {hotMatches
                        .map((hotMatch: HotMatchObject) => <HotMatchItem
                                key={`${hotMatch.eid}-${hotMatch.mk}-${hotMatch.sg}`}
                                hotMatch={hotMatch}
                        />)}
            </HotMatchesTable>
        </div>

        <EventDetailsModal/>
        <PlayerDetailsModal/>
        <BookEventModal/>
        <SignChartModal/>
        <HideAlertModal/>
    </React.Fragment>;
}

export default HotMatch;
