import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PlayerTable from '../layouts/components/player/PlayerTable';
import Toolbar from '../layouts/components/player/Toolbar';
import PlayerItem from '../layouts/components/player/PlayerItem';
import {AlertPlayerObject, BookmakerData, EventsStoredObjects, User} from '../@types/response';
import {getBookmakers} from '../store/selectors/getBookmakers';
import {GetInitAction} from '../store/actions/player/GetInitAction';
import getClient from '../store/selectors/getClient';
import {getMonitors} from '../store/selectors/player/getMonitors';
import {getEvents} from '../store/selectors/player/getEvents';
import PlayerDetailsModal from '../layouts/components/modals/PlayerDetailsModal';
import MarketDetailsModal from '../layouts/components/modals/MarketPlayerModal';
import {keySearch} from '../store/utils/playerUtils';
import HidePlayerModal from '../layouts/components/modals/HidePlayerModal';
import BookPlayerModal from '../layouts/components/modals/BookPlayerModal';
import {ENABLED_BOOKMAKERS} from '../constants/PlayerConstants';

const Players: React.FC = () => {

    const dispatch = useDispatch();

    const events: EventsStoredObjects = useSelector(getEvents);
    const monitors: AlertPlayerObject[] = useSelector(getMonitors);
    const bookmakers: BookmakerData = useSelector(getBookmakers);
    const client: undefined | User = useSelector(getClient);

    const [search, setSearch] = useState<string>('')

    const enabledBookmakers: Set<number> = useMemo(() => {
        return new Set(Object.keys(bookmakers)
                .filter((id) => ENABLED_BOOKMAKERS.has(parseInt(id)))
                .map((b) => parseInt(b)))
    }, [bookmakers])

    useEffect(() => {
        dispatch(GetInitAction(client?.id || ''))
        document.title = 'PLAYER - OddMonitor';
    }, [client, dispatch]);

    if (!Object.keys(bookmakers).length) {
        return <div>
            <div
                    id='toolbar-player'
                    className='bg-toolbar-bgMain border-b border-toolbar-borderBottom fixed top-0 right-0 flex items-end justify-around w-full z-10'
            >
                <Toolbar
                        search={search}
                        setSearch={setSearch}
                />
            </div>
            <div className='mt-16'>
                <PlayerTable/>
            </div>
            <div>Loading</div>
        </div>
    }

    return <React.Fragment>
        <div id='toolbar-player'
             className='bg-toolbar-bgMain border-b border-toolbar-borderBottom fixed top-0 right-0 flex items-end justify-around w-full z-10'>
            <Toolbar
                    search={search}
                    setSearch={setSearch}/>
        </div>
        <div className='mt-16 mx-4'>
            <PlayerTable>
                {monitors
                        .filter(monitor => keySearch(search.toLowerCase(), monitor, events[monitor.eventId])
                                && enabledBookmakers.has(monitor.bookmakerId)
                                && (client?.client === null || client?.client === monitor.bookmakerId)
                        )
                        .map((monitor: AlertPlayerObject, idx: number) => (
                                <PlayerItem
                                        alert={monitor}
                                        key={`${monitor.playerId}x${idx}`}
                                        event={events[monitor.eventId]}
                                />
                        ))}
            </PlayerTable>
        </div>
        <PlayerDetailsModal/>
        <MarketDetailsModal/>
        <BookPlayerModal/>
        <HidePlayerModal/>
    </React.Fragment>
}

export default Players;
